import { EApiLibrary } from '@features/oktaClient';
import api from '@configs/urls/api';

export const oktaApiLib = {
  [EApiLibrary.logout]: api.logout,
  [EApiLibrary.refreshToken]: api.refreshToken,
  [EApiLibrary.authorize]: api.authorize,
  [EApiLibrary.authenticate]: api.authenticate,
  [EApiLibrary.resetPasswordWithCode]: api.resetPasswordWithCode,
  [EApiLibrary.resetPasswordEmail]: api.resetPasswordEmail,
};
