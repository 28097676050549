import { isObject } from './isObject';

export const deepCompare = <T extends Record<string, any>, A extends Record<string, any>>(
  objectA: T,
  objectB: A,
): boolean => {
  const keysA = Object.keys(objectA);
  const keysB = Object.keys(objectB);
  if (keysA.length !== keysB.length) {
    return false;
  }

  return Object.keys(objectA).every((key) => {
    const valueA = objectA[key];
    const valueB = objectB[key];
    const areObjects = isObject(objectA[key]) && isObject(objectB[key]);
    if (
      areObjects && !deepCompare(valueA, valueB) ||
      !areObjects && valueA !== valueB
    ) {
      return false;
    }
    return true;
  });
};
