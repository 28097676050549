import React, { FC } from 'react';
import { Box } from '@mui/material';

import { ReactComponent as SupplierLogo } from '@assets/images/icons/supplierLogo.svg';

import useStylesBaseLogo from './baseLogo.theme';
import { baseLogoText, baseLogoWrapper } from './baseLogo.const';
import { IBaseLogoProps } from './baseLogo.types';

const BaseLogo: FC<IBaseLogoProps> = props => {
  const {
    size = 'small',
    externalClasses = {},
  } = props;
  const { classes, cx } = useStylesBaseLogo(props);

  return (
    <Box
      className={cx(
        classes.baseWrapper,
        externalClasses['root'] || undefined,
      )}
      data-testid={baseLogoWrapper}
    >
      <SupplierLogo className={cx(classes.supplierLogo, size)} />

      <span className={classes.logoText}>
        {baseLogoText.SUPPLIER}
      </span>
    </Box>
  );
};

export default BaseLogo;
