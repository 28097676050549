import { FC } from 'react';
import { TableCell, TableRow } from '@mui/material';

import { IRowWithCenteredChildrenProps } from './rowWithCenteredChildren.interface';

const RowWithCenteredChildren: FC<IRowWithCenteredChildrenProps> = (props) => {
  const {
    children,
    cellsNumber,
  } = props;

  return (
    <TableRow>
      <TableCell
        colSpan={cellsNumber}
        sx={{
          textAlign: 'center',
        }}
      >
        {children}
      </TableCell>
    </TableRow>
  );
};

export { RowWithCenteredChildren };
