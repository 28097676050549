import { FC, PropsWithChildren } from 'react';

import {
  EntityManagementProvider,
} from '@features/recentlyViewed/providers/entityManagementProvider/entityManagementProvider';
import { ConfirmationProvider } from '@features/confirmationPopup/providers/confirmationProvider/ConfirmationProvider';
import { PushNotificationsProvider } from '@features/sharedWidgets/pushNotificationsProvider/pushNotificationsProvider';
import { FeatureFlagsProvider } from '@providers/featureFlagsProvider/featureFlagsProvider';
import MaterialTypesProvider from '@providers/materialTypesProvider/MaterialTypesProvider';
import ReduxWrapperProvider from '@store/reduxProviderWrapper/ReduxWrapperProvider';
import { TenantProvider } from '@providers/tenantProvider/TenantProvider';
import NetworkChecker from '@components/networkChecker';
import { RefreshPageWarningProvider } from '@providers/refreshPageWarningProvider/RefreshPageWarningProvider';
import InitAppChecker from '@components/initAppChecker/InitAppChecker';
import { DownloadTicketsProvider } from '@providers/downloadTicketsProvider/DownloadTicketsProvider';
import { ManageModalsProvider } from '@features/haulhubUi';

const NoOpsAuthorizationChecker: FC<PropsWithChildren> = ({ children }) => {
  return (
    <>
      <ReduxWrapperProvider>
        <ConfirmationProvider>
          <FeatureFlagsProvider>
            <TenantProvider>
              <MaterialTypesProvider>
                <InitAppChecker>
                  <PushNotificationsProvider>
                    <EntityManagementProvider>
                      <RefreshPageWarningProvider>
                        <NetworkChecker>
                          <ManageModalsProvider>
                            <DownloadTicketsProvider>
                              {children}
                            </DownloadTicketsProvider>
                          </ManageModalsProvider>
                        </NetworkChecker>
                      </RefreshPageWarningProvider>
                    </EntityManagementProvider>
                  </PushNotificationsProvider>
                </InitAppChecker>
              </MaterialTypesProvider>
            </TenantProvider>
          </FeatureFlagsProvider>
        </ConfirmationProvider>
      </ReduxWrapperProvider>
    </>
  );
};

export default NoOpsAuthorizationChecker;
