import { ELocalization } from '@enums/localization.enum';

export const getBrowserLanguage = (): ELocalization => {
  const { language } = navigator;
  if (language === ELocalization.FRENCH) {
    return language;
  }

  return ELocalization.ENGLISH;
};
