import { IListButtonConfig } from '@features/haulhubUi/interfaces/listButtonConfig';

import { IDateRangeButton } from '../interfaces/dateRangeButton.interface';

export const getDropdownButtonsConfig = (
  dateRangeButtonsConfigList: Record<string, IDateRangeButton>,
  selectedChipButton?: string | null,
): IListButtonConfig[] => {

  return Object.values(dateRangeButtonsConfigList).map((buttonConfig) => {
    return {
      label: buttonConfig.label,
      value: buttonConfig.value,
      isActive: selectedChipButton === buttonConfig.value,
    };
  });
};
