import { FC, memo } from 'react';

import { ITimePickerDayPeriodButtonProps } from './timePickerDayPeriodButton.interface';
import { TimePickerDayPeriodButtonWrapper } from './timePickerDayPeriodButton.theme';
import { timePickerDayPeriodButtonTestId } from './timePickerDayPeriodButton.const';

const TimePickerDayPeriodButton: FC<ITimePickerDayPeriodButtonProps> = (props) => {
  const {
    dayPeriod,
    isActive,
    updateDayPeriod,
  } = props;

  const updateDayPeriodHandler = (): void => {
    updateDayPeriod(dayPeriod);
  };

  return (
    <TimePickerDayPeriodButtonWrapper
      isActive={isActive}
      disabled={isActive}
      data-testid={timePickerDayPeriodButtonTestId}
      onClick={updateDayPeriodHandler}
    >
      {dayPeriod}
    </TimePickerDayPeriodButtonWrapper>
  );
};

export default memo(TimePickerDayPeriodButton);
