import axios from 'axios';

import HttpClient from '@configs/httpClient/httpClient';
// TODO: fix it after app updating
// import http from 'http';
// import https from 'https';

export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URI,
  // TODO: fix it after app updating
  // httpAgent: new http.Agent({ keepAlive: true }),
  // httpsAgent: new https.Agent({ keepAlive: true }),
});

export const httpClient = new HttpClient(axiosInstance);
