import { FC } from 'react';

import {
  FlyoutPopupTabTitle,
  FlyoutPopupTabSubTitle,
} from '@features/haulhubUi/uiKit';

import { ChipsList } from '../chipsList/ChipsList';
import { IChipsListFilterTabContentProps } from './chipsListFilterTabContent.interface';

const ChipsListFilterTabContent: FC<IChipsListFilterTabContentProps> = (props) => {
  const {
    tabTitle,
    tabSubTitle,
    ...restProps
  } = props;
  return (
    <>
      <FlyoutPopupTabTitle>
        {tabTitle}
      </FlyoutPopupTabTitle>

      <FlyoutPopupTabSubTitle>
        {tabSubTitle}
      </FlyoutPopupTabSubTitle>

      <ChipsList {...restProps} />
    </>
  );
};

export { ChipsListFilterTabContent };
