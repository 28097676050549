import { FC, useCallback, useState } from 'react';
import { Button, List, ListItem } from '@mui/material';

import ButtonsList from '@features/haulhubUi/components/buttonsList/ButtonsList';
import { ITimeParts } from '@components/nativeTimePiker/interfaces/ITimeParts.interface';

import { getDropdownButtonsConfig } from '../../configs/getDropdownButtonsConfig';
import { defaultTimePeriodButtonsConfig } from '../../configs/defaultTimePeriodButtonsConfig';
import TimePicker from '../timePicker/TimePicker';
import { DropdownContent } from './dropdownTimePickerContent.theme';
import { IDropdownTimePickerContentProps } from './dropdownTimePickerContent.interface';
import { dropdownContentResetButtonTestId, dropdownContentSaveButtonTestId, dropdownContentTestId, dropdownTimePickerContentTexts } from './dropdownTimePickerContent.const';
import { isSaveButtonDisabled } from './helpers/isSaveButtonDisabled';
import { defaultTimeParts } from './configs/defaultTimeParts';

const DropdownTimePickerContent: FC<IDropdownTimePickerContentProps> = (props) => {
  const {
    timeStart,
    timeEnd,
    options,
    PopperComponent,
    onSaveButtonClick,
  } = props;
  const currentTimeStart = timeStart || defaultTimeParts;
  const currentTimeEnd = timeEnd || defaultTimeParts;
  const [localTimeStart, setLocalTimeStart] = useState<ITimeParts>(currentTimeStart);
  const [localTimeEnd, setLocalTimeEnd] = useState<ITimeParts>(currentTimeEnd);
  const {
    resetButtonTitle,
    saveButtonTitle,
    startTimeLabel,
    endTimeLabel,
  } = options || {};

  const onTimePeriodButtonClick = (value: string): void => {
    setLocalTimeStart(defaultTimePeriodButtonsConfig[value].timeStart);
    setLocalTimeEnd(defaultTimePeriodButtonsConfig[value].timeEnd);
  };

  const onResetButtonClick = (): void => {
    setLocalTimeStart(defaultTimeParts);
    setLocalTimeEnd(defaultTimeParts);
  };

  const onSaveButtonClickHandler = (): void => {
    const currentLocalTimeStart = localTimeStart.hours !== defaultTimeParts.hours && localTimeStart.minutes !== defaultTimeParts.minutes
      ? localTimeStart
      : null;
    const currentLocalTimeEnd = localTimeEnd.hours !== defaultTimeParts.hours && localTimeEnd.minutes !== defaultTimeParts.minutes
      ? localTimeEnd
      : null;
    onSaveButtonClick(currentLocalTimeStart, currentLocalTimeEnd);
  };

  const updateLocalTimeStart = useCallback((newTime: Partial<ITimeParts>): void => {
    setLocalTimeStart(prevState => ({ ...prevState, ...newTime }));
  }, []);

  const updateLocalTimeEnd = useCallback((newTime: Partial<ITimeParts>): void => {
    setLocalTimeEnd(prevState => ({ ...prevState, ...newTime }));
  }, []);

  return (
    <DropdownContent data-testid={dropdownContentTestId}>
      <header className="dropdown-content-header">
        <ButtonsList
          buttonsConfig={getDropdownButtonsConfig(localTimeStart, localTimeEnd)}
          onButtonClick={onTimePeriodButtonClick}
        />
      </header>

      <List className="time-pickers-wrapper">
        <ListItem className="time-picker-wrapper">
          <TimePicker
            time={localTimeStart}
            label={startTimeLabel || dropdownTimePickerContentTexts.START_TIME_LABEL}
            PopperComponent={PopperComponent}
            updateTime={updateLocalTimeStart}
          />
        </ListItem>

        <ListItem className="time-picker-wrapper">
          <TimePicker
            time={localTimeEnd}
            label={endTimeLabel || dropdownTimePickerContentTexts.END_TIME_LABEL}
            PopperComponent={PopperComponent}
            updateTime={updateLocalTimeEnd}
          />
        </ListItem>
      </List>

      <footer className="dropdown-content-footer">
        <Button
          variant="text"
          color="secondary"
          className="reset-button"
          data-testid={dropdownContentResetButtonTestId}
          onClick={onResetButtonClick}
        >
          {resetButtonTitle || dropdownTimePickerContentTexts.RESET_BUTTON}
        </Button>

        <Button
          variant="text"
          color="primary"
          disabled={isSaveButtonDisabled(currentTimeStart, currentTimeEnd, localTimeStart, localTimeEnd)}
          data-testid={dropdownContentSaveButtonTestId}
          onClick={onSaveButtonClickHandler}
        >
          {saveButtonTitle || dropdownTimePickerContentTexts.SAVE_BUTTON}
        </Button>
      </footer>
    </DropdownContent>
  );
};

export default DropdownTimePickerContent;
