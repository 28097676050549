import { FC, createContext, PropsWithChildren } from 'react';

import useTicketsReportParams from '@api/useTicketsReportParams/useTicketsReportParams';
import { IUseTicketsReportParamsBody } from '@api/useTicketsReportParams/useTicketsReportParamsBody.interface';
import { downloadURL } from '@helpers/downloadURL';
import DownloadTicketsReportProgressModal from '@providers/downloadTicketsProvider/components/downloadTicketsReportProgressModal/DownloadTicketsReportProgressModal';
import { manageModalsActions } from '@features/haulhubUi';
import { ThemeProvider } from '@emotion/react';
import { ticketsTheme } from '@features/haulhubUi/theme/ticketsTheme';

import { IDownloadTicketsActions } from './downloadTicketsProviderActions.interface';
import { useTicketsReportWebSocket } from './hook/useTicketsReportWebSocket/useTicketsReportWebSocket';

const DownloadTicketsContext = createContext<undefined>(undefined);

export let downloadTicketsActions: IDownloadTicketsActions;

const DownloadTicketsProvider: FC<PropsWithChildren> = ({ children }) => {
  const ticketsReportWebSocket = useTicketsReportWebSocket();
  const { makeRequestHandler: sendTicketsReportParams } = useTicketsReportParams();

  const downloadTickets = async (ticketsReportParams: Omit<IUseTicketsReportParamsBody, 'connection_id'>): Promise<void> => {
    manageModalsActions.closeModal('downloadSlipsModalOpen');
    manageModalsActions.openModal('downloadTicketsReportProgressModal');
    const connectionId = await ticketsReportWebSocket.actions.getConnectionId();
    if (!connectionId) return;
    sendTicketsReportParams({
      body: {
        ...ticketsReportParams,
        connection_id: connectionId,
      },
    });
    const reportUrl = await ticketsReportWebSocket.actions.getReportUrl();
    if (!reportUrl) return;
    downloadURL(reportUrl);
    manageModalsActions.closeModal('downloadTicketsReportProgressModal');
  };

  downloadTicketsActions = {
    downloadTickets,
  };

  return (
    <DownloadTicketsContext.Provider value={undefined}>
      <>
        {children}

        <ThemeProvider theme={ticketsTheme}>
          <DownloadTicketsReportProgressModal
            disconnectWebSocket={ticketsReportWebSocket.actions.disconnectWebSocket}
          />
        </ThemeProvider>
      </>
    </DownloadTicketsContext.Provider>
  );
};

export { DownloadTicketsProvider, DownloadTicketsContext };
