import { useEffect } from 'react';

import { MessagePayload } from '@firebase/messaging';
import { ENotificationsStatusType, EUserInfoActionsType } from '@features/navigationContainer/components/pushNotificationMenu/enums/notificationStatusType.enum';

import { getFilteredNotifications } from '../../helpers/getFilteredMessages';

export const useBackgroundMessage = (notificationHandler: (payload: MessagePayload) => void): void => {
  const onFocus = (): void => {
    const openRequest = indexedDB.open('backgroundPushNotifications', 1);
    openRequest.onupgradeneeded = (): void => {
      const db = openRequest.result;
      if (!db.objectStoreNames.contains('notifications')) {
        db.createObjectStore('notifications', { autoIncrement: true });
      }
    };

    openRequest.onsuccess = (): void => {
      const db = openRequest.result;
      const transaction = db.transaction('notifications', 'readwrite');
      const notifications = transaction.objectStore('notifications');
      const notificationsArrayRequest = notifications.getAll();
      notificationsArrayRequest.onsuccess = (): void => {
        if (notificationsArrayRequest.result !== undefined && notificationsArrayRequest.result.length) {
          const filteredNotifications = getFilteredNotifications(notificationsArrayRequest.result);
          const materialProducerBlockedNotification = filteredNotifications[ENotificationsStatusType.MATERIAL_PRODUCER];
          if (materialProducerBlockedNotification) {
            notificationHandler(materialProducerBlockedNotification);
            return;
          }
          const userBlockedNotification = filteredNotifications[EUserInfoActionsType.BLOCK] || filteredNotifications[EUserInfoActionsType.ARCHIVE];
          if (userBlockedNotification) {
            notificationHandler(userBlockedNotification);
            return;
          }
          Object.values(filteredNotifications).forEach((notification) => {
            notificationHandler(notification as unknown as MessagePayload);
          });
        }
      };
      notifications.clear();
    };
  };

  useEffect(() => {
    window.addEventListener('focus', onFocus);

    return () => {
      window.removeEventListener('focus', onFocus);
    };
  }, []);
};
