import { ICompletion, IProjectedDetails } from '@pages/projectPlusModule/pages/live/interfaces/completion.interface';
import { ProjectedTargetTimeEnum } from '@pages/projectPlusModule/enums/projectedTargetTime.enum';

type TFieldNames = {
  [ProjectedTargetTimeEnum.PROJECTED_DROP_OFF_TIME]: 'projectedDropOff';
  [ProjectedTargetTimeEnum.PROJECTED_LAST_LOAD_TIME]: 'projectedLastLoad';
};

const fieldNames: TFieldNames = {
  [ProjectedTargetTimeEnum.PROJECTED_DROP_OFF_TIME]: 'projectedDropOff',
  [ProjectedTargetTimeEnum.PROJECTED_LAST_LOAD_TIME]: 'projectedLastLoad',
};

export const getCompetitionType = (
  completion: ICompletion | null,
  projectedTarget: ProjectedTargetTimeEnum,
): IProjectedDetails | null => {
  if (!completion) return null;
  if (projectedTarget === ProjectedTargetTimeEnum.COMPLETION_TIME) return null;
  return completion[fieldNames[projectedTarget]];
};
