import React, { FC } from 'react';
import { Box } from '@mui/material';
import { SwishSpinner } from 'react-spinners-kit';

import BaseLogo from '../baseLogo';
import useSuspenseLoadingStyles from './suspenseLoading.theme';
import suspenseLoadingCircle from './suspenseLoading.const';
import { ISuspenseLoadingProps } from './suspenseLoading.types';

const SuspenseLoading: FC<ISuspenseLoadingProps> = props => {
  const {
    showLogo = false,
    color = '#4ADFE9',
    size = 30,
  } = props;
  const { classes } = useSuspenseLoadingStyles(props);

  return (
    <Box
      className={classes.wrapper}
      data-testid={suspenseLoadingCircle}
    >
      {showLogo &&
        <BaseLogo
          size='large'
          cardIndent={[0, 0, 45, 0]}
        />
      }

      <SwishSpinner
        size={size}
        frontColor={color}
      />
    </Box>
  );
};

export default SuspenseLoading;
