import {
  subMonths,
  addMonths,
  format,
  isToday,
  isYesterday,
  lastDayOfMonth,
} from 'date-fns';
import { TFunction } from 'i18next';

import { getDateLocalizationModule } from '@helpers/getDateLocalizationModule';

import { dayNames } from '../historical.const';

interface IJumpToMonthOptions {
  monthNumber?: number;
  isLastDay?: boolean;
}

const currentDate = new Date();

const findPrevMonthDate = (value: Date): Date => {
  return subMonths(value, 1);
};

const findForwardMonthDate = (value: Date): Date => {
  return addMonths(value, 1);
};

const jumpToPreviousMonth = (currentMonth: Date, options?: IJumpToMonthOptions): Date => {
  const monthNumber = options?.monthNumber || 1;
  const isLastDay = options?.isLastDay || false;
  const currentMonthNumber = isLastDay ? monthNumber - 1 : monthNumber;
  return new Date(currentMonth.getFullYear(), currentMonth.getMonth() - currentMonthNumber, isLastDay ? 0 : 1);
};

const jumpToNextMonth = (currentMonth: Date, options?: IJumpToMonthOptions): Date => {
  const monthNumber = options?.monthNumber || 1;
  const isLastDay = options?.isLastDay || false;
  const currentMonthNumber = isLastDay ? monthNumber + 1 : monthNumber;
  return new Date(currentMonth.getFullYear(), currentMonth.getMonth() + currentMonthNumber, isLastDay ? 0 : 1);
};

const getMonthName = (value: Date): string => {
  return format(value, 'LLLL', { locale: getDateLocalizationModule() });
};

const getFullDate = (value: Date): string => {
  return format(value, 'yyyy-MM-dd');
};

// TODO need global TF
const getDropDownLabelDate = (
  value: Date,
  t: TFunction<'date'[], undefined>
  ): string => {
  if (isToday(value)) return t(dayNames.TODAY, { ns: 'date' });

  if (isYesterday(value)) return t(dayNames.YESTERDAY, { ns: 'date' });

  return format(value, 'EEE, MMM d', { locale: getDateLocalizationModule() });
};

const getMonthDateFirstDay = (value: Date): string => {
  return getFullDate(
    new Date(value.getFullYear(), value.getMonth(), 1),
  );
};

const getMonthDateLastDay = (value: Date): string => {
  return getFullDate(lastDayOfMonth(value));
};

const getInitFromDate = (): string => {
  const prevMonthDate = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
  return getFullDate(prevMonthDate);
};

const getInitToDate = (): string => {
  return getFullDate(lastDayOfMonth(currentDate));
};

const getFromDate = (value: Date): string => {
  return getFullDate(findPrevMonthDate(value));
};

const getToDate = (value: Date): string => {
  return getMonthDateLastDay(value);
};

const generateFromDayForRequest = (date: string): string => {
  const rawDate = new Date(`${date} 00:00`);
  const modifyDate = subMonths(rawDate, 1);
  return format(modifyDate, 'yyyy-MM-dd');
};

export {
  findPrevMonthDate,
  findForwardMonthDate,
  jumpToPreviousMonth,
  jumpToNextMonth,
  getMonthName,
  getFullDate,
  getDropDownLabelDate,
  getMonthDateFirstDay,
  getMonthDateLastDay,
  getInitFromDate,
  getInitToDate,
  getFromDate,
  getToDate,
  generateFromDayForRequest,
};
