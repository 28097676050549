import { createAsyncThunk } from '@reduxjs/toolkit';
import { stringify } from 'qs';

import api from '@configs/urls/api';
import { ITrafficRound } from '@store/projectPlus/projectPlusDetails/interfaces/trafficRound.interface';

import instance from '../instanceDefiner';
import { IProjectPlusLapTrackTrafficParams } from './projectPlusLapTrackTrafficParams.interface';

export const projectPlusLapTrackTraffic = createAsyncThunk(
  'projectPlusCounters/projectPlusLapTrackTraffic',
  async (filters: IProjectPlusLapTrackTrafficParams) => {
    try {
      const response = await instance.get<ITrafficRound[]>(api.getTrafficValuesForLapTrackChart, {
        params: {
          filters,
        },
        paramsSerializer: {
          serialize: (params) => stringify(params.filters, { arrayFormat: 'repeat', encode: true }),
        },
      });
      return response.data;
    } catch {
      return null;
    }
  },
);
