import { BaseSyntheticEvent, FC, useRef, useState, memo, useEffect } from 'react';
import { InputAdornment } from '@mui/material';

import { StyledTextField } from './searchField.theme';
import { ISearchFieldProps } from './searchFieldProps.interface';
import { searchFieldTexts } from './searchField.const';

const SearchField: FC<ISearchFieldProps> = memo((props) => {
  const {
    value = '',
    onInputChangeHandler,
    debounceTime = 500,
    placeholder,
  } = props;
  const [currentValue, setCurrentValue] = useState(value || '');
  const timeoutIdRef = useRef<any>('');

  const searchHandler = (event: BaseSyntheticEvent): void => {
    const newValue = event.target.value || '';
    setCurrentValue(newValue);
    clearTimeout(timeoutIdRef.current);
    timeoutIdRef.current = setTimeout(() => {
      onInputChangeHandler(newValue);
    }, debounceTime);
  };

  useEffect(() => {
    setCurrentValue(prevState => prevState !== value ? value : prevState );
  }, [value]);

  return (
    <StyledTextField
      value={currentValue}
      onChange={searchHandler}
      InputProps={{
        disableUnderline: true,
        placeholder: placeholder || searchFieldTexts.SEARCH_PLACEHOLDER_TEXT,
        startAdornment: (
          <InputAdornment
            position="start"
            className="search-icon-wrapper"
          >
            {/* TODO move icon to feature if we have same design */}
            <svg
              width="12"
              height="12"
            >
              <use
                x="0"
                y="0"
                xlinkHref="#searchLoupe"
              />
            </svg>
          </InputAdornment>
        ),
      }}
      variant="standard"
      color="secondary"
    />
  );
});

export { SearchField };
