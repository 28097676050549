export enum LocalStorageKeysEnum {
  TOP_PROJECTS_DEFAULT_FILTERS = 'TOP_PROJECTS_DEFAULT_FILTERS',
  PROJECTS_PLUS_LIST_FILTERS = 'PROJECTS_PLUS_LIST_FILTERS',
  PUSH_NOTIFICATION_TOKEN = 'PUSH_NOTIFICATION_TOKEN',
  OKTA_DATA = '@@okta::data@@',
  CHARNZERO_LOGIN = 'CHARNZERO_LOGIN',
  PROJECT_PLUS_OPTIONS = 'PROJECT_PLUS_OPTIONS',
  LANGUAGE = 'language',
  TOP_FIFTY_FILTERS = 'TOP_FIFTY_FILTERS',
  TRUCK_LOG_FILTERS = 'TRUCK_LOG_FILTERS',
  AUTH_APP_STATE = 'OIDC_STATE',
  MAP_VIEW = 'MAP_VIEW',
}
