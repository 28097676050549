import { AxiosInstance, AxiosError } from 'axios';

import {
  IDeleteRequestOptions,
  IGetRequestOptions,
  IPatchRequestOptions,
  IPostRequestOptions,
  IPutRequestOptions,
} from './interfaces/requests.interface';
import { ApiResponse } from './interfaces/responses.interface';

class HttpClient {
  private axiosInstance: AxiosInstance;

  constructor(axios: AxiosInstance) {
    this.axiosInstance = axios;
  }

  async get<T, >(requestConfig: IGetRequestOptions): Promise<ApiResponse<T>> {
    const {
      url,
      config = {},
    } = requestConfig;
    try {
      const response = await this.axiosInstance.get(url, config);
      const {
        data,
        status,
      } = response;
      return {
        data,
        status,
      };
    } catch (rawError: unknown) {
      const error = rawError as AxiosError;
      throw error;
    }
  }

  async post<T, K>(requestConfig: IPostRequestOptions<K>): Promise<ApiResponse<T>> {
    const {
      url,
      postData,
      config = {},
    } = requestConfig;
    try {
      const response = await this.axiosInstance.post(url, postData, config);
      const {
        data,
        status,
      } = response;
      return {
        data,
        status,
      };
    } catch (rawError: unknown) {
      const error = rawError as AxiosError;
      throw error;
    }
  }

  async patch<T, K>(requestConfig: IPatchRequestOptions<K>): Promise<ApiResponse<T>> {
    const {
      url,
      patchData,
      config = {},
    } = requestConfig;
    try {
      const response = await this.axiosInstance.patch(url, patchData, config);
      const {
        data,
        status,
      } = response;
      return {
        data,
        status,
      };
    } catch (rawError: unknown) {
      const error = rawError as AxiosError;
      throw error;
    }
  }

  async put<T, K>(requestConfig: IPutRequestOptions<K>): Promise<ApiResponse<T>> {
    const {
      url,
      putData,
      config = {},
    } = requestConfig;
    try {
      const response = await this.axiosInstance.put(url, putData, config);
      const {
        data,
        status,
      } = response;
      return {
        data,
        status,
      };
    } catch (rawError: unknown) {
      const error = rawError as AxiosError;
      throw error;
    }
  }

  async delete<T, >(requestConfig: IDeleteRequestOptions): Promise<ApiResponse<T>> {
    const {
      url,
      config = {},
    } = requestConfig;
    try {
      const response = await this.axiosInstance.delete(url, config);
      const {
        data,
        status,
      } = response;
      return {
        data,
        status,
      };
    } catch (rawError: unknown) {
      const error = rawError as AxiosError;
      throw error;
    }
  }
}

export default HttpClient;
