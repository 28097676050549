import { FC, ReactElement, memo, useCallback, useState } from 'react';
import { Button } from '@mui/material';
import WestIcon from '@mui/icons-material/West';
import EastIcon from '@mui/icons-material/East';
import {
  format,
  isSameDay,
  subMonths,
} from 'date-fns';
import { DayPicker, DateRange } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

import ButtonsList from '@features/haulhubUi/components/buttonsList/ButtonsList';
import MemoizedButton from '@features/haulhubUi/components/memoizedButton/MemoizedButton';
import { useFeatureFlags } from '@providers/featureFlagsProvider';
import { fFlagsNames } from '@configs/fFlagsNames/fFlagsNames';

import { getDropdownButtonsConfig } from '../../configs/getDropdownButtonsConfig';
import { getInitialDateRange } from '../../helpers/getInitialDateRange';
import { defaultDateRangeButtonsConfigList } from '../../configs/defaultDateRangeButtonsConfigList';
import { IDropdownDatePickerContentProps } from './dropdownDatePickerContent.interface';
import { DropdownContent } from './dropdownDatePickerContent.theme';
import { dropdownDatePickerContentTexts } from './dropdownDatePickerContent.const';

const DropdownDatePickerContent: FC<IDropdownDatePickerContentProps> = (props) => {
  const {
    dateStart,
    dateEnd,
    resetButtonTitle,
    saveButtonTitle,
    selectedChipButton,
    dateRangeButtonsConfigList = defaultDateRangeButtonsConfigList,
    closeDropdownHandler,
    changeDateRangeHandler,
    updateSelectedChipButton,
  } = props;
  const today = new Date();
  const { isUnderFeatureFlag } = useFeatureFlags();
  const [dateRange, setDateRange] = useState<DateRange | undefined>(getInitialDateRange(dateStart, dateEnd));
  const [innerSelectedChipButton, setInnerSelectedChipButton] = useState<string | null>(selectedChipButton);
  const [currentMonth, setCurrentMonth] = useState<Date | undefined>(subMonths(dateRange?.from || today, 1));

  const onDateButtonClick = useCallback((value: string): void => {
    setInnerSelectedChipButton(value);
    setDateRange({
      from: dateRangeButtonsConfigList[value].from,
      to: dateRangeButtonsConfigList[value].to,
    });
    setCurrentMonth(subMonths(today, 1));
  }, []);

  const onResetButtonClick = useCallback((): void => {
    setDateRange(undefined);
    setInnerSelectedChipButton(null);
  }, []);

  const onSaveButtonClick = (): void => {
    changeDateRangeHandler(
      dateRange?.from ? format(new Date(dateRange?.from), 'yyyy-MM-dd') : null,
      dateRange?.to ? format(new Date(dateRange?.to), 'yyyy-MM-dd') : null,
    );
    updateSelectedChipButton(innerSelectedChipButton);
    closeDropdownHandler();
  };

  const onSelect = (_range: DateRange | undefined, selectedDay: Date): void => {
    setInnerSelectedChipButton(null);
    setDateRange((prevState) => {
      const isResetDateRange = !!prevState?.from && isSameDay(selectedDay, prevState.from);
      const isSetFromDate = !prevState || (!!prevState?.from && !!prevState?.to);
      const isSetToDate = !!prevState?.from && !prevState.to;
      const isFromInThePast = !!prevState?.from && selectedDay > prevState.from;

      if (isResetDateRange) return undefined;
      if (isSetFromDate) {
        return {
          from: selectedDay,
          to: undefined,
        };
      }
      if (isSetToDate && isFromInThePast) {
        return {
          from: prevState.from,
          to: selectedDay,
        };
      }
      if (isSetToDate && !isFromInThePast) {
        return {
          from: selectedDay,
          to: prevState.from,
        };
      }
    });
  };

  const onMonthChange = (newMonth: Date): void => {
    setCurrentMonth(newMonth);
  };

  const renderIconLeft = (): ReactElement => <WestIcon fontSize="small" />;
  const renderIconRight = (): ReactElement => <EastIcon fontSize="small" />;

  return (
    <DropdownContent>
      <header className="dropdown-content-header">
        <ButtonsList
          buttonsConfig={getDropdownButtonsConfig(dateRangeButtonsConfigList, innerSelectedChipButton)}
          onButtonClick={onDateButtonClick}
        />
      </header>

      <DayPicker
        numberOfMonths={2}
        mode="range"
        month={currentMonth}
        selected={dateRange}
        onSelect={onSelect}
        toDate={today}
        components={{
          IconLeft: renderIconLeft,
          IconRight: renderIconRight,
        }}
        onMonthChange={onMonthChange}
      />

      <footer className="dropdown-content-footer">
        {!isUnderFeatureFlag(fFlagsNames.fflagYearToDate)
          ? <MemoizedButton
              variant="text"
              color="secondary"
              className="reset-button"
              onClick={onResetButtonClick}
            >
              {resetButtonTitle || dropdownDatePickerContentTexts.RESET_BUTTON}
            </MemoizedButton>
          : null
        }

        <Button
          variant="text"
          color="primary"
          onClick={onSaveButtonClick}
        >
          {saveButtonTitle || dropdownDatePickerContentTexts.SAVE_BUTTON}
        </Button>
      </footer>
    </DropdownContent>
  );
};

export default memo(DropdownDatePickerContent);
