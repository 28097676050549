import { TProjectPlusOptions } from '@store/projectPlus/optionsPopup/interfaces/optionsPopupState.interface';

type TProjectPlusOptionsKeys = keyof Partial<TProjectPlusOptions>;

export const plantsField: TProjectPlusOptionsKeys[] = [
  'plantIds',
];

export const fleetsField: TProjectPlusOptionsKeys[] = [
  'fleetIds',
];

export const customersField: TProjectPlusOptionsKeys[] = [
  'customerIds',
];

export const materialTypesField: TProjectPlusOptionsKeys[] = [
  'materialTypes',
];

export const trucksField: TProjectPlusOptionsKeys[] = [
  'trucks',
];

export const filtersUpdateForRequest: TProjectPlusOptionsKeys[] = [
  ...plantsField,
  ...customersField,
  ...materialTypesField,
];

export const filtersUpdateLocally: TProjectPlusOptionsKeys[] = [
  ...plantsField,
  ...customersField,
  ...materialTypesField,
  ...trucksField,
  ...fleetsField,
  // TODO: commented according to task https://haulhub.atlassian.net/browse/SW-2813
  // 'tonnageRange',
];

export const settingsUpdateForRequest: TProjectPlusOptionsKeys[] = [
  'endTime',
  'startTime',
  'projectedTargetTime',
  'exclusionThresholdEnabled',
  'exclusionThresholdInMinutes',
];

export const settingsUpdateLocally: TProjectPlusOptionsKeys[] = [
  'endTime',
  'startTime',
  'projectedTargetTime',
  'exclusionThresholdEnabled',
  'exclusionThresholdInMinutes',
];

export const getFieldsForUpdating = (
  options: TProjectPlusOptions,
  fieldNames: TProjectPlusOptionsKeys[],
): Partial<TProjectPlusOptions> => {
  let fieldsForUpdating: Partial<TProjectPlusOptions> = {};
  fieldNames.forEach((field: TProjectPlusOptionsKeys) => {
    fieldsForUpdating = {
      ...fieldsForUpdating,
      [field]: options[field],
    };
  });

  return fieldsForUpdating;
};

export const getFieldsForResetting = (
  fieldNames: TProjectPlusOptionsKeys[],
): Partial<TProjectPlusOptions> => {
  let fieldsForUpdating: Partial<TProjectPlusOptions> = {};
  fieldNames.forEach((field: TProjectPlusOptionsKeys) => {
    fieldsForUpdating = {
      ...fieldsForUpdating,
      [field]: null,
    };
  });

  return fieldsForUpdating;
};
