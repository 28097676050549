import { getStorageItem, removeStorageItem, setStorageItem } from '../../../../helpers/localStorage';
import { IOktaData, IOktaStoredPayload } from '../oktaClient.interface';
import { wrapDataEntry } from './helpers/wrapDataEntry';

export class LocalstorageStore {

  saveData(entry: IOktaData, storageKey: string): void {
    const data: IOktaStoredPayload = wrapDataEntry(entry);
    setStorageItem<IOktaStoredPayload>(storageKey, data);
  }

  getData(fieldKey: string): Partial<IOktaData> | null {
    const data = getStorageItem<IOktaStoredPayload>(fieldKey);
    const now = new Date().getTime();

    if (!data) {
      return null;
    }

    if (data.expiresAt < now) {
      if (data.body.refreshToken) {
        data.body = {
          refreshToken: data.body.refreshToken,
        };

        return data.body;
      }

      return null;
    }

    return data.body;
  }

  clearData(storageKey: string): void {
    removeStorageItem(storageKey);
  }
}
