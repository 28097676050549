import { FC, createContext, useEffect, PropsWithChildren, useState, useMemo } from 'react';
import { getToken, MessagePayload, onMessage } from 'firebase/messaging';

import { messageToTopisSubscribeHandler, messaging, vapidKey } from '@configs/firebase/firebase.config';
import { IPushNotification } from '@features/sharedWidgets/pushNotificationsProvider/interfaces/pushNotification.interface';
import useTenantProvider from '@providers/tenantProvider/useTenantProvider';
import { LocalStorageKeysEnum } from '@enums/localStorageKeys.enum';
import { setStorageItem } from '@helpers/localStorage';
import { useNavigation } from '@hooks/useNavigation/useNavigation';
import { ENotificationsStatusType, ESilentNotificationsStatusType } from '@features/navigationContainer/components/pushNotificationMenu/enums/notificationStatusType.enum';

import { useSilentPushNotificationsController } from './hooks/useSilentPushNotificationsController/useSilentPushNotificationsController';
import { IPushNotificationsContext } from './pushNotificationsProvider.interface';
import { pushNotificationToTopicName } from './helpers/createTopicName';
import { TPushNotificationsActions } from './pushNotificationsActions.interface';
import { postFireBaseToken } from './helpers/postFireBaseToken';
import { useManageWidgetNotifications } from './hooks/useManageWidgetNotifications/manageWidgetNotifications';
import { supportedNotificationTypeList } from './configs/supportedNotificationTypeList';
import { useBackgroundMessage } from './hooks/useBackgroundMessage/useBackgroundMessage';

const DASHBOARD_PATH = '/dashboard';

export let pushNotificationsActions: TPushNotificationsActions;

const PushNotificationsContext = createContext<IPushNotificationsContext | undefined>(undefined);

const PushNotificationsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [lastPushNotification, setLastPushNotification] = useState<IPushNotification | null>(null);
  const {
    values,
    handlers,
  } = useManageWidgetNotifications();
  const { isCurrentLink } = useNavigation();
  const { value: tenant } = useTenantProvider();
  const {
    isSilentPush,
    silentPushChecker,
  } = useSilentPushNotificationsController();
  const {
    checkWidgetTypeHandler,
    ...otherManageWidgetNotificationsHandlers
  } = handlers;

  const getNotificationTokenHandler = async (): Promise<void> => {
    try {
      const messagingResolve = await messaging;
      if (!messagingResolve) return;
      const notificationToken = await getToken(messagingResolve, { vapidKey });
      setStorageItem(
        LocalStorageKeysEnum.PUSH_NOTIFICATION_TOKEN,
        { [LocalStorageKeysEnum.PUSH_NOTIFICATION_TOKEN]: notificationToken }
      );
      postFireBaseToken(notificationToken);
      if (!tenant) return;
      messageToTopisSubscribeHandler({
        deviceToken: notificationToken,
        topicName: pushNotificationToTopicName(tenant.materialProducerId),
      });
    } catch (err) {}
  };

  const notificationHandler = (payload: MessagePayload): void => {
    const { data } = payload || {};
    if (!data) return;
    const notificationType = data.notificationType;
    if (isSilentPush(notificationType as ESilentNotificationsStatusType)) {
      silentPushChecker(data as unknown as IPushNotification);
      return;
    }
    if (!supportedNotificationTypeList.includes(notificationType as ENotificationsStatusType)) return;
    setLastPushNotification(data as unknown as IPushNotification);
    if (!isCurrentLink(DASHBOARD_PATH)) return;
    checkWidgetTypeHandler(data as unknown as IPushNotification);
  };

  useBackgroundMessage(notificationHandler);

  const messageListenerHandler = async (): Promise<void> => {
    const messagingResolve = await messaging;
    if (!messagingResolve) return;
    onMessage(messagingResolve, notificationHandler);
  };

  useEffect(() => {
    messageListenerHandler();
    getNotificationTokenHandler();
  }, []);

  pushNotificationsActions = {
    ...otherManageWidgetNotificationsHandlers,
  };

  const value = useMemo(() => ({
    lastPushNotification,
    ...values,
  }), [
    lastPushNotification,
    values,
  ]);

  return (
    <PushNotificationsContext.Provider value={value}>
      {children}
    </PushNotificationsContext.Provider>
  );
};

export { PushNotificationsProvider, PushNotificationsContext };
