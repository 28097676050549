import { IListButtonConfig } from '@features/haulhubUi/interfaces/listButtonConfig';
import { ITimeParts } from '@components/nativeTimePiker/interfaces/ITimeParts.interface';
import { deepCompare } from '@helpers/deepCompare';
import { defaultTimePeriodButtonsConfig } from './defaultTimePeriodButtonsConfig';

export const getDropdownButtonsConfig = (timeStart: ITimeParts, timeEnd: ITimeParts): IListButtonConfig[] => {
  return Object.values(defaultTimePeriodButtonsConfig).map((buttonConfig) => {
    return {
      label: buttonConfig.label,
      value: buttonConfig.value,
      isActive: deepCompare(timeStart, buttonConfig.timeStart) && deepCompare(timeEnd, buttonConfig.timeEnd),
      size: 'large',
    };
  });
};
