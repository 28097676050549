import { alpha } from '@mui/system';
import { makeStyles } from '@theme/appTheme';

import { p, rem, br } from '@helpers/remUtils';

const useGlobalContainerStyles = makeStyles()((theme) => ({
  containerRoot: {
    zIndex: '999999!important' as unknown as number,
  },
  popupTitle: {
    margin: 0,
    padding: p(13, 22, 13, 22),
    borderRadius: br(10, 10, 0, 0),
    fontSize: rem(18),
    fontWeight: 700,
    background: alpha('#689AA8', 0.5),
  },
  overflowX: {
    overflowX: 'auto',
  },
  resetList: {
    margin: 0,
    padding: 0,
  },
  resetListItem: {
    listStyle: 'none',
  },
  popupWrapper: {
    background: theme.palette.background.paper,
    boxShadow: '0px 2.7px 9px rgba(0, 0, 0, 0.09)',
    borderRadius: rem(10),
    wordBreak: 'break-all',
  },
  popupWrapperFlyOut: {
    background: theme.palette.background.paper,
    boxShadow: '0px 2.7px 9px rgba(0, 0, 0, 0.09)',
    wordBreak: 'break-all',
  },
  emptyData: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: p(50, 0, 50, 0),
    fontSize: rem(14),
    textAlign: 'center',
    fontStyle: 'italic',
    opacity: '0.7',
  },
  cardWrapper: {
    width: '100%',
    backgroundColor: '#3E4362',
    borderRadius: rem(4),
  },
  hideElement: {
    visibility: 'hidden',
  },
}));

export default useGlobalContainerStyles;
