import { forwardRef } from 'react';
import { CustomContentProps, SnackbarContent } from 'notistack';

import { EToasterVariant } from '@enums/toasterVariant.enum';

import BaseToaster from '../baseToaster/BaseToaster';

const SuccessToaster = forwardRef<HTMLDivElement, CustomContentProps>((props, ref) => {
  const { message } = props;

  return (
    <SnackbarContent
      ref={ref}
      role="alert"
    >
      <BaseToaster
        message={message}
        variant={EToasterVariant.SUCCESS}
      />
    </SnackbarContent>
  );
});

export default SuccessToaster;
