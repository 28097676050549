import { FC } from 'react';

import { useGlobalFlexStyles } from '@assets/globalStyles';

import useStylesBaseToaster from './baseToaster.theme';
import { IBaseToaster } from './baseToaster.interface';
import { baseToasterConfig } from './baseToaster.config';

const BaseToaster: FC<IBaseToaster> = (props) => {
  const {
    message,
    variant,
  } = props;
  const { classes: globalFlexClasses } = useGlobalFlexStyles();
  const { classes, cx } = useStylesBaseToaster();
  const {
    className,
    IconComponent,
  } = baseToasterConfig[variant];

  return (
    <div
      className={cx(
        globalFlexClasses.flexRowItemCenter,
        classes.baseToasterRoot,
        className,
      )}
    >
      <IconComponent className={classes.baseToasterIcon} />

      <span className={classes.baseToasterMessage}>
        {message}
      </span>
    </div>
  );
};

export default BaseToaster;
