import { ElementType, ReactElement } from 'react';
import { useDroppable } from '@dnd-kit/core';
import { SortableContext } from '@dnd-kit/sortable';

import { IDndListProps } from './dndList.interface';
import { ListWrapper } from './dndList.theme';

const DndList = <TList extends { id: string }, >(props: IDndListProps<TList>): ReactElement => {
  const {
    id,
    useDroppableConfig,
    items,
    listTitle,
    strategy,
    disabled,
    renderItem,
    component,
  } = props;
  const CustomElement = component || ('ul' as ElementType);
  const { setNodeRef } = useDroppable({ id, ...useDroppableConfig });

  return (
    <ListWrapper>
      {listTitle
        ? <h6 className="list-title">
            {listTitle}
          </h6>
        : null
      }

      <SortableContext
        id={id}
        items={items}
        strategy={strategy}
        disabled={disabled}
      >
        <CustomElement
          className="dnd-list-wrapper"
          ref={setNodeRef}
        >
          {items.map((item) => {
            return renderItem(item);
          })}
        </CustomElement>
      </SortableContext>
    </ListWrapper>
  );
};

export default DndList;
