import { alpha, styled } from '@mui/material';
import { br, rem } from '@helpers/remUtils';

export const TimePickerButtonWrapper = styled('button')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 0,
  padding: 0,
  width: rem(65),
  height: rem(50),
  outline: 'none',
  borderRadius: br(6),
  border: `${rem(1)} solid ${theme.palette.additionalColors.divider.secondary}`,
  background: 'transparent',
  fontSize: rem(27),
  fontWeight: 700,
  color: alpha(theme.palette.text.primary, 0.9),
  cursor: 'pointer',
}));
