const apiHHAdminUrlV1 = '/supplier_ci/api/v1/';

const apiHHAdmin = {
  getClassificationMaterialsList: `${apiHHAdminUrlV1}materials`,
  getTicketTypesMaterials: `${apiHHAdminUrlV1}materials/ticket_types`,
  getEditableClassificationMaterials: `${apiHHAdminUrlV1}materials/group_details`,
  updateClassificationMaterials: `${apiHHAdminUrlV1}materials/bulk_update`,
  getPlantHealthList: `${apiHHAdminUrlV1}plants`,
};

export default apiHHAdmin;
