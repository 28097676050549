import { FC, ReactElement, useEffect, useRef } from 'react';
import { List } from '@mui/material';

import TimeEntityListItem from '../timeEntityListItem/TimeEntityListItem';
import { ITimeEntityListProps } from './timeEntityList.interface';
import { TimeEntityListWrapper } from './timeEntityList.theme';

const TimeEntityList: FC<ITimeEntityListProps> = (props) => {
  const {
    config,
    currentValue,
    onItemClick,
  } = props;
  const defaultActiveElement = useRef<HTMLLIElement | null>(null);

  useEffect(() => {
    if (!defaultActiveElement.current) return;
    defaultActiveElement.current.scrollIntoView({ block: 'start' });
  }, []);

  return (
    <TimeEntityListWrapper>
      <List className="time-entity-list">
        {config.map((item): ReactElement => {
          const isActive = item === currentValue;
          return (
            <TimeEntityListItem
              key={item}
              item={item}
              isActive={isActive}
              listItemRef={isActive ? defaultActiveElement : undefined}
              onItemClick={onItemClick}
            />
          );
        })}
      </List>
    </TimeEntityListWrapper>
  );
};

export default TimeEntityList;
