import { styled } from '@mui/material';
import { br, m, p, rem } from '@helpers/remUtils';

export const DropdownContent = styled('div')(({ theme }) => ({
  margin: m(5, 0, 0),
  width: rem(560),
  borderRadius: br(7),
  border: `${rem(1)} solid ${theme.palette.additionalColors.divider.secondary}`,
  backgroundColor: theme.palette.additionalColors.background.secondary,
  boxShadow: `0px 10px 32px 0px ${theme.palette.additionalColors.boxShadow.main}`,

  '.dropdown-content-header': {
    padding: p(14, 10),
    borderBottom: `${rem(1)} solid ${theme.palette.additionalColors.divider.secondary}`,
  },

  '.rdp': {
    margin: 0,

    '--rdp-cell-size': rem(35),
  },

  '.rdp-months': {
    justifyContent: 'center',
  },

  '.rdp-month': {
    padding: p(18, 13, 13),
  },

  '.rdp-caption_start': {
    margin: 0,
    borderRight: `${rem(1)} solid ${theme.palette.additionalColors.divider.secondary}`,
  },

  '.rdp-caption_end': {
    margin: 0,
  },

  '.rdp-button': {
    '&:hover:not([disabled]):not(.rdp-day_selected)': {
      backgroundColor: theme.palette.additionalColors.background.fourthly,
    },
  },

  '.rdp-nav_button': {
    color: theme.palette.text.secondary,

    '&:hover:not([disabled]):not(.rdp-day_selected)': {
      backgroundColor: 'transparent',
    },
  },

  '.rdp-caption_label': {
    fontSize: rem(13),
    fontWeight: 500,
  },

  '.rdp-head_cell': {
    fontSize: rem(11),
    fontWeight: 500,
  },

  '.rdp-day': {
    fontSize: rem(11),
    fontWeight: 500,

    '&_today': {
      border: `${rem(1)} solid ${theme.palette.additionalColors.divider.thirdly}`,
      fontWeight: 500,
    },

    '&_selected': {
      border: 'none',
      fontWeight: 500,
    },

    '&_range_middle': {
      border: 'none',
      background: theme.palette.additionalColors.background.fourthly,

      '&:hover': {
        background: theme.palette.additionalColors.background.fourthly,
      },
    },

    '&_range_end': {
      background: theme.palette.additionalColors.background.thirdly,
      color: theme.palette.text.dark,

      '&:hover': {
        background: theme.palette.additionalColors.background.thirdly,
        color: theme.palette.text.dark,
      },

      '&::before': {
        content: '""',
        position: 'absolute',
        width: rem(35),
        height: rem(35),
        background: `radial-gradient(circle, transparent 71%, ${theme.palette.additionalColors.background.fourthly} 0)`,
      },
    },

    '&_range_start': {
      background: theme.palette.additionalColors.background.thirdly,
      color: theme.palette.text.dark,

      '&:hover': {
        background: theme.palette.additionalColors.background.thirdly,
        color: theme.palette.text.dark,
      },

      '&::before': {
        content: '""',
        position: 'absolute',
        width: rem(35),
        height: rem(35),
        background: 'radial-gradient(circle, transparent 71%, #3d4e6b 0)',
      },
    },
  },

  '.dropdown-content-footer': {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: p(10),
    borderTop: `${rem(1)} solid ${theme.palette.additionalColors.divider.secondary}`,
  },

  '.reset-button': {
    marginRight: rem(5),
  },
}));
