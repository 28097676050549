import indentConvertor from '@helpers/indentConvertor';
import { rem, m } from '@helpers/remUtils';
import { makeStyles } from '@theme/appTheme';

import { IBaseLogoProps } from './baseLogo.types';

const useStylesBaseLogo = makeStyles<IBaseLogoProps>()((theme, props) => ({
  // TODO: clear styles after implement new logo design
  baseWrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: indentConvertor(props.cardIndent),
  },
  image: {
    '&.small': {
      width: rem(21),
      height: rem(19),
      margin: m(-4, 3, 0, 0),
    },
    '&.large': {
      width: rem(36),
      height: rem(32),
      margin: '-8px 7px 0 0',
    },
  },
  text: {
    fontWeight: 900,
    lineHeight: rem(15),
    color: theme.palette.text.primary,
    '&.small': {
      fontSize: rem(17),
    },
    '&.large': {
      fontSize: rem(29),
    },
  },
  modifyColor: {
    color: theme.palette.secondary.main,
  },
  supplierLogo: {
    marginTop: rem(-5),
    '&.small': {
      width: rem(130),
      height: rem(30),
    },
    '&.middle': {
      width: rem(168),
      height: rem(45),
    },
    '&.large': {
      width: rem(170),
      height: rem(50),
    },
  },
  logoText: {
    position: 'absolute',
    left: '-99999px',
  },
}));

export default useStylesBaseLogo;
