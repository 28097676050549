import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootStateType } from '@store/interfaces/rootState.type';

import api from '@configs/urls/api';

import instance from '../instanceDefiner';
import {
  IProjectPlusOneParams,
  IProjectPlusOneResponse,
} from './projectPlusOne.interface';

let controller = new AbortController();

export const getProjectPlusOne = createAsyncThunk(
  'selectedProjectPlusItem/getProjectPlusOne',
  async (inputParams?: IProjectPlusOneParams) => {
    try {
      const response = await instance.get<IProjectPlusOneResponse>(api.getProjectPlusOne, {
        params: { ...inputParams },
        signal: controller.signal,
      });
      return response.data;
    } catch {
      return null;
    }
  },
  {
    condition: (arg, apiData): any => {
      const { selectedProjectPlusItemReducer } = apiData.getState() as RootStateType;

      if (selectedProjectPlusItemReducer.loading) {
        controller.abort();
        controller = new AbortController();
      }
    },
  },
);
