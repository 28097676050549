export const redirectLinks = {
  deepLink: 'https://uzedy.app.link/R7OBrYt92wb',
  downloadIOSApp: 'https://apps.apple.com/us/app/sales-slip/id1552268729',
  downloadAndroidApp: 'https://play.google.com/store/apps/details?id=com.haulhub.android.salesslip',
};

export const mockChildrenText = 'testText';

export const devices = {
  android: {
    deviceName: 'Android',
    className: 'android',
  },
  iPhone: {
    deviceName: 'iPhone',
    className: 'apple',
  },
  iPad: {
    deviceName: 'iPad',
    className: 'apple',
  },
};
