import eventBus from '@eventBus/index';
import { EventBusCallbackType } from '@eventBus/interfaces/callback.interface';
import { IPushNotification } from '@features/sharedWidgets/pushNotificationsProvider/interfaces/pushNotification.interface';

import { EventBusTypes } from '../../enums/eventBusTypes.enum';

const eventName = EventBusTypes.USER_INFO_NOTIFICATION;

const subscribe = (callback: EventBusCallbackType<IPushNotification>): VoidFunction => {
  return eventBus.subscribe(eventName, callback);
};

const broadcast = (data: IPushNotification): void => {
  eventBus.broadcast(eventName, data);
};

export default {
  subscribe,
  broadcast,
};
