import { useDraggable } from '@dnd-kit/core';

import { FCC } from '@sharedTypes/fcc.type';

import { IDraggableProps } from './draggable.interface';
import { DraggableWrapper } from './draggable.theme';

const Draggable: FCC<IDraggableProps> = (props) => {
  const {
    children,
    id,
    data,
    disabled = false,
  } = props;
  const {
    attributes,
    listeners,
    setNodeRef,
  } = useDraggable({
    id,
    data,
    disabled,
  });

  return (
    <DraggableWrapper
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      disabled={disabled}
    >
      {children}
    </DraggableWrapper>
  );
};

export { Draggable };
