import { initializeApp } from 'firebase/app';
import { getMessaging, isSupported, Messaging } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const vapidKey = process.env.REACT_APP_FIREBASE_VAPID_KEY;

const firebaseApp = initializeApp(firebaseConfig);

export const messaging = (async (): Promise<Messaging | null> => {
  if (process.env.REACT_APP_E2E_TESTS) return null;
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      return getMessaging(firebaseApp);
    }
    return null;
  } catch (err) {
    return null;
  }
})();

interface IMessagingToTopicSubscribeOptions {
  deviceToken: string;
  topicName: string;
}

const Authorization = `key=${process.env.REACT_APP_FIREBASE_SERVICE_KEY}`;

const getSubscribedTopics = async (token: string): Promise<string[]> => {
  try {
    const response = await fetch(`https://iid.googleapis.com/iid/info/${token}?details=true`, {
      method: 'GET',
      headers: {
        Authorization,
      },
    });

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }

    const responseData = await response.json();
    return Object.keys(responseData.rel.topics);
  } catch (error) {
    return [];
  }
};

const unsubscribeFromTopic = async (token: string, topic: string): Promise<void> => {
  const requestBody = {
    to: `/topics/${topic}`,
    registration_tokens: [token],
  };

  try {
    await fetch('https://iid.googleapis.com/iid/v1:batchRemove', {
      method: 'POST',
      headers: {
        Authorization,
      },
      body: JSON.stringify(requestBody),
    });
  } catch {}
};

const subscribeToTopic = async (token: string, topic: string): Promise<void> => {
  try {
    const response = await fetch(`https://iid.googleapis.com/iid/v1/${token}/rel/topics/${topic}`, {
      method: 'POST',
      headers: {
        Authorization,
      },
    });

    if (response.ok) {
      // eslint-disable-next-line no-console
      console.log('message OK');
    } else {
      // eslint-disable-next-line no-console
      console.log('Failed to subscribe to topic.');
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log('An error occurred while subscribing to topic.', error);
  }
};

export const messageToTopisSubscribeHandler = async (messageToTopisSubscribeOptions: IMessagingToTopicSubscribeOptions): Promise<void> => {
  const { deviceToken, topicName } = messageToTopisSubscribeOptions;
  const subscribedTopics = await getSubscribedTopics(deviceToken);
  subscribedTopics.forEach(async (topic) => {
    await unsubscribeFromTopic(deviceToken, topic);
  });
  subscribeToTopic(deviceToken, topicName);
};
