import { FC } from 'react';

import {
  FlyoutPopupTabTitle,
  FlyoutPopupTabSubTitle,
} from '@features/haulhubUi/uiKit';

import { SearchField } from '../searchField';
import { EntityList } from '../entityList';
import { IEntityFilterTabContentProps } from './entityFilterTabContent.interface';

const EntityFilterTabContent: FC<IEntityFilterTabContentProps> = (props) => {
  const {
    tabTitle,
    tabSubTitle,
    searchValue,
    entityList,
    firstLoading,
    placeholder,
    debounceTime,
    options,
    intervalRef,
    newPageLoading,
    onInputChangeHandler,
    manageSelectedEntity,
    onListScrollHandler,
  } = props;

  return (
    <>
      <FlyoutPopupTabTitle>
        {tabTitle}
      </FlyoutPopupTabTitle>

      <FlyoutPopupTabSubTitle>
        {tabSubTitle}
      </FlyoutPopupTabSubTitle>

      {onInputChangeHandler
        ? <SearchField
            value={searchValue}
            debounceTime={debounceTime}
            placeholder={placeholder}
            onInputChangeHandler={onInputChangeHandler}
          />
        : null
      }

      <EntityList
        entityList={entityList}
        firstLoading={firstLoading}
        options={options}
        intervalRef={intervalRef}
        newPageLoading={newPageLoading}
        manageSelectedEntity={manageSelectedEntity}
        onListScrollHandler={onListScrollHandler}
      />
    </>
  );
};

export { EntityFilterTabContent };
