import { createAsyncThunk } from '@reduxjs/toolkit';
import { stringify } from 'qs';

import api from '@configs/urls/api';
// eslint-disable-next-line max-len
import { IProjectPlusFilterRequestField } from '@pages/projectPlusModule/pages/live/components/rightSideBarProjectPlus/hooks/useProjectPlusFilters/useProjectPlusFilters.interface';

import instance from '../instanceDefiner';
import { IProjectPlusCountersResponse } from './projectPlusCounters.interface';

export const getProjectPlusCounters = createAsyncThunk(
  'projectPlusCounters/getProjectPlusCounters',
  async (filters?: IProjectPlusFilterRequestField) => {
    try {
      const response = await instance.get<IProjectPlusCountersResponse>(api.getProjectPlusCounters, {
        params: {
          filters,
        },
        paramsSerializer: {
          serialize: (params) => stringify(params.filters, { arrayFormat: 'repeat', encode: true }),
        },
      });
      return response.data;
    } catch {
      return null;
    }
  },
);
