import { alpha, styled } from '@mui/material';
import { p, rem } from '@helpers/remUtils';

interface ITimeEntityButton {
  isActive: boolean;
}

export const TimeEntityButton = styled('button', {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<ITimeEntityButton>(({ theme, isActive }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: 0,
  padding: p(11, 0, 11, 0),
  width: '100%',
  outline: 'none',
  border: 'none',
  background: isActive ? theme.palette.background.default : 'transparent',
  fontWeight: isActive ? 'bold' : 'normal',
  color: theme.palette.text.main,
  cursor: 'pointer',
  fontSize: rem(14),
  '&:hover': {
    backgroundColor: alpha(theme.palette.background.default, 0.7),
  },
}));
