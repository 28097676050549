import axios from 'axios';

import { storageFactory } from './storage/storageFactory';
import {
  IApiLibrary,
  IAuthPortalData,
  IOktaClientOptions,
  IOktaData,
  IOktaRequestData,
  IOktaRequestDataWithCode,
  IOktaStorage,
} from './oktaClient.interface';
import { OktaStoreEnum } from './oktaClient.enum';
import { EApiLibrary } from './apiLibrary.enum';

export default class OktaClient {
  private domain: string;
  private storage: IOktaStorage;
  private storageKey: string;
  private apiLibrary: IApiLibrary;

  constructor(options: IOktaClientOptions) {
    this.domain = options.domain;
    this.storageKey = options.storageKey ? options.storageKey : '@@okta::data@@';
    this.storage = storageFactory(options.location || OktaStoreEnum.CACHE);
    this.apiLibrary = options.apiLibrary;
  }

  public async login(data: IOktaRequestData): Promise<void> {
    const response = await axios.post<IOktaData>(
      `${this.domain}${this.apiLibrary[EApiLibrary.authorize]}`,
      data,
    );
    this.storage.saveData(response.data, this.storageKey);
  }

  public async getToken(): Promise<string> {
    const token = this.storage.getData(this.storageKey)?.token;
    const refreshToken = this.storage.getData(this.storageKey)?.refreshToken;

    if (token) {
      return Promise.resolve(token);
    }

    if (!token && refreshToken) {
      try {
        const response = await axios.post<IOktaData>(
          `${this.domain}${this.apiLibrary[EApiLibrary.refreshToken]}`,
          { refreshToken },
        );
        this.storage.saveData(response.data, this.storageKey);
        return response.data.token;
      } catch {
        this.storage.clearData(this.storageKey);
        return Promise.reject();
      }
    }

    return Promise.reject();
  }

  public async getTokenByCode(code: string, redirectUri: string): Promise<string> {
    try {
      const response = await axios.post<IAuthPortalData>(
        `${this.apiLibrary[EApiLibrary.authenticate]}`,
        {
          authorization_code: code,
          redirect_uri: redirectUri,
          domain: process.env.REACT_APP_AUTH_DOMAIN,
        },
        {
          baseURL: process.env.REACT_APP_AUTH_API_URL,
        },
      );
      const tokenData: IOktaData = {
        status: response.data.status,
        token: response.data['x-hh-api-token'],
        refreshToken: response.headers['x-hh-refresh-token'],
        idToken: response.headers['x-hh-id-token'],
      };
      this.storage.saveData(tokenData, this.storageKey);
      return tokenData.token;
    } catch {
      this.storage.clearData(this.storageKey);
      return Promise.reject();
    }
  }

  public async refreshToken(): Promise<string> {
    const refreshToken = this.storage.getData(this.storageKey)?.refreshToken;

    if (refreshToken) {
      try {
        const response = await axios.post<IOktaData>(
          `${this.domain}${this.apiLibrary[EApiLibrary.refreshToken]}`,
          { refreshToken },
        );
        this.storage.saveData(response.data, this.storageKey);
        return response.data.token;
      } catch {
        this.storage.clearData(this.storageKey);
        return Promise.reject();
      }
    }

    return Promise.reject();
  }

  public async logout(): Promise<Error | null> {
    try {
      const token = await this.getToken();
      await axios.post(`${this.domain}${this.apiLibrary[EApiLibrary.logout]}`, {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      this.storage.clearData(this.storageKey);
      return null;
    } catch {
      return Promise.reject();
    }
  }

  public logoutByIdToken(): void {
    this.storage.clearData(this.storageKey);
  }

  public async sendResetPasswordEmail(email: string): Promise<string> {
    try {
      const response = await axios.post<{ message: string }>(
        `${this.domain}${this.apiLibrary[EApiLibrary.resetPasswordEmail]}`,
        { email },
      );
      return response.data.message;
    } catch (error: any) {
      const { status, data } = error.response;
      if (status === 401 && data.error) {
        return Promise.reject(data.error);
      }

      return Promise.reject();
    }
  }

  public async resetPasswordUsingCode(data: IOktaRequestDataWithCode): Promise<Error | null> {
    const response = await axios.patch<IOktaData>(
      `${this.domain}${this.apiLibrary[EApiLibrary.resetPasswordWithCode]}`,
      data,
    );
    this.storage.saveData(response.data, this.storageKey);
    return null;
  }

  public getTokenData(): string {
    const token = this.storage.getData(this.storageKey)?.token;
    return token || '';
  }

  public getIdTokenData(): string {
    const idToken = this.storage.getData(this.storageKey)?.idToken;
    return idToken || '';
  }
}
