const texts = {
  copiedInfo: 'copied_info',
  commonError: 'common_error',
  somethingWentWrong: 'Something went wrong', // TODO not use
  addFavoriteWidget: 'The widget has been added successfully to favorites', // TODO not use
  removeFavoriteWidget: 'The widget has been removed successfully from favorites', // TODO not use
  removeFavoriteWidgetDashboard: 'The widget has been removed successfully from favorites', // TODO not use
  noFound: 'No results found', // TODO not use
  pageNotFound: 'Page is not found', // TODO not use
  noEntityFound: 'no_entities_found',
  reportsNotFound: 'No recently viewed reports', // TODO not use
  widgetFiltersNotFound: 'No data available for your filters', // TODO not use
  datePickerStartDateError: 'Start date must come before End date', // TODO not use
  datePickerEndDateError: 'End date must come after Start date', // TODO not use
  fieldRequired: 'field_required',
  firstNameInvalidData: 'first_name_invalid_data',
  emailInvalidData: 'email_invalid_data',
  passwordInvalidData: 'password_invalid_data',
  confirmationPassword: 'confirmation_password',
  lastNameInvalidData: 'last_name_invalid_data',
  archiveUser: 'User has been successfully archived', // TODO not use
  restoreUser: 'User has been successfully restored', // TODO not use
  resetPassword: 'User will be emailed instructions for setting their password', // TODO not use
  saveCurrentUser: 'User has been successfully saved', // TODO not use
  existedEmail: 'This email address already exists', // TODO not use
  maxLength: 'max_length',
  minLength: 'min_length',
  phoneNumberFormat: 'phone_number_format',
  savedUserRoles: 'User\'s roles has been successfully updated', // TODO not use
  saveRolePermissions: 'Permissions have been successfully saved', // TODO not use
  createNewRole: 'Role has been successfully created', // TODO not use
  editRole: 'Role has been successfully edited', // TODO not use
  // eslint-disable-next-line max-len
  singleUserCreated: 'User has been created and will be emailed instructions for setting his/her account', // TODO not use
  // eslint-disable-next-line max-len
  multipleUsersCreated: 'Users have been created and will be emailed instructions for setting their accounts', // TODO not use
  updateRoleStatus: 'Status has been successfully changed', // TODO not use
  deleteSavedSearch: 'The Saved Search(es) has been removed successfully', // TODO not use
  addedSavedSearch: 'The Saved Search has been added successfully', // TODO not use
  clearSavedSearches: 'The recent Saved Search(es) has been cleared successfully', // TODO not use
  permissionRestriction: 'You don\'t have permissions', // TODO not use
  addedCustomerToWatchlist: 'added_customer_to_watchlist',
  removeCustomerToWatchlist: 'remove_customer_from_watchlist',
  addedPlantToWatchlist: 'added_plant_to_watchlist',
  removePlantToWatchlist: 'remove_plant_from_watchlist',
  addedProjectToWatchlist: 'added_project_to_watchlist',
  removeProjectToWatchlist: 'remove_project_from_watchlist',
  noDataAvailable: 'no_data_available',
  removeCustomWidget: 'remove_custom_widget',
  createWidget: 'create_widget',
  updateWidget: 'update_widget',
  moveWidget: 'move_widget',
  changeRoleSuccess: 'change_role_success',
  archiveUserSuccess: 'archive_user_success',
  restoreUserSuccess: 'restore_user_success',
  enableFeatureFlag: 'enable_feature_flag',
  disableFeatureFlag: 'disable_feature_flag',
  editUserSuccess: 'edit_user_success',
  loginSuccess: 'Login has been successfully', // TODO not use
  logoutSuccess: 'Logout has been successfully', // TODO not use
  resetPasswordSuccess: 'Password has been successfully changed', // TODO not use
  addToTrackedProjectPlus: 'add_to_tracked_project_plus',
  updateTargetTrackedProjectPlus: 'Project has been successfully updated', // TODO not use
  removeProjectPlusFromTrackedList: 'remove_project_plus_from_tracked_list',
  updateTargetTimeProjectPlus: 'update_target_time_project_plus',
  updateTrucksNumberProjectPlus: 'update_trucks_number_project_plus',
  hiddenProjectPlus: 'hidden_project_plus',
  unhiddenProjectPlus: 'unhidden_project_plus',
  excludeEntity: 'exclude_entity',
  includeEntity: 'include_entity',
  emailInvalidSymbol: 'email_invalid_symbol',
  userNotExist: 'user_not_exist',
  selectValueFromList: 'select_value_from_list',
  createBid: 'create_bid',
  editBid: 'edit_bid',
  deleteBid: 'delete_bid',
  notValidUrl: 'not_valid_url',
  blockUser: 'block_user_message',
  blockMaterialProducer: 'block_material_producer_message',
  epdTicketTypeExists: 'epd_ticket_type_exists',
  epdCreatingSuccess: 'epd_creating_success',
  epdEditingSuccess: 'epd_creating_success',
  gwpTotalValidation: 'gwp_total_validation',
  makeRequestOnNeRoleSuccess: 'make_request_on_new_role_success',
};

export default texts;
