import { FC, memo } from 'react';

import { ListItem } from '@mui/material';

import { ITimeEntityListItemProps } from './timeEntityListItem.interface';
import { TimeEntityButton } from './timeEntityListItem.theme';
import { timeEntityButtonTestId } from './timeEntityListItem.const';

const TimeEntityListItem: FC<ITimeEntityListItemProps> = (props) => {
  const {
    item,
    isActive,
    listItemRef,
    onItemClick,
  } = props;

  const onItemClickHandler = (): void => {
    onItemClick(item);
  };

  return (
    <ListItem ref={listItemRef}>
      <TimeEntityButton
        type="button"
        isActive={isActive}
        disabled={isActive}
        data-testid={timeEntityButtonTestId}
        onClick={onItemClickHandler}
      >
        {item}
      </TimeEntityButton>
    </ListItem>
  );
};

export default memo(TimeEntityListItem);
