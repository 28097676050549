import updateEntityWatchlistStatus from './events/updateEntityWatchlistStatus/updateEntityWatchlistStatus.event';
import updateEntityDetailsList from './events/updateEntityDetailsList/updateEntityDetailsList.event';
import widgetTileNameChange from './events/widgetTileNameChange/widgetTileNameChange.event';
import removeFromWatchlist from './events/removeFromWatchlist/removeFromWatchlist.event';
import updateProjectPlus from './events/updateProjectPlus/updateProjectPlus.event';
import shareChartValues from './events/shareChartValues/shareChartValues.event';
import roundsManipulation from './events/roundsManipulation/roundsManipulation.event';
import ticketTypeNotification from './events/ticketTypeNotification/ticketTypeNotification.event';
import materialProducerNotification from './events/materialProducerNotification/materialProducerNotification.event';
import userInfoNotification from './events/userInfoNotification/userInfoNotification.event';
import generalSettingsNotification from './events/generalSettingsNotification/generalSettingsNotification.event';
import { subscribe, broadcast } from './eventBus';

export default {
  updateEntityWatchlistStatus,
  updateEntityDetailsList,
  widgetTileNameChange,
  removeFromWatchlist,
  updateProjectPlus,
  shareChartValues,
  roundsManipulation,
  ticketTypeNotification,
  materialProducerNotification,
  userInfoNotification,
  generalSettingsNotification,
  subscribe,
  broadcast,
};
