import { alpha } from '@mui/material/styles';

import { rem, m } from '@helpers/remUtils';
import { makeStyles } from '@theme/appTheme';

const useStylesRecentlyViewedUnit = makeStyles()((theme) => ({
  listItem: {
    margin: m(0, 0, 13, 0),
    padding: 0,
  },
  listButton: {
    display: 'block',
    padding: 0,
    color: '#E0E0E0',
    border: 'none',
    fontSize: rem(16),
    cursor: 'pointer',
    backgroundColor: 'transparent',
    transition: 'color 0.2s ease-out',
    '&:hover': {
      color: alpha('#E0E0E0', 0.6),
    },
    '&.active': {
      color: theme.palette.primary.main,
    },
  },
}));

export default useStylesRecentlyViewedUnit;
