import { FC } from 'react';
import { Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useGlobalFlexStyles } from '@assets/globalStyles';

import {
  accessButtonTestId,
  cancelButtonTestId,
  confirmationPopupContainer,
  confirmPopupText,
} from './confirmationPopup.const';
import { IConfirmPopupProps } from './confirmationPopup.interface';
import useStylesConfirmPopup from './confirmationPopup.theme';

const ConfirmationPopup: FC<IConfirmPopupProps> = props => {
  const {
    externalClasses = {},
    popupContent,
    handleConfirmationAccess,
    handleConfirmationCancel,
  } = props;
  const { classes, cx } = useStylesConfirmPopup();
  const { classes: flexClasses } = useGlobalFlexStyles();
  const { t } = useTranslation();
  const title = popupContent?.title || t(confirmPopupText.DEFAULT_TITLE_TEXT);
  const bodyText = popupContent?.bodyText || null;
  const accessButtonTitle = popupContent?.accessButtonTitle || t(confirmPopupText.ACCESS_BUTTON);
  const cancelButtonTitle = popupContent?.cancelButtonTitle || t(confirmPopupText.CANCEL_BUTTON);

  return (
    <section
      className={cx(
        classes.popupWrapper,
        externalClasses['root'] || null,
      )}
      data-testid={confirmationPopupContainer}
    >
      <h3
        className={cx(
          classes.popupTitle,
          externalClasses['title'] || null,
        )}
      >
        {title}
      </h3>

      <main className={cx(
        classes.popupDescription,
        externalClasses['content'] || null,
       )}
      >
        {bodyText}
      </main>

      <footer className={flexClasses.flexBetween}>
        <Button
          onClick={handleConfirmationCancel}
          data-testid={cancelButtonTestId}
          color='error'
        >
          {cancelButtonTitle}
        </Button>

        <Button
          onClick={handleConfirmationAccess}
          data-testid={accessButtonTestId}
        >
          {accessButtonTitle}
        </Button>
      </footer>
    </section>
  );
};

export default ConfirmationPopup;
