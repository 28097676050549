import { combineReducers, configureStore } from '@reduxjs/toolkit';

import manageProjectPlusReducer from './projectPlus/manageProjectPlusSlice/manageProjectPlusSlice';
import selectedProjectPlusItemReducer from './projectPlus/selectedProjectPlusItem/selectedProjectPlusItemSlice';
import projectPlusCommonReducer from './projectPlus/settingsProjectPlusSlice/projectPlusCommonSlice';
import projectPlusCountersSliceReducer from './projectPlus/projectPlusCounters/projectPlusCounters';
import projectPlusDetailsReducer from './projectPlus/projectPlusDetails/projectPlusDetailsSlice';
import projectPlusHistoricalReducer from './projectPlus/projectPlusHistorical/projectPlusHistoricalSlice';
import sharedWidgetsReducer from './sharedWidgets/sharedWidgetsSlice';
import drillingEntitiesReducer from './projectPlus/drillingEntitiesSlice/drillingEntitiesSlice';
import detailsWatchlistControlsReducer from './projectPlus/detailsWatchlistControlsSlice/detailsWatchlistControlsSlice';
import recentlyViewedReducer from './recentlyViewed/recentlyViewedSlice';
import widgetsReducer from './dashboard/widgets/widgetsSlice';
import historicalProjectsCalenderSliceReducer
  from './projectPlus/historicalProjectsCalenderSlice/historicalProjectsCalenderSlice';
import materialClassificationsSliceReducer from './materialClassifications/materialClassificationsSlice';
import rankingSliceReducer from './projectPlus/rankingSlice/rankingSlice';
import navigationReducer from './navigation/navigationSlice';
import configPopupNavigationSlice from './projectPlus/configPopupNavigation/configPopupNavigationSlice';
import { rankingListenerMiddleware } from './middlewares/rankingListenerMiddelware/rankingListenerMiddleware';
import optionsPopupReducer from './projectPlus/optionsPopup/optionsPopupSlice';
import newRankingSliceReducer from './projectPlus/newRankingSlice/newRankingSlice';
import sharedLinksDetailsSliceReducer from './sharedLinksDetails/sharedLinksDetailsSlice';

export const rootReducer = combineReducers({
  manageProjectPlusReducer,
  projectPlusDetailsReducer,
  projectPlusCommonReducer,
  selectedProjectPlusItemReducer,
  projectPlusCountersSliceReducer,
  projectPlusHistoricalReducer,
  sharedWidgetsReducer,
  drillingEntitiesReducer,
  detailsWatchlistControlsReducer,
  recentlyViewedReducer,
  widgetsReducer,
  historicalProjectsCalenderSliceReducer,
  materialClassificationsSliceReducer,
  rankingSliceReducer,
  newRankingSliceReducer,
  navigationReducer,
  configPopupNavigationSlice,
  optionsPopupReducer,
  sharedLinksDetailsSliceReducer,
});

const configDefaultMiddleware = { serializableCheck: { ignoredActions: ['listenerMiddleware/add'] } };

export const setupStore = (): any => {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(configDefaultMiddleware)
      .concat(rankingListenerMiddleware.middleware),
    devTools: process.env.NODE_ENV !== 'production',
  });
};

export const store = setupStore();
