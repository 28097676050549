import { styled } from '@mui/material';

import { rem, m } from '@helpers/remUtils';

export const FlyoutPopupTabSubTitle = styled('p')(({ theme }) => ({
  margin: m(0, 0, 20),
  fontSize: rem(13),
  fontWeight: 400,
  color: theme.palette.text.secondary,
}));
