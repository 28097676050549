import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import useStylesNoInfo from '@components/noInfo/noInfo.theme';
import { noInfoConst } from '@components/noInfo/noInfo.const';

import { INoInfoProps } from './noInfo.interface';

const NoInfo: FC<INoInfoProps> = (props) => {
  const { className = null } = props;
  const { classes, cx } = useStylesNoInfo();
  const { t } = useTranslation('informationMessages');

  return (
    <span className={cx(classes.noInfo, className)}>
      {t(noInfoConst.NO_INFO)}
    </span>
  );
};

export default NoInfo;
