import { alpha } from '@mui/material/styles';

import { rem, m, p } from '@helpers/remUtils';
import { makeStyles } from '@theme/appTheme';

export const useStylesExperienceButton = makeStyles()((theme) => ({
  wrapper: {
    width: rem(320),
    padding: p(18, 23, 18, 23),
    marginBottom: rem(20),
    border: `${rem(2)} solid ${alpha(theme.palette.primary.main, 0.2)}`,
    backgroundColor: alpha(theme.palette.primary.main, 0.04),
    borderRadius: rem(7),
    textTransform: 'capitalize',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: alpha(theme.palette.primary.main, 0.1),
    },
  },
  active: {
    backgroundColor: alpha(theme.palette.primary.main, 0.2),
    border: `${rem(2)} solid ${theme.palette.primary.main}`,
    cursor: 'default',
  },
  title: {
    margin: m(0, 0, 5, 0),
    padding: 0,
    fontSize: rem(16),
    color: theme.palette.text.primary,
  },
  description: {
    margin: m(0, 0, 10, 0),
    textAlign: 'start',
    lineHeight: rem(20),
    fontSize: rem(16),
    fontWeight: 400,
    color: theme.palette.text.secondary,
  },
}));
