import { makeStyles } from '@theme/appTheme';

export const useStylesUnauthorizedHeader = makeStyles()(() => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '60px',
    padding: '0 41px 0 65px',
  },
}));
