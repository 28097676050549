import { FC, memo } from 'react';
import { Button } from '@mui/material';
import { deepCompare } from '@helpers/deepCompare';

import { IButtonsListProps } from './buttonsList.interface';
import { ButtonsListWrapper } from './buttonsList.theme';

const ButtonsList: FC<IButtonsListProps> = (props) => {
  const {
    buttonsConfig,
    onButtonClick,
  } = props;

  return (
    <ButtonsListWrapper>
      {buttonsConfig.map((buttonConfig) => {
        const {
          label,
          value,
          isActive,
          isDisabled,
          size = 'medium',
        } = buttonConfig;

        const onButtonClickHandler = (): void => {
          onButtonClick(value);
        };

        return (
          <li
            key={value}
            className="buttonsListItem"
          >
            <Button
              variant="rounded"
              color={isActive ? 'primary' : 'secondary'}
              size={size}
              disabled={isDisabled}
              onClick={onButtonClickHandler}
            >
              {label}
            </Button>
          </li>
        );
      })}
    </ButtonsListWrapper>
  );
};

export default memo(ButtonsList, (oldProps, newProps) => deepCompare(oldProps, newProps));
