import { IMaterialTypes } from '@interfaces/materialTypes.interface';

export const resetToDefaultMaterialsList = (materials: IMaterialTypes[]): IMaterialTypes[] => {
  return materials.map(material => {
    return ({
      ...material,
      active: material.activeByDefault,
    });
  });
};
