import React, { ComponentType, FC, ReactElement } from 'react';
import { isPortalType } from '@okta/intialOktaApp/helpers/isPortalType.helper';
import SuspenseLoading from '@components/suspenseLoading';

import useOkta from '../../../useOkta';

// Small Update
const withAuthenticationRequired = <P extends Record<string, unknown>>(
  Component: ComponentType<P>,
  RedirectComponent: ComponentType<any>,
  onLoadingComponent: () => ReactElement,
): FC<P> => {
  return function WithAuthenticationRequired(props: P): ReactElement {
    const { isAuthenticated, isLoading, logoutRedirectUrl } = useOkta();
    const correctRenderPortal = !isPortalType()
      ? <RedirectComponent
          {...props}
          to={logoutRedirectUrl}
          replace={true}
        />
      : <SuspenseLoading showLogo={true} />;

    if (isLoading) {
      return onLoadingComponent();
    }

    return !isLoading && isAuthenticated
      ? <Component {...props} />
      : correctRenderPortal;
  };
};

export default withAuthenticationRequired;
