import { IconButton, styled } from '@mui/material';
import { rem } from '@helpers/remUtils';

import { ITreeViewButton } from './treeViewButton.interface';

const TreeViewButton = styled(IconButton)<ITreeViewButton>(({ isTreeViewOpen }) => ({
  position: 'absolute',
  top: rem(144),
  left: rem(-24),
  visibility: isTreeViewOpen ? 'hidden' : 'visible',
  zIndex: 1,
}));

export { TreeViewButton };
