import { Active, Over } from '@dnd-kit/core/dist/store';
import { arrayMove } from '@dnd-kit/sortable';

import {
  IUseColumnManagementLists,
} from '@pages/admin/pages/calibration/hooks/useColumnManagementList/useColumnManagementListStates.interface';

import { findContainer } from './findContainer';

export const handleDragEndHandler = (
  active: Active,
  over: Over,
  items: IUseColumnManagementLists,
): IUseColumnManagementLists | null => {
  const { id } = active;
  const { id: overId } = over;
  const activeContainer = findContainer(items, id);
  const overContainer = findContainer(items, overId);

  if (!activeContainer || !overContainer || activeContainer !== overContainer) return null;

  const activeContainerKey = activeContainer as keyof IUseColumnManagementLists;
  const overContainerKey = overContainer as keyof IUseColumnManagementLists;
  const activeItems = items[activeContainerKey];
  const overItems = items[overContainerKey];
  const activeIndex = activeItems.findIndex(item => item.id == id);
  const overIndex = overItems.findIndex(item => item.id == overId);

  if (activeIndex === overIndex) return null;

  return ({
    ...items,
    [overContainerKey]: arrayMove(items[overContainerKey], activeIndex, overIndex),
  });
};
