const rem = (size: number): string => {
  // use rem without material
  const baseSize = 16;
  return (1 / baseSize * size) + 'rem';

  // use rem with material
  // return appTheme.typography.pxToRem(size);
};

const m = (
  top: number,
  right?: number,
  bottom?: number,
  left?: number,
): string => {
  const rightDimension = right ?? top;
  const bottomDimension = bottom ?? top;
  const leftDimension = left ?? rightDimension;

  return `${rem(top)} ${rem(rightDimension)} ${rem(bottomDimension)} ${rem(leftDimension)}`;
};

const p = m;

const br = m;

export {
  rem,
  m,
  p,
  br,
};
