import { FC, memo, useCallback } from 'react';
import { Typography } from '@mui/material';

import { DayPeriodEnum } from '@enums/dayPeriod.enum';
import { hoursConfig, minutesConfig } from '@components/nativeTimePiker/nativeTimePiker.config';

import TimePickerButton from '../timePickerButton/TimePickerButton';
import TimePickerDayPeriodButton from '../timePickerDayPeriodButton/TimePickerDayPeriodButton';
import { ITimePickerProps } from './timePicker.interface';
import { TimePickerWrapper } from './timePicker.theme';

const TimePicker: FC<ITimePickerProps> = (props) => {
  const {
    time,
    label,
    PopperComponent,
    updateTime,
  } = props;
  const {
    hours,
    minutes,
    dayPeriod,
  } = time;

  const updateDayPeriod = useCallback((newDayPeriod: DayPeriodEnum): void => {
    updateTime({ dayPeriod: newDayPeriod });
  }, []);

  const updateHours = useCallback((newHours: string): void => {
    updateTime({ hours: newHours });
  }, []);

  const updateMinutes = useCallback((newMinutes: string): void => {
    updateTime({ minutes: newMinutes });
  }, []);

  return (
    <TimePickerWrapper>
      <Typography className="label">
        {label}
      </Typography>

      <div className="displays-wrapper">
        <TimePickerButton
          currentValue={hours}
          dropdownConfig={hoursConfig}
          PopperComponent={PopperComponent}
          updateTimeString={updateHours}
        />

        <div className="separator">
          <span className="separator-item" />

          <span className="separator-item" />
        </div>

        <TimePickerButton
          currentValue={minutes}
          dropdownConfig={minutesConfig}
          PopperComponent={PopperComponent}
          updateTimeString={updateMinutes}
        />

        <div className="day-period-wrapper">
          <TimePickerDayPeriodButton
            dayPeriod={DayPeriodEnum.AM}
            isActive={dayPeriod === DayPeriodEnum.AM}
            updateDayPeriod={updateDayPeriod}
          />

          <TimePickerDayPeriodButton
            dayPeriod={DayPeriodEnum.PM}
            isActive={dayPeriod === DayPeriodEnum.PM}
            updateDayPeriod={updateDayPeriod}
          />
        </div>
      </div>
    </TimePickerWrapper>
  );
};

export default memo(TimePicker);
