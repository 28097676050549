import { ProjectedTargetTimeEnum } from '@pages/projectPlusModule/enums/projectedTargetTime.enum';

export const initialLocalOptions = {
  tonnageRange: 0,
  trucks: 2,
  fleetIds: [],
};

export const initialGeneralOptions = {
  endTime: '05:00:00',
  startTime: '05:00:00',
  exclusionThresholdEnabled: true,
  exclusionThresholdInMinutes: 20,
  standardDeviationExclusionThreshold: 2,
  standardDeviationExclusionThresholdEnabled: true,
  projectedTargetTime: ProjectedTargetTimeEnum.PROJECTED_DROP_OFF_TIME,
  materialTypes: [],
  customerIds: [],
  plantIds: [],
};

export const initialOptionsOptions = {
  ...initialGeneralOptions,
  ...initialLocalOptions,
};
