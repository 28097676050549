import React, { FC } from 'react';
import { Button, LinearProgress } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { CenteredModal } from '@features/haulhubUi/uiKit/centeredModal/centeredModal.theme';
import { useManageModals } from '@features/haulhubUi/providers/manageModalsProvider/useManageModals';
import DefaultModalHeader from '@features/haulhubUi/components/defaultModalHeader/DefaultModalHeader';
import { manageModalsActions } from '@features/haulhubUi';

import { DownloadTicketsReportProgressModalContent } from './downloadTicketsReportProgressModal.theme';
import { IDownloadTicketsReportProgressModalProps } from './downloadTicketsReportProgressModal.interface';
import { downloadTicketsReportProgressModalTexts } from './downloadTicketsReportProgressModal.const';

const DownloadTicketsReportProgressModal: FC<IDownloadTicketsReportProgressModalProps> = (props) => {
  const { disconnectWebSocket } = props;
  const { t } = useTranslation(['tickets']);
  const { isOpen } = useManageModals();

  const onCancelButtonClick = (): void => {
    manageModalsActions.closeModal('downloadTicketsReportProgressModal');
    disconnectWebSocket();
  };

  return (
    <CenteredModal
      open={isOpen('downloadTicketsReportProgressModal')}
    >
      <DownloadTicketsReportProgressModalContent>
        <DefaultModalHeader title={t(downloadTicketsReportProgressModalTexts.HEADER_TITLE)} />

        <div className='report-progress-modal-body'>
          <p className='report-progress-modal-text'>
            {t(downloadTicketsReportProgressModalTexts.FIRST_DESCRIPTION)}
          </p>

          <p className='report-progress-modal-text'>
            {t(downloadTicketsReportProgressModalTexts.SECOND_DESCRIPTION)}

            <Button
              className='report-progress-modal-cancel-button'
              variant='text'
              onClick={onCancelButtonClick}
            >
              {t(downloadTicketsReportProgressModalTexts.CANCEL_BUTTON_TITLE)}
            </Button>
          </p>

          <LinearProgress className='report-progress-modal-loader' />
        </div>
      </DownloadTicketsReportProgressModalContent>
    </CenteredModal>
  );
};

export default DownloadTicketsReportProgressModal;
