import { useContext } from 'react';

import { IEntityManagementContext } from './entityManagementContext.interface';
import { EntityManagementContext } from './entityManagementProvider';

export const useEntityManagement = (): IEntityManagementContext => {
  const context = useContext(EntityManagementContext);

  if (context === undefined) {
    throw new Error('useEntityManagement must be used within a EntityManagementProvider');
  }

  return context;
};
