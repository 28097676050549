import { RolesEnum } from '@enums/tenantRoles.enum';

export const denyEmailDomains = [
  '@haulhub.com',
  '@spd-ukraine.com',
  '@kanerika.com',
];

export const availableRoles = [
  RolesEnum.ADMIN,
  RolesEnum.DRIVER,
  RolesEnum.IT_ADMIN_ONLY,
  RolesEnum.SUPER_ADMIN,
  RolesEnum.USER,
];
