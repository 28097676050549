import { styled } from '@mui/material';
import { p } from '@helpers/remUtils';

const TreeViewModalHeader = styled('header')(() => ({
  padding: p(10, 20),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  backgroundColor: '#506986',
}));

export { TreeViewModalHeader };
