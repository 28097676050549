import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getYear, isToday } from 'date-fns';

import {
  getFullDate,
  getInitFromDate,
  getInitToDate,
} from '@pages/projectPlusModule/pages/live/components/historical/helpers/historicalDateUtils';
import {
  getProjectsPerDayStatistics,
} from '@store/thunk/historicalProjectsCalendar/getHistoricalProjectsCalendarStatistics';
import { IMonthsRange } from '@interfaces/monthsRange.interface';
import { convertDateLocalTimeString } from '@helpers/timeUtils';

import { IHistoricalProjectsCalenderSliceSlice } from './historicalProjectsCalenderSlice.interface';
import { IProjectsPerDay } from './projectsPerDay.interface';

export const initDateRange = {
  toDate: getInitToDate(),
  fromDate: getInitFromDate(),
};
const initHistoricalDate = getFullDate(new Date());
const initCurrentYear = getYear(new Date());

const initialState: IHistoricalProjectsCalenderSliceSlice = {
  historicalProjectDate: initHistoricalDate,
  projectsPerDay: [],
  isPresentDay: true,
  currentYear: initCurrentYear,
  dateRange: initDateRange,
  error: false,
  loading: true,
  historicalProjectsNumber: null,
};

export const historicalProjectsCalenderSlice = createSlice({
  name: 'historicalProjectsCalender',
  initialState,
  reducers: {
    setHistoricalProjectDate(
      state: IHistoricalProjectsCalenderSliceSlice,
      action: PayloadAction<string>,
    ): void {
      const { payload: currentDate } = action;
      state.historicalProjectDate = currentDate;
      state.isPresentDay = isToday(new Date(convertDateLocalTimeString(currentDate)));
    },
    clearHistoricalProjectDate( state: IHistoricalProjectsCalenderSliceSlice): void {
      state.historicalProjectDate = initHistoricalDate;
      state.currentYear = initCurrentYear;
      state.dateRange = initDateRange;
      state.isPresentDay = true;
    },
    setDateRange (
      state: IHistoricalProjectsCalenderSliceSlice,
      action: PayloadAction<IMonthsRange>,
    ): void {
      const { payload: dateRange } = action;
      state.dateRange = dateRange;
    },
    changeIsPresentDay (
      state: IHistoricalProjectsCalenderSliceSlice,
      action: PayloadAction<boolean>,
    ): void {
      const { payload: isPresentDay } = action;
      state.isPresentDay = isPresentDay;
    },
    setCurrentYear (
      state: IHistoricalProjectsCalenderSliceSlice,
      action: PayloadAction<number>,
    ): void {
      const { payload: currentYear } = action;
      state.currentYear = currentYear;
    },
    setHistoricalProjectsNumber (
      state: IHistoricalProjectsCalenderSliceSlice,
      action: PayloadAction<number>,
    ): void {
      const { payload } = action;
      state.historicalProjectsNumber = payload;
    },
    clearHistoricalProjectsNumber (state: IHistoricalProjectsCalenderSliceSlice): void {
      state.historicalProjectsNumber = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getProjectsPerDayStatistics.pending, (state) => {
        state.error = false;
        state.loading = true;
      })
      .addCase(getProjectsPerDayStatistics.fulfilled, (
        state: IHistoricalProjectsCalenderSliceSlice,
        action: PayloadAction<IProjectsPerDay[]>,
      ) => {
        const { payload } = action;
        state.projectsPerDay = payload;
        state.loading = false;
        state.error = false;
      })
      .addCase(getProjectsPerDayStatistics.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const {
  setDateRange,
  setCurrentYear,
  changeIsPresentDay,
  setHistoricalProjectDate,
  clearHistoricalProjectDate,
  setHistoricalProjectsNumber,
  clearHistoricalProjectsNumber,
} = historicalProjectsCalenderSlice.actions;
export default historicalProjectsCalenderSlice.reducer;
