import { createAsyncThunk } from '@reduxjs/toolkit';
import { stringify } from 'qs';

import { RootStateType } from '@store/interfaces/rootState.type';
import api from '@configs/urls/api';
import { IRankingPage } from '@interfaces/ranking/rankingPage.interface';
import { IRankingRequestReturnData } from '@interfaces/ranking/rankingRequestReturnData.interface';

import instance from '../instanceDefiner';
import { IGetProjectPlusRankingRequestParams } from './getProjectPlusRankingRequestParams.interface';
import { generateRequestParams } from './helpers/generateRequestParams.helper';

export const getProjectPlusRanking = createAsyncThunk<IRankingRequestReturnData,
  IGetProjectPlusRankingRequestParams | undefined,
  { state: RootStateType }>(
  'rankingSlice/getProjectPlusRanking',
  async (inputParams, thunkAPI) => {
    try {
      const { getState } = thunkAPI;
      const requestParams = generateRequestParams(getState(), inputParams);
      const response = await instance.get<IRankingPage>(api.getRanking, {
        params: requestParams,
        paramsSerializer: {
          serialize: params => stringify(params, { arrayFormat: 'repeat', encode: true }),
        },
      });
      const { content } = response.data;
      return ({
        data: content,
      });
    } catch {
      return Promise.reject();
    }
  },
);
