import { createContext, FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { ERefreshPageWarning } from '@enums/refreshPageWarning';
import {
  removeDuplicatesFromPrimitiveArray,
  removePrimitiveItem,
} from '@helpers/arrayUtils';
import RefreshPageNotificationList from '@components/refreshPageNotificationList';

import { IRefreshPageWarningContext } from './refreshPageWarningContext.interface';
import { IRefreshPageWarningProviderActions } from './refreshPageWarningProviderActions.interface';

const RefreshPageWarningContext = createContext<IRefreshPageWarningContext | undefined>(undefined);

export let refreshPageWarningProviderActions: IRefreshPageWarningProviderActions;

const RefreshPageWarningProvider: FC<PropsWithChildren> = ({ children }) => {
  const [refreshWarnings, setRefreshWarnings] = useState<ERefreshPageWarning[]>([]);

  const addWarningMessage = useCallback((waringType: ERefreshPageWarning) => {
    setRefreshWarnings(prevState => removeDuplicatesFromPrimitiveArray([...prevState, waringType]));
  }, []);

  const removeWarningMessage = useCallback((waringType: ERefreshPageWarning) => {
    setRefreshWarnings(prevState => removePrimitiveItem(prevState, waringType));
  }, []);

  refreshPageWarningProviderActions = {
    addWarningMessage,
    removeWarningMessage,
  };

  const value = useMemo(() => {
    return {
      refreshWarnings,
    };
  }, [refreshWarnings]);

  return (
    <RefreshPageWarningContext.Provider value={value}>
      <>
        {children}

        {refreshWarnings.length
          ? <RefreshPageNotificationList refreshWarnings={refreshWarnings} />
          : null
        }
      </>
    </RefreshPageWarningContext.Provider>
  );
};

export { RefreshPageWarningProvider, RefreshPageWarningContext };
