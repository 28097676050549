import { useReducer } from 'react';

import { IApiHookResponseError } from '../interfaces/apiHookResponseError.interface';

import { IUseRequestReturn } from './useRequestReturn.interface';
import { useRequestReducerInit, useRequestReducerLoadingInit } from './useRequest.const';
import { requestReducer } from './requestReducer';

export const useRequest = (autoRequest?: boolean): IUseRequestReturn => {
  const initValue = autoRequest ? useRequestReducerLoadingInit : useRequestReducerInit;
  const [requestStatusState, dispatch] = useReducer(requestReducer, initValue);

  const makeRequest = (): void => dispatch({ type: 'REQUEST' });

  const setSuccess = (): void => dispatch({ type: 'SUCCESS' });

  const setError = (error: IApiHookResponseError): void => dispatch({ type: 'ERROR', error });

  const reset = (): void => dispatch({ type: 'RESET' });

  return {
    requestStatusState,
    makeRequest,
    setSuccess,
    setError,
    reset,
  };
};
