import { FC } from 'react';
import { useDroppable } from '@dnd-kit/core';

import { IDroppableProps } from './droppable.interface';

const Droppable: FC<IDroppableProps> = (props) => {
  const {
    children,
    id,
  } = props;
  const {
    active,
    setNodeRef,
  } = useDroppable({
    id,
  });

  return (
    <div
      className="droppable-wrapper"
      ref={setNodeRef}
    >
      {children(!!active)}
    </div>
  );
};
export { Droppable };
