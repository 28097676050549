import { makeStyles } from '@theme/appTheme';

const useStylesMobileView = makeStyles()((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.default,
  },
  contentWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
  text: {
    lineHeight: '1.4',
    color: theme.palette.common.white,
    fontSize: '19px',
    padding: '0 15px',
  },
  badge: {
    width: '135px',
    '&.apple': {
      height: '42px',
    },
    '&.android': {
      height: '40px',
    },
  },
  logo: {
    width: '36px',
    height: '32px',
    margin: '-8px 7px 0 0',
  },
}));

export default useStylesMobileView;
