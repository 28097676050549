import { IApiHookResponseError } from '../interfaces/apiHookResponseError.interface';

import { IRequestReducerState } from './requestReducer.interface';

export type IRequestReducerAction =
  | { type: 'REQUEST' }
  | { type: 'SUCCESS' }
  | { type: 'ERROR', error: IApiHookResponseError }
  | { type: 'RESET' };

export const requestReducer = (state: IRequestReducerState, action: IRequestReducerAction): IRequestReducerState => {
  switch (action.type) {
    case 'REQUEST':
      return { ...state, isLoading: true, isPending: true, isError: false, isSuccess: false, status: 'pending' };
    case 'SUCCESS':
      return { ...state, isLoading: false, isPending: false, isError: false, isSuccess: true, status: 'success' };
    case 'ERROR':
      return { ...state, isLoading: false, isPending: false, isError: true, isSuccess: false, status: 'error', error: action.error };
    case 'RESET':
      return { isLoading: false, isPending: false, isError: false, isSuccess: false, status: 'pending', error: null };
    default:
      return state;
  }
};
