import { createAsyncThunk } from '@reduxjs/toolkit';

import { IGeneralOptions } from '@store/projectPlus/optionsPopup/interfaces/generalOptions.interface';
import api from '@configs/urls/api';

import instance from '../instanceDefiner';

export const updateGeneralOptionsProjectPlus = createAsyncThunk(
  'projectPlusOptions/updateGeneralOptionsProjectPlus',
  async (updatedOptions: Partial<IGeneralOptions>) => {
    try {
      await instance.patch(
        api.updateGeneralSettingsProjectPlus,
        { ...updatedOptions },
      );
      return updatedOptions;
    } catch {
      return Promise.reject();
    }
  },
);
