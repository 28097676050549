import { styled } from '@mui/material';
import { m, rem, p } from '@helpers/remUtils';

export const DropdownContent = styled('div')(({ theme }) => ({
  width: rem(220),
  marginTop: rem(2),
  borderRadius: rem(9),
  backgroundColor: theme.palette.background.default,
  boxShadow: '0 7px 11px 0 rgba(0,0,0,0.2)',
  '& .group-dropdown-list': {
    padding: rem(12),
    maxHeight: rem(250),
    overflowY: 'auto',
  },
  '& .group-dropdown-footer': {
    padding: p(5, 2),
    borderTop: `${rem(1)} solid ${theme.palette.additionalColors.divider.secondary}`,
  },
}));

export const ButtonsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  borderRadius: rem(62),
  backgroundColor: theme.palette.additionalColors.background.eighth,
  '.reset-button': {
    margin: m(0, 16, 0, -10),
    padding: 0,
    fontSize: rem(16),
    color: theme.palette.text.primary,
  },
}));
