import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SharedLinksTypeEnum } from '@enums/sharedLinksType.enum';

import { ISharedLinksDetailsData, ISharedLinksDetailsSliceState } from './sharedLinksDetailsSlice.interface';

const initialState: ISharedLinksDetailsSliceState = {
  sharedLinksDetailsType: null,
  sharedLinksDetailsData: null,
};

const sharedLinksDetailsSlice = createSlice({
  name: 'sharedLinksDetails',
  initialState,
  reducers: {
    updateSharedLinksDetailsType(
      state: ISharedLinksDetailsSliceState,
      action: PayloadAction<SharedLinksTypeEnum>,
    ): void {
      const { payload } = action;
      state.sharedLinksDetailsType = payload;
    },
    clearSharedLinksDetailsType(
      state: ISharedLinksDetailsSliceState,
    ): void {
      state.sharedLinksDetailsType = null;
    },
    updateSharedLinksDetailsData(
      state: ISharedLinksDetailsSliceState,
      action: PayloadAction<ISharedLinksDetailsData>,
    ): void {
      const { payload } = action;
      state.sharedLinksDetailsData = payload;
    },
    clearSharedLinksDetailsData (
      state: ISharedLinksDetailsSliceState,
    ): void {
      state.sharedLinksDetailsData = null;
    },
  },
});

export const {
  updateSharedLinksDetailsType,
  clearSharedLinksDetailsType,
  updateSharedLinksDetailsData,
  clearSharedLinksDetailsData,
} = sharedLinksDetailsSlice.actions;

export default sharedLinksDetailsSlice.reducer;
