import { alpha, styled } from '@mui/material';

import { rem } from '@helpers/remUtils';

export const DndContextWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  display: 'flex',
  height: rem(555),
  '&:after': {
    content: '\'\'',
    position: 'absolute',
    width: rem(1),
    top: 0,
    bottom: 0,
    left: '50%',
    backgroundColor: alpha(theme.palette.common.white, 0.1),
    transform: 'translate(-50%)',
  },
}));
