import { FC, memo } from 'react';

import { IBarDividersProps } from './barDividers.interface';
import { BarDivider } from './barDividers.theme';
import { barDividerTestId } from './barDividers.const';

const BarDividers: FC<IBarDividersProps> = (props) => {
  const {
    dividersNumber,
    bgColor,
  } = props;

  return (
    <>
      {Array.from({ length: dividersNumber }).map((_item, index) => {
        const itemNumber = index + 1;
        const segmentsNumber = dividersNumber + 1;
        const leftPositionInPercent = `${100 / segmentsNumber * itemNumber}%`;
        return (
          <BarDivider
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            bgColor={bgColor}
            style={{ left: leftPositionInPercent }}
            data-testid={barDividerTestId}
          />
        );
      })}
    </>
  );
};

export default memo(BarDividers);
