import { ChangeEvent, FC, memo } from 'react';
import { Box, Tab } from '@mui/material';

import { formatStringToSnakeCase } from '@features/haulhubUi/helpers/formatStringToSnakeCase';

import { ITabsListProps } from './tabsList.interface';
import { StyledTabs } from './tabsList.theme';

const TabsList: FC<ITabsListProps> = memo((props) => {
  const {
    activeTab,
    tabsListConfig,
    tabsProps,
    tabProps,
    onTabChangeHandler,
  } = props;

  const changeActiveTabHandler = (event: ChangeEvent<any>, newValue: string): void => {
    onTabChangeHandler(newValue);
  };

  return (
    <StyledTabs
      {...tabsProps}
      value={activeTab}
      onChange={changeActiveTabHandler}
      aria-label="tabs"
    >
      {tabsListConfig.map((tab) => {
        const {
          id,
          value,
          label,
          disabled,
          additionalComponent,
        } = tab;
        const formattedValue = formatStringToSnakeCase(value);
        const currentLabel = additionalComponent
          ? <Box
              display="flex"
              alignItems="center"
            >
              <span>{label}</span>

              {additionalComponent}
            </Box>
          : label;

        return (
          <Tab
            {...tabProps}
            key={id}
            label={currentLabel}
            value={value}
            disabled={disabled}
            id={`tab-${formattedValue}`}
            aria-controls={`tabpanel-${formattedValue}`}
            data-testid={`tab-${formattedValue}`}
          />
        );
      })}
    </StyledTabs>
  );
});

export { TabsList };
