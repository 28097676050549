import { FC } from 'react';
import WifiOffIcon from '@mui/icons-material/WifiOff';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';

import {
  useGlobalButtonStyles,
  useGlobalFlexStyles,
} from '@assets/globalStyles';

import { noInternetMessageTexts } from './noInternetMessage.const';
import useStylesNoInternetMessage from './noInternetMessage.theme';
import { INoInternetMessageProps } from './noInternetMessage.interface';

const NoInternetMessage: FC<INoInternetMessageProps> = (props) => {
  const { closePopupHandler } = props;
  const { classes: flexClasses } = useGlobalFlexStyles();
  const { classes: buttonClasses } = useGlobalButtonStyles();
  const { classes, cx } = useStylesNoInternetMessage();

  return (
    <div
      className={cx(
        flexClasses.flexCenter,
        classes.noInternetMessage,
      )}
    >
      <WifiOffIcon fontSize='large' />

      <h3 className={classes.title}>
        {noInternetMessageTexts.TITLE}
      </h3>

      <IconButton
        className={cx(
          buttonClasses.buttonHoverEffect,
          classes.closePopupButton,
        )}
        size="small"
        onClick={closePopupHandler}
      >
        <CloseIcon className={classes.closePopupButtonIcon} />
      </IconButton>
    </div>
  );
};

export default NoInternetMessage;
