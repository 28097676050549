import { keyframes } from '@emotion/react';

import { rem, p } from '@helpers/remUtils';
import { makeStyles } from '@theme/appTheme';

const slide = keyframes`
  0% {
    height: 0};
  }
  100% {
    height: ${rem(60)};
  }
`;

const useStylesRefreshPageNotificationList = makeStyles()((theme) => ({
  refreshPageNotificationList: {
    position: 'fixed',
    top: 0,
    right: 0,
    left: 0,
    zIndex: 999999,
  },
  refreshPageNotification: {
    height: rem(60),
    padding: p(0, 20),
    background: '#EB8045',
    overflow: 'hidden',
    animation: `${slide} 1s forwards ease-in-out`,
    '&:not(:last-child)': {
      marginBottom: rem(5),
    },
  },
  refreshPageNotificationMessage: {
    marginRight: rem(30),
    fontSize: rem(20),
    fontWeight: 500,
  },
  refreshPageNotificationButton: {
    padding: rem(5),
    color: '#272e4f',
    background: theme.palette.common.white,
    '&:hover': {
      background: theme.palette.common.white,
    },
  },
}));

export default useStylesRefreshPageNotificationList;
