import { createAsyncThunk } from '@reduxjs/toolkit';
import { stringify } from 'qs';

import {
  ITrafficPercentageValues,
} from '@store/projectPlus/projectPlusDetails/interfaces/trafficPercentageValues.interface';
import api from '@configs/urls/api';

import instance from '../instanceDefiner';
import { IProjectPlusLapTrackTrafficPercentagesParams } from './projectPlusLapTrackTrafficPercentages.interface';

export const projectPlusLapTrackTrafficPercentages = createAsyncThunk(
  'projectPlusDetails/projectPlusLapTrackTrafficPercentages',
  async (filters: IProjectPlusLapTrackTrafficPercentagesParams) => {
    try {
      const response = await instance.get<ITrafficPercentageValues>(api.getTrafficPercentages, {
        params: {
          filters,
        },
        paramsSerializer: {
          serialize: (params) => stringify(params.filters, { arrayFormat: 'repeat', encode: true }),
        },
      });
      return response.data;
    } catch {
      return null;
    }
  },
);
