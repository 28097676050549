import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '@configs/urls/api';

import instance from '../instanceDefiner';
import { IExcludedSlipsParams } from './excludedSlipsParams.interface';

export const removeExcludedSlips = createAsyncThunk(
  'projectPlusDetails/removeExcludedSlips',
  async (inputParams: IExcludedSlipsParams) => {
    try {
      const response = await instance.delete(api.excludedSlips, {
        data: { ...inputParams },
      });
      return response.data;
    } catch {
      return Promise.reject();
    }
  },
);
