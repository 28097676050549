import React, { FC } from 'react';
import InputMask from 'react-input-mask';
import { PhoneInputWrapper } from './phoneInput.theme';
import { IPhoneInputProps } from './phoneInput.interface';

const PhoneInput: FC<IPhoneInputProps> = (props) => {
  const {
    inputAttributes,
    inputMaskProps,
    InputElement,
  } = props;
  const CurrentInputComponent = InputElement || PhoneInputWrapper;

  return (
    <InputMask
      mask="+1 (999) 999-9999"
      maskPlaceholder="x"
      alwaysShowMask={true}
      {...inputAttributes}
      {...inputMaskProps}
    >
      <CurrentInputComponent />
    </InputMask>
  );
};

export { PhoneInput };
