import React, { ComponentType, FC, ReactElement, useEffect } from 'react';

import useOkta from '../../../useOkta';

const hideAuthPagesAfterLogin = <P extends Record<string, unknown>>(
  Component: ComponentType<P>,
): FC<P> => {
  return function HideAuthPagesAfterLogin(props: P): ReactElement {
    const { isAuthenticated, redirectUrl } = useOkta();

    useEffect(() => {
      if (isAuthenticated) {
        window.location.replace(redirectUrl || '/');
      }
    }, []);

    return <Component {...props} />;
  };
};

export default hideAuthPagesAfterLogin;
