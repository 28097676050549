import { ENotificationsStatusType } from '@features/navigationContainer/components/pushNotificationMenu/enums/notificationStatusType.enum';

export const supportedNotificationTypeList = [
  ENotificationsStatusType.SHARED_NEW_WIDGET,
  ENotificationsStatusType.SHARED_WIDGET_UNSHARED,
  ENotificationsStatusType.FORCE_SHARED_NEW_WIDGET,
  ENotificationsStatusType.SHARED_WIDGET_REJECT,
  ENotificationsStatusType.SHARED_WIDGET_ACCEPT,
  ENotificationsStatusType.SHARED_WIDGET_BLOCKED,
  ENotificationsStatusType.CLONED_SHARED_WIDGET,
  ENotificationsStatusType.DELETED_WIDGET,
  ENotificationsStatusType.INTERNAL_SHARING,
];
