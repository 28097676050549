import { ESilentNotificationsStatusType } from '@features/navigationContainer/components/pushNotificationMenu/enums/notificationStatusType.enum';
import { IPushNotification } from '@features/sharedWidgets/pushNotificationsProvider/interfaces/pushNotification.interface';

interface ISilentPushActions {
  userInfoHandler: (silentPushValues: IPushNotification) => void;
  ticketTypesHandler: (silentPushValues: IPushNotification) => void;
  generalSettingsHandler: (silentPushValues: IPushNotification) => void;
  materialProducerHandler: (silentPushValues: IPushNotification) => void;
}

interface ISilentPushTypeConditionProps {
  silentPushActions: ISilentPushActions
}

type TSilentPushTypeCondition = Map<ESilentNotificationsStatusType, (silentPushValues: IPushNotification) => void>;

export const silentPushTypeCondition = (props: ISilentPushTypeConditionProps): TSilentPushTypeCondition => {
  const { silentPushActions } = props;
  const {
    userInfoHandler,
    ticketTypesHandler,
    generalSettingsHandler,
    materialProducerHandler,
  } = silentPushActions;
  // eslint-disable-next-line func-call-spacing
  return new Map<ESilentNotificationsStatusType, (silentPushValues: IPushNotification) => void>([
    [ESilentNotificationsStatusType.GENERAL_SETTINGS, generalSettingsHandler],
    [ESilentNotificationsStatusType.MATERIAL_PRODUCER, materialProducerHandler],
    [ESilentNotificationsStatusType.TICKET_TYPES, ticketTypesHandler],
    [ESilentNotificationsStatusType.USER_INFO, userInfoHandler]
  ]);
};
