import { alpha, styled } from '@mui/material';

import { m, p, rem } from '@helpers/remUtils';

export const ListWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  overflow: 'hidden',
  '.list-title': {
    margin: 0,
    flexShrink: 0,
    padding: p(10, 31),
    borderBottom: `${rem(1)} solid ${alpha(theme.palette.common.white, 0.1)}`,
    fontSize: rem(16),
    fontWeight: 700,
  },
  '.dnd-list-wrapper': {
    flexGrow: 1,
    margin: m(22, 0),
    padding: p(0, 31),
    overflowY: 'auto',
  },
}));
