import eventBus from '@eventBus/index';

import { EventBusTypes } from '../../enums/eventBusTypes.enum';
import { EventBusCallbackType } from '../../interfaces/callback.interface';
import { EmptyFunction } from '../../interfaces/emptyFunction.interface';
import { IUpdateEntityWatchlistStatusProps } from './updateEntityWatchlistStatus.interface';

const eventName = EventBusTypes.UPDATE_ENTITY_WATCHLIST_STATUS;

const subscribe = (
  callback: EventBusCallbackType<IUpdateEntityWatchlistStatusProps>
): EmptyFunction => {
  return eventBus.subscribe<IUpdateEntityWatchlistStatusProps>(eventName, callback);
};

const broadcast = (data: IUpdateEntityWatchlistStatusProps): void => {
  eventBus.broadcast<IUpdateEntityWatchlistStatusProps>(eventName, data);
};

export default {
  subscribe,
  broadcast,
};
