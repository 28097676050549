import { alpha, styled } from '@mui/material';

import { p, rem } from '@helpers/remUtils';

export const InformationMessageWrapper = styled('span')(({ theme }) => ({
  padding: p(15, 23),
  fontSize: rem(15),
  fontWeight: 700,
  backgroundColor: alpha(theme.palette.common.black, 0.1),
}));
