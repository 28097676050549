import { makeStyles } from '@theme/appTheme';

const useGlobalFlexStyles = makeStyles()(() => ({
  flexRow: {
    display: 'flex',
  },
  flexRowItemCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  flexRowItemStart: {
    display: 'flex',
    alignItems: 'flex-start',
  },
  flexRowItemStretchBetween: {
    display: 'flex',
    alignItems: 'stretch',
    justifyContent: 'space-between',
  },
  flexRowItemAround: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  flexRowItemEvenly: {
    display: 'flex',
    justifyContent: 'space-evenly',
  },
  flexRowItemCenterStart: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
  },
  flexRowCenterRight: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  flexRowWrap: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexColumnEnd: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  flexColumnBetween: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  flexRowWrapBetweenStart: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignContent: 'flex-start',
    alignItems: 'flex-start',
  },
  flexRowWrapCenterCenter: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexRowWrapBetweenCenter: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flexRowStartRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  flexColumnStartLeft: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  flexColumnStartStart: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
  },
  flexColumnStartEnd: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-end',
  },
  flexColumnStretch: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexColumnCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexColumnBetweenEnd: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  flexRowStartEnd: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  flexColumnCenterStart: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  flexInlineCenter: {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexBetweenCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flexBetweenEnd: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'end',
  },
  flexEndCenter: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  flexRowStartCenter: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
  },
  flexStartCenter: {
    display: 'flex',
    alignItems: 'center',
  },
  flexBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  inlineFlexCenter: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export default useGlobalFlexStyles;
