import { styled } from '@mui/material';

export const BarDivider = styled('span')<{ bgColor?: string }>(({ theme, bgColor }) => ({
  position: 'absolute',
  top: 0,
  display: 'block',
  width: '2%',
  height: '100%',
  transform: 'translateX(-50%)',
  backgroundColor: bgColor || theme.palette.background.default,
}));
