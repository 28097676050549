import { createSlice } from '@reduxjs/toolkit';

import { INavigationSliceState } from './navigationSlice.interface';

const initialState: INavigationSliceState = {
  isExpandedNavigation: false,
};

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    openNavigation(
      state: INavigationSliceState,
    ): void {
      state.isExpandedNavigation = true;
    },
    closeNavigation(
      state: INavigationSliceState,
    ): void {
      state.isExpandedNavigation = false;
    },
  },
});

export const {
  openNavigation,
  closeNavigation,
} = navigationSlice.actions;

export default navigationSlice.reducer;
