import { styled } from '@mui/material';
import Badge from '@mui/material/Badge';

import { rem } from '@helpers/remUtils';

export const InlineBadge = styled(Badge)({
  alignItems: 'center',

  '& .MuiBadge-badge': {
    position: 'relative',
    marginLeft: rem(5),
    transformOrigin: 'unset',
    transform: 'none',
  },
});
