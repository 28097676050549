import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import BaseLogo from '@components/baseLogo';

import androidBadge from '../../../assets/images/icons/androidBadge.png';
import appleBadge from '../../../assets/images/icons/appleBadge.svg';
import { devices } from '../redirectionToMobileApplication.const';
import { IMobileViewProps } from './mobileView.types';
import useStylesMobileView from './mobileView.theme';
import { TITLE_TEXT } from './mobileView.const';

const MobileView: FC<IMobileViewProps> = (props) => {
  const { link } = props;
  const { classes, cx } = useStylesMobileView();
  const { t } = useTranslation('informationMessages');

  const specifiedLink = link.includes(devices.iPad.className);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.contentWrapper}>
          <BaseLogo size='large' />

          <p className={classes.text}>
            {t(TITLE_TEXT, { ns: 'informationMessages' })}
          </p>

          <a href={link}>
            <img
              className={cx(classes.badge, specifiedLink ? devices.iPad.className : devices.android.className)}
              src={specifiedLink ? appleBadge : androidBadge}
              alt='Store badge'
            />
          </a>
        </div>
      </div>
    </>
  );
};

export default MobileView;
