import { FC } from 'react';

import { BarWrapper } from './bar.theme';
import { IBarProps } from './bar.interface';
import { barTrackTestId, barWrapperTestId } from './bar.const';
import BarDividers from './components/barDividers/BarDividers';

const Bar: FC<IBarProps> = (props) => {
  const {
    percent,
    bgColor,
    trackColor,
    dividersNumber,
    dividersColor,
  } = props;

  return (
    <BarWrapper
      percent={percent}
      bgColor={bgColor}
      trackColor={trackColor}
      data-testid={barWrapperTestId}
    >
      <span
        className="track"
        data-testid={barTrackTestId}
      />

      {dividersNumber
        ? <BarDividers
            dividersNumber={dividersNumber}
            bgColor={dividersColor || bgColor}
          />
        : null
      }
    </BarWrapper>
  );
};

export { Bar };
