import { useCallback, useState } from 'react';

import { ENotificationsStatusType } from '@features/navigationContainer/components/pushNotificationMenu/enums/notificationStatusType.enum';

import { IPushNotification } from '../../interfaces/pushNotification.interface';
import { IUseManageWidgetNotifications } from './manageWidgetNotifications.interface';

export const useManageWidgetNotifications = (): IUseManageWidgetNotifications => {
  const [addWidgetId, setAddWidgetId] = useState<number | null>(null);
  const [clonedWidgetId, setClonedWidgetId] = useState<number | null>(null);
  const [removeWidgetId, setRemoveWidgetId] = useState<number | null>(null);

  const checkWidgetTypeHandler = (message: IPushNotification): void => {
    const { notificationType, widgetId } = message;
    if (notificationType === ENotificationsStatusType.DELETED_WIDGET) {
      setRemoveWidgetId(Number(widgetId));
    }
    if (notificationType === ENotificationsStatusType.SHARED_WIDGET_UNSHARED) {
      setRemoveWidgetId(Number(widgetId));
    }
    if (notificationType === ENotificationsStatusType.FORCE_SHARED_NEW_WIDGET) {
      setAddWidgetId(Number(widgetId));
    }
    if (notificationType === ENotificationsStatusType.CLONED_SHARED_WIDGET) {
      setClonedWidgetId(Number(widgetId));
    }
  };

  const clearAddWidgetId = useCallback((): void => {
    setAddWidgetId(null);
  }, []);

  const clearClonedWidgetId = useCallback((): void => {
    setClonedWidgetId(null);
  }, []);

  const clearRemoveWidgetId = useCallback((): void => {
    setRemoveWidgetId(null);
  }, []);

  return {
    values: {
      addWidgetId,
      clonedWidgetId,
      removeWidgetId,
    },
    handlers: {
      checkWidgetTypeHandler,
      setAddWidgetId,
      setRemoveWidgetId,
      clearAddWidgetId,
      clearClonedWidgetId,
      clearRemoveWidgetId,
    },
  };
};
