import {
  IBaseTicketTypeItem,
  ITicketTypeItem,
} from '@store/materialClassifications/interfaces/ticketTypeItem.interface';
import { IMaterialTypes } from '@interfaces/materialTypes.interface';
import { getItemById } from '@helpers/arrayUtils';

const sortTicketTypes = (
  a: ITicketTypeItem,
  b: ITicketTypeItem,
): number => {
  return Number(b.active) - Number(a.active) || a.name.localeCompare(b.name);
};

export const addStatusToTicketTypes = (
  hhAdminTicketTypes: IBaseTicketTypeItem[] | null,
  materials: IMaterialTypes[],
): ITicketTypeItem[] => {
  if (!hhAdminTicketTypes) return [];

  return hhAdminTicketTypes
    .map((hhAdminTicket: IBaseTicketTypeItem): ITicketTypeItem => {
      const getTicketFromMaterials = getItemById(materials, hhAdminTicket.id, 'databaseIndex');
        return {
          ...hhAdminTicket,
          active: getTicketFromMaterials ? getTicketFromMaterials.active : false,
          locked: getTicketFromMaterials ? getTicketFromMaterials.locked : false,
        };
    })
    .sort(sortTicketTypes);
};
