import { UniqueIdentifier } from '@dnd-kit/core';

import {
  IUseColumnManagementLists,
} from '@pages/admin/pages/calibration/hooks/useColumnManagementList/useColumnManagementListStates.interface';

export const findContainer = (
  items: IUseColumnManagementLists,
  id: UniqueIdentifier | undefined
): UniqueIdentifier | undefined => {
  if (!items || !id) return;

  if (id in items) {
    return id;
  }

  return Object.keys(items).find((key) => {
    const itemKey = key as keyof IUseColumnManagementLists;
    return items[itemKey].some(element => element.id === id);
  });
};
