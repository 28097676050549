import { jwtDecode, JwtPayload } from 'jwt-decode';

import { IOktaData, IOktaStoredPayload } from '../../oktaClient.interface';

export const wrapDataEntry = (entry: IOktaData): IOktaStoredPayload => {
  const tokenDecoded: JwtPayload = jwtDecode(entry.token);
  const expires = tokenDecoded.exp ? tokenDecoded.exp * 1000 : 0;

  return {
    body: entry,
    expiresAt: expires,
  };
};
