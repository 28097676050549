import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ICustomWidget } from '@interfaces/customWidget.interface';

import { IActiveQuantityTrackerWidget } from './activeQuantityTrackerWidget.interface';
import { IActiveBucketWidget } from './activeBucketWidget.interface';
import { IWidgetsSlice } from './widgetsSlice.interface';

const initialState: IWidgetsSlice = {
  isDraggableMode: false,
  activeBucketWidget: null,
  activeQuantityTrackerWidget: null,
  isWidgetListEmpty: false,
  widgetForDelete: null,
  isCreateWidgetModalOpen: false,
  // temporary fieeld
  fetchingWidgetId: null,
};

const widgetsSlice = createSlice({
  name: 'widgets',
  initialState,
  reducers: {
    turnOnDraggableMode(state: IWidgetsSlice): void {
      state.isDraggableMode = true;
    },
    turnOffDraggableMode(state: IWidgetsSlice): void {
      state.isDraggableMode = false;
    },
    setActiveBucketWidget(
      state: IWidgetsSlice,
      action: PayloadAction<IActiveBucketWidget>,
    ): void {
      const { payload } = action;
      state.activeBucketWidget = payload;
    },
    clearActiveBucketWidget(state: IWidgetsSlice): void {
      state.activeBucketWidget = null;
    },
    setActiveQuantityTrackerWidget(
      state: IWidgetsSlice,
      action: PayloadAction<IActiveQuantityTrackerWidget>,
    ): void {
      const { payload } = action;
      state.activeQuantityTrackerWidget = payload;
    },
    updateActiveQuantityTrackerWidgetBaseWidget(
      state: IWidgetsSlice,
      action: PayloadAction<ICustomWidget>,
    ): void {
      if (!state.activeQuantityTrackerWidget) return;
      const { payload } = action;
      state.activeQuantityTrackerWidget.baseWidget = payload;
    },
    updateActiveBucketWidgetBaseWidget(
      state: IWidgetsSlice,
      action: PayloadAction<ICustomWidget>,
    ): void {
      if (!state.activeBucketWidget) return;
      const { payload } = action;
      state.activeBucketWidget.baseWidget = payload;
    },
    clearActiveQuantityTrackerWidget(state: IWidgetsSlice): void {
      state.activeQuantityTrackerWidget = null;
    },
    setIsWidgetListEmpty(
      state: IWidgetsSlice,
      action: PayloadAction<boolean>,
    ): void {
      const { payload } = action;
      state.isWidgetListEmpty = payload;
    },
    setWidgetForDelete(
      state: IWidgetsSlice,
      action: PayloadAction<ICustomWidget>,
    ): void {
      const { payload } = action;
      state.widgetForDelete = payload;
    },
    clearWidgetForDelete(state: IWidgetsSlice): void {
      state.widgetForDelete = null;
    },
    openCreateWidgetModal(state: IWidgetsSlice): void {
      state.isCreateWidgetModalOpen = true;
    },
    closeCreateWidgetModal(state: IWidgetsSlice): void {
      state.isCreateWidgetModalOpen = false;
    },
    setFetchingWidgetId(
      state: IWidgetsSlice,
      action: PayloadAction<number | null>,
    ): void {
      state.fetchingWidgetId = action.payload;
    },
    clearFetchingWidgetId(state: IWidgetsSlice): void {
      state.fetchingWidgetId = null;
    },
  },
});

export const {
  setIsWidgetListEmpty,
  turnOnDraggableMode,
  turnOffDraggableMode,
  setActiveBucketWidget,
  clearActiveBucketWidget,
  setActiveQuantityTrackerWidget,
  clearActiveQuantityTrackerWidget,
  setWidgetForDelete,
  openCreateWidgetModal,
  closeCreateWidgetModal,
  setFetchingWidgetId,
  clearFetchingWidgetId,
  clearWidgetForDelete,
  updateActiveQuantityTrackerWidgetBaseWidget,
  updateActiveBucketWidgetBaseWidget,
} = widgetsSlice.actions;
export default widgetsSlice.reducer;
