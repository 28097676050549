import React, { FC, ReactElement, useEffect, useState } from 'react';
import { Outlet } from 'react-router';

import SuspenseLoading from '@components/suspenseLoading';
import { useOkta } from '@features/oktaClient';

const AuthenticatePortalChecker: FC = (): ReactElement => {
  const { getTokenData, loadSession } = useOkta();
  const [loaded, setLoaded] = useState(false);
  const hash = new URLSearchParams(window.location.hash.substring(1));
  const state = hash.get('state');

  useEffect(() => {
    if (!getTokenData() && !state) {
      loadSession();
      return;
    }
    setLoaded(true);
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('AuthenticatePortalChecker');
  }, []);

  return (
    <>
      {loaded ? <Outlet /> : <SuspenseLoading />}
    </>
  );
};

export default AuthenticatePortalChecker;
