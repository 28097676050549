import { rem, p, m } from '@helpers/remUtils';
import { makeStyles } from '@theme/appTheme';

const useStylesConfirmPopup = makeStyles()((theme) => ({
  popupWrapper: {
    width: rem(400),
    padding: p(20, 20, 10),
    backgroundColor: theme.palette.background.paper,
    borderRadius: rem(10),
    whiteSpace: 'pre-line',
  },
  popupTitle: {
    margin: m(0, 0, 10, 0),
    fontWeight: 600,
    textAlign: 'center',
  },
  popupDescription: {
    margin: m(0, 0, 10, 0),
    fontWeight: 500,
    textAlign: 'center',
  },
}));

export default useStylesConfirmPopup;
