import { useContext } from 'react';

import { ManageModalsContext } from './ManageModalsProvider';
import { IManageModalsProviderValues } from './manageModalsProvider.interface';

const useManageModals = (): IManageModalsProviderValues => {
  const context = useContext(ManageModalsContext);

  if (context === undefined) {
    throw new Error('useManageModals must be used within a ManageModalsProvider');
  }

  return context;
};

export { useManageModals };
