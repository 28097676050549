import { styled } from '@mui/material';

import { br, p, rem, m } from '@helpers/remUtils';

export const DownloadTicketsReportProgressModalContent = styled('div')(({ theme }) => ({
  borderRadius: br(10),
  background: theme.palette.background.paper,
  lineHeight: 1,
  '& .report-progress-modal-body': {
    padding: p(24),
  },
  '& .report-progress-modal-text': {
    margin: m(0, 0, 20),
    fontSize: rem(16),
    fontWeight: 400,
  },
  '& .report-progress-modal-cancel-button': {
    margin: p(0, 0, 0, 5),
    padding: 0,
    fontSize: rem(16),
    fontWeight: 400,
    verticalAlign: 'baseline',
  },
  '& .report-progress-modal-loader': {
    height: rem(20),
  },
}));
