import { FC, ReactElement, useState, MouseEvent, useMemo } from 'react';
import { Chip, Fade, ClickAwayListener, Popper } from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { convertTimePartsIntoTimeString, getFormattedTime, getTimeParts } from '@helpers/timeUtils';
import { ITimeParts } from '@components/nativeTimePiker/interfaces/ITimeParts.interface';

import { dropdownTimePickerChipTestId, dropdownTimePickerTexts } from './dropdownTimePicker.const';
import { IDropdownTimePickerProps } from './dropdownTimePicker.interface';
import DropdownTimePickerContent from './components/dropdownTimePickerContent/DropdownTimePickerContent';

const DropdownTimePicker: FC<IDropdownTimePickerProps> = (props) => {
  const {
    timeStart,
    timeEnd,
    options,
    PopperComponent,
    changeTimeRangeHandler,
  } = props;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const {
    label,
    ...otherOptions
  } = options || {};
  const CurrentPopperComponent = PopperComponent || Popper;

  const currentLabel = useMemo((): string => {
    if (timeStart && timeEnd) {
      return `${getFormattedTime(timeStart)} - ${getFormattedTime(timeEnd)}`;
    }
    if (label) {
      return label;
    }
    return dropdownTimePickerTexts.DEFAULT_LABEL;
  }, [
    timeStart,
    timeEnd,
    label,
  ]);

  const toggleIsDropdownOpenHandler = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
    setIsDropdownOpen((previousOpen) => !previousOpen);
  };

  const closeDropdownHandler = (): void => {
    setIsDropdownOpen(false);
  };

  const resetTimeRangeHandler = (): void => {
    changeTimeRangeHandler(null, null);
    setIsDropdownOpen(false);
  };

  const onSaveButtonClick = (newTimeStart: ITimeParts | null, newTimeEnd: ITimeParts | null): void => {
    const currentNewTimeStart = newTimeStart ? convertTimePartsIntoTimeString(newTimeStart) : null;
    const currentNewTimeEnd = newTimeEnd ? convertTimePartsIntoTimeString(newTimeEnd) : null;
    changeTimeRangeHandler(currentNewTimeStart, currentNewTimeEnd);
    setIsDropdownOpen(false);
  };

  return (
    <>
      <Chip
        icon={<AccessTimeIcon fontSize="small" />}
        label={currentLabel}
        variant="filled"
        color={isDropdownOpen ? 'secondary' : 'default'}
        data-testid={dropdownTimePickerChipTestId}
        onClick={toggleIsDropdownOpenHandler}
        onDelete={timeStart ? resetTimeRangeHandler : undefined}
      />

      <CurrentPopperComponent
        open={isDropdownOpen}
        anchorEl={anchorEl}
        transition={true}
        placement="bottom-start"
      >
        {({ TransitionProps }): ReactElement => (
          <ClickAwayListener onClickAway={closeDropdownHandler}>
            <Fade
              {...TransitionProps}
              timeout={350}
            >
              {/* Fade don`t work with styled component */}
              <div>
                <DropdownTimePickerContent
                  timeStart={timeStart ? getTimeParts(timeStart) : null}
                  timeEnd={timeEnd ? getTimeParts(timeEnd) : null}
                  options={otherOptions}
                  PopperComponent={PopperComponent}
                  onSaveButtonClick={onSaveButtonClick}
                />
              </div>
            </Fade>
          </ClickAwayListener>
        )}
      </CurrentPopperComponent>
    </>
  );
};

export { DropdownTimePicker };
