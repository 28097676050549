import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ViewModeEnum } from '@enums/viewMode.enum';
import { FilterTypeEnum } from '@enums/filterType.enum';
import { CategoryStatusEnum } from '@enums/categoryStatus.enum';

import { IDetailsWatchlistControlsSlice } from './detailsWatchlistControlsSlice.interface';

const initialState: IDetailsWatchlistControlsSlice = {
  isResetEntityByStatus: true,
  filterType: FilterTypeEnum.STATUSES,
  viewModeList: ViewModeEnum.TILE,
  entityByStatus: CategoryStatusEnum.ALL,
};

const detailsWatchlistControlsSlice = createSlice({
  name: 'detailsWatchlistControls',
  initialState,
  reducers: {
    changeFilterType(
      state: IDetailsWatchlistControlsSlice,
      action: PayloadAction<FilterTypeEnum>,
    ): void {
      state.filterType = action.payload;
    },
    changeViewModeList(
      state: IDetailsWatchlistControlsSlice,
      action: PayloadAction<ViewModeEnum>,
    ): void {
      state.viewModeList = action.payload;
    },
    changeEntityByStatus(
      state: IDetailsWatchlistControlsSlice,
      action: PayloadAction<string>,
    ): void {
      state.entityByStatus = action.payload;
    },
    changeIsResetEntityByStatus(
      state: IDetailsWatchlistControlsSlice,
      action: PayloadAction<boolean>,
    ): void {
      state.isResetEntityByStatus = action.payload;
    },
  },
});

export const {
  changeFilterType,
  changeViewModeList,
  changeEntityByStatus,
  changeIsResetEntityByStatus,
} = detailsWatchlistControlsSlice.actions;
export default detailsWatchlistControlsSlice.reducer;
