import { useState, useEffect, useRef } from 'react';

import { ICustomResponse } from '@interfaces/customResponse.interface';

import { IUseWebSocketProps } from './useWebSocketProps.interface';
import { IUseWebSocket } from './useTicketSearch.interface';

export const useWebSocket = <T>(props: IUseWebSocketProps<T>): IUseWebSocket<T> => {
  const {
    socketUrl,
    autoConnect = true,
    defaultSubscribers = [],
  } = props;
  const [lastMessage, setLastMessage] = useState<T | null>(null);
  const socket = useRef<WebSocket | null>(null);
  const subscribers = useRef<((message: T) => void)[]>(defaultSubscribers);

  const connect = (): Promise<ICustomResponse<any>> => {
    return new Promise((resolve) => {
      if (socket.current && socket.current.readyState === WebSocket.OPEN) return;
      socket.current = new WebSocket(socketUrl);
      socket.current.onmessage = (event): void => {
        const { data } = event;
        const formattedData = JSON.parse(data);
        setLastMessage(formattedData);
        subscribers.current.forEach(subscriber => subscriber(formattedData));
      };
      socket.current.onopen = (): void => {
        resolve({ responseType: 'SUCCESS' });
      };
      socket.current.onerror = (): void => {
        resolve({ responseType: 'ERROR' });
      };
      socket.current.onclose = (): void => {
        subscribers.current = defaultSubscribers;
      };
    });
  };

  const disconnect = (): void => {
    if (!socket.current) return;
    socket.current.close();
  };

  const subscribeToData = (subscriber: (message: T) => void): VoidFunction => {
    subscribers.current.push(subscriber);

    return () => {
      const index = subscribers.current.indexOf(subscriber);
      if (index === -1) return;
      subscribers.current.splice(index, 1);
    };
  };

  const sendMessage = (message: string): void => {
    if (!socket.current || socket.current.readyState !== WebSocket.OPEN) return;
    socket.current.send(message);
  };

  useEffect(() => {
    if (autoConnect) {
      connect();
    }

    return disconnect;
  }, [autoConnect]);

  return {
    values: {
      lastMessage,
    },
    actions: {
      connect,
      disconnect,
      sendMessage,
      subscribeToData,
    },
  };
};
