import { ESilentNotificationsStatusType } from '@features/navigationContainer/components/pushNotificationMenu/enums/notificationStatusType.enum';
import eventBus from '@eventBus/index';

import { IPushNotification } from '../../interfaces/pushNotification.interface';
import { supportedSilentPushNotification } from '../../configs/supportedSilentPushNotification';
import { IUseSilentPushNotificationsController } from './useSilentPushNotificationsController.interface';
import { silentPushTypeCondition } from './helpers/silentPushTypeCondition.helper';

export const useSilentPushNotificationsController = (): IUseSilentPushNotificationsController => {
  const isSilentPush = (pushType: ESilentNotificationsStatusType): boolean => {
    return Boolean(supportedSilentPushNotification[pushType]);
  };

  const materialProducerHandler = (): void => {
    eventBus.materialProducerNotification.broadcast();
  };

  const ticketTypesHandler = (silentPushValues: IPushNotification): void => {
    const { sourceUserId } = silentPushValues;
    if (!sourceUserId) return;
    eventBus.ticketTypeNotification.broadcast(Number(sourceUserId));
  };

  const userInfoHandler = (silentPushValues: IPushNotification): void => {
    eventBus.userInfoNotification.broadcast(silentPushValues);
  };

  const generalSettingsHandler = (silentPushValues: IPushNotification): void => {
    const { sourceUserId } = silentPushValues;
    if (!sourceUserId) return;
    eventBus.generalSettingsNotification.broadcast(Number(sourceUserId));
  };

  const silentPushActions = {
    userInfoHandler,
    ticketTypesHandler,
    generalSettingsHandler,
    materialProducerHandler,
  };

  const silentPushChecker = (silentPushValues: IPushNotification): void => {
    const { notificationType } = silentPushValues;
    const notificationAction = silentPushTypeCondition({ silentPushActions }).get(notificationType as unknown as ESilentNotificationsStatusType);

    if (notificationAction) {
      notificationAction(silentPushValues);
    }
  };

  return {
    isSilentPush,
    silentPushChecker,
  };
};
