import { makeStyles } from '@theme/appTheme';
import { m } from '@helpers/remUtils';

const useGlobalButtonStyles = makeStyles()((theme) => ({
  buttonHoverEffect: {
    transition: 'opacity 0.2s ease-out',
    '&:hover': {
      opacity: '.5',
    },
  },
  buttonOpacityHoverEffect: {
    opacity: 0.7,
    transition: 'opacity 0.2s ease-out',
    '&:hover': {
      opacity: 1,
    },
  },
  buttonHoverColorEffect: {
    transition: 'color 0.3s ease-in-out',
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  buttonDisableEffect: {
    '&:disabled': {
      background: '#CFCFCF',
    },
  },
  outlineButtonDisableEffect: {
    '&:disabled': {
      opacity: 0.4,
    },
    '&:hover:disabled': {
      cursor: 'default',
    },
  },
  buttonMarginRight: {
    marginRight: '10px',
  },
  defaultButton: {
    margin: 0,
    padding: 0,
    outline: 'none',
    border: 'none',
    background: 'transparent',
    cursor: 'pointer',
  },
  squareButton: {
    borderRadius: '3px',
    background: theme.palette.additionalColors.squareButton.main,
    color: theme.palette.additionalColors.squareButton.dark,
    '&:disabled': {
      cursor: 'default',
      background: theme.palette.additionalColors.squareButton.light,
    },
  },
  closePopupIconButton: {
    margin: m(0, 14, 0, 0),
    background: theme.palette.additionalColors.buttonIconBackgroundColor.main,
    color: theme.palette.background.default,
    '&:hover': {
      background: theme.palette.additionalColors.buttonIconBackgroundColor.main,
    },
  },
}));

export default useGlobalButtonStyles;
