import React, { createContext, FC, ReactElement, useCallback, useEffect, useReducer, useState } from 'react';
import { AxiosError } from 'axios';

import { deleteNotificationToken } from '@hooks/useLogoutHandler/useLogoutHandler.helper';
import { isPortalType } from '@okta/intialOktaApp/helpers/isPortalType.helper';
import { LocalStorageKeysEnum } from '@enums/localStorageKeys.enum';
import { setStorageItem } from '@helpers/localStorage';

import { notifications } from '../../helpers/notifications';
import { IOktaRequestData, IOktaRequestDataWithCode } from './oktaClient/oktaClient.interface';
import { IOktaAuthContext, IOktaProviderProps, VariantType } from './oktaProvider.interface';
import { initialAuthState, initialContext } from './oktaProvider.const';
import OktaClient from './oktaClient/oktaClient';
import { reducer } from './reducer';

export const OktaContext = createContext<IOktaAuthContext>(initialContext);
OktaContext.displayName = 'OktaProvider';

const oktaAppClientId = process.env.REACT_APP_OKTA_APP_CLIENT_ID;
const oktaAuthServerBaseURL = process.env.REACT_APP_OKTA_AUTHSERVER_BASE_URL;

const OktaProvider: FC<IOktaProviderProps> = (props): ReactElement => {
  const {
    children,
    redirectUrl,
    logoutRedirectUrl,
    redirect,
    requestCallback,
    ...clientConfig
  } = props;
  const [client] = useState(new OktaClient(clientConfig));
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const showToaster = (
    textMessage: string,
    variant: VariantType = 'success',
  ): void => {
    requestCallback(
      textMessage,
      { variant },
    );
  };

  const loginComplete = (): void => {
    dispatch({ type: 'LOGIN_COMPLETE' });
    redirect(redirectUrl || '/');
  };

  const logoutComplete = (): void => {
    dispatch({ type: 'LOGOUT_COMPLETE' });
    redirect(logoutRedirectUrl || '/');
  };

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        const token = await client.getToken();
        if (!token) {
          dispatch({ type: 'LOGOUT_COMPLETE' });
          return;
        }
        dispatch({ type: 'LOGIN_COMPLETE' });
      } catch {
        if (isPortalType()) {
          dispatch({ type: 'LOGOUT_COMPLETE' });
          return;
        }
        logoutComplete();
      } finally {
        if (!isPortalType()) {
          redirect(window.location.pathname || '/');
        }
      }
    })();
  }, []);

  const loginRedirectUri = (sessionToken?: string): string => {
    // generate random 6-char string
    const appState = Math.random().toString(36).slice(2, 8);
    setStorageItem(LocalStorageKeysEnum.AUTH_APP_STATE, appState);

    const params = {
      client_id: oktaAppClientId,
      response_type: 'code',
      response_mode: 'fragment',
      scope: 'openid email offline_access',
      redirect_uri: `${window.location.origin}/authorization/callback`,
      state: appState,
      prompt: 'none',
      sessionToken,
    };

    return `${oktaAuthServerBaseURL}/v1/authorize?${Object.entries(params).map(e => `${e[0]}=${encodeURIComponent(e[1] as string)}`).join('&')}`;
  };

  const authenticateWithAuthorizationCode = async (
    code: string,
    redirectUri: string,
    errorCallback?: VoidFunction,
  ): Promise<void> => {
    try {
      await client.getTokenByCode(code, redirectUri);
      loginComplete();
    } catch (error) {
      const currentError = error as AxiosError;
      dispatch({ type: 'ERROR' });
      if (currentError?.response?.status !== 401 && currentError?.response?.status !== 403) {
        showToaster(notifications.somethingWentWrong, 'error');
        return;
      }
      if (errorCallback) errorCallback();
    }
  };

  const loadSession = (): void => {
    window.location.href = loginRedirectUri();
  };

  const login = useCallback(async (
    userData: IOktaRequestData,
    errorCallback?: VoidFunction,
  ): Promise<void> => {
    dispatch({ type: 'LOGIN_STARTED' });
    try {
      await client.login(userData);
      loginComplete();
    } catch (error) {
      const currentError = error as AxiosError;
      dispatch({ type: 'ERROR' });
      if (currentError?.response?.status !== 401 && currentError?.response?.status !== 403) {
        showToaster(notifications.somethingWentWrong, 'error');
        return;
      }
      if (errorCallback) errorCallback();
    }
  }, [client]);

  const logoutRedirectUri = useCallback((idTokenHint: string): string => {
    const params = {
      id_token_hint: idTokenHint,
      post_logout_redirect_uri: `${window.location.origin}`,
    };

    return `${oktaAuthServerBaseURL}/v1/logout?${Object.entries(params).map(e => `${e[0]}=${encodeURIComponent(e[1] as string)}`).join('&')}`;
  }, [client]);

  const logoutWithRedirectUri = useCallback((): void => {
    const idTokenHint = client.getIdTokenData();
    client.logoutByIdToken();
    dispatch({ type: 'LOGOUT_COMPLETE' });
    window.location.href = logoutRedirectUri(idTokenHint);
  }, [client]);

  const logout = useCallback(async (): Promise<void> => {
    dispatch({ type: 'LOGOUT_STARTED' });

    await deleteNotificationToken();

    if (isPortalType()) {
      logoutWithRedirectUri();
      return;
    }

    try {
      await client.logout();
      logoutComplete();
    } catch (error) {
      dispatch({ type: 'ERROR' });
    }
  }, [client]);

  const getAccessTokenSilently = useCallback(async (): Promise<string | null> => {
    try {
      const token = await client.refreshToken();
      return token || '';
    } catch {
      logoutComplete();
      return null;
    }
  }, [client]);

  const sendResetPasswordEmail = useCallback(async (email: string, currentRedirectUrl?: string, options?: Record<string, unknown>): Promise<void> => {
    dispatch({ type: 'SEND_EMAIL_STARTED' });
    try {
      const response = await client.sendResetPasswordEmail(email);
      showToaster(response);
      if (currentRedirectUrl) {
        redirect(currentRedirectUrl, { ...options });
      }
      dispatch({ type: 'SEND_EMAIL_COMPLETE' });
    } catch (error: any) {
      const correctErrorText = error || notifications.somethingWentWrong;
      showToaster(correctErrorText, 'error');
      dispatch({ type: 'ERROR' });
    }
  }, [client]);

  const resetPasswordWithCode = useCallback(async (
    data: IOktaRequestDataWithCode,
    errorCallback?: VoidFunction,
  ): Promise<void> => {
    dispatch({ type: 'LOGIN_STARTED' });
    try {
      await client.resetPasswordUsingCode(data);
      loginComplete();
      showToaster(notifications.resetPasswordSuccess);
    } catch (error) {
      const currentError = error as AxiosError;
      dispatch({ type: 'ERROR' });
      if (currentError?.response?.status !== 403) {
        showToaster(notifications.somethingWentWrong, 'error');
        return;
      }
      if (errorCallback) errorCallback();
    }
  }, [client]);

  const getTokenData = useCallback((): string => {
    return client.getTokenData();
  }, [client]);

  return (
    <OktaContext.Provider
      value={{
        ...state,
        redirectUrl,
        logoutRedirectUrl,
        login,
        logout,
        getAccessTokenSilently,
        sendResetPasswordEmail,
        resetPasswordWithCode,
        getTokenData,
        loadSession,
        logoutWithRedirectUri,
        authenticateWithAuthorizationCode,
      }}
    >
      {children}
    </OktaContext.Provider>
  );
};

export default OktaProvider;
