export enum ESubMenuTabs {
  CUSTOMERS = 'CUSTOMERS',
  PLANTS = 'PLANTS',
  TONNAGE_RANGE = 'TONNAGE_RANGE',
  TICKET_TYPES = 'TICKET_TYPES',
  TIME = 'TIME',
  EXCLUSION_TIME = 'EXCLUSION_TIME',
  TRUCKS = 'TRUCKS',
  FLEETS = 'FLEETS',
  PROJECTED_TARGET_TIME = 'PROJECTED_TARGET_TIME',
  HIDDEN = 'HIDDEN',
}
