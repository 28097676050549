import { useState } from 'react';

import { IMaterialTypes } from '@interfaces/materialTypes.interface';
import api from '@configs/urls/api';

import { httpClient } from '../../../../okta/axiosInstance';
import { IUseGetMaterialTypes } from './useGetMaterialTypes.interface';

export const useGetMaterialTypes = (): IUseGetMaterialTypes => {
  const [materials, setMaterials] = useState<IMaterialTypes[]>([]);
  const [initialLoading, setInitialLoading] = useState<boolean>(true);

  const getMaterialTypes = async (): Promise<void> => {
    try {
      const { data } = await httpClient.get<IMaterialTypes[]>({
        url: api.getMaterials,
        config: {
          params: {
            onlyActive: false,
          },
        },
      });
      setMaterials(data);
    } finally {
      setInitialLoading(false);
    }
  };

  return {
    values: {
      materials,
      initialLoading,
    },
    handlers: {
      getMaterialTypes,
      setMaterials,
    },
  };
};
