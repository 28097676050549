import { createAsyncThunk } from '@reduxjs/toolkit';
import { stringify } from 'qs';
import { RootStateType } from '@store/interfaces/rootState.type';

import api from '@configs/urls/api';
import { IProjectPlusDetailsDto } from '@interfaces/projectPlusDetails/projectPlusDetailsResponseDto.interface';

import instance from '../instanceDefiner';
import { IGetProjectPlusRoundParams } from './getProjectPlusRoundParams.interface';

let controller = new AbortController();

export const getRounds = createAsyncThunk(
  'projectPlusDetails/getRounds',
  async (inputParams: IGetProjectPlusRoundParams) => {
    try {
      const response = await instance.get<IProjectPlusDetailsDto>(api.getRounds, {
        params: {
          excludeRounds: true,
          pageNumber: 0,
          pageSize: 10000000,
          ...inputParams,
        },
        paramsSerializer: {
          serialize: params => stringify(params, { encode: true }),
        },
        signal: controller.signal,
      });
      return response.data;
    } catch {
      return Promise.reject();
    }
  },
  {
    condition: (arg, apiData): any => {
      const { projectPlusDetailsReducer } = apiData.getState() as RootStateType;

      if (projectPlusDetailsReducer.loading) {
        controller.abort();
        controller = new AbortController();
      }
    },
  },
);
