import { FC } from 'react';
import { Skeleton } from '@mui/material';

import { ICustomSkeletonProps } from './customSkeletonProps.types';
import skeleton from './customSkeleton.const';

const CustomSkeleton: FC<ICustomSkeletonProps> = props => {
  const {
    lines = 1,
    lineHeight = '100px',
    variant = 'text',
    externalClasses = {},
    component = 'li',
  } = props;

  return (
    <>
      {
        Array.from({ length: lines }, (v, i) =>
          (
            <Skeleton
              key={i}
              variant={variant}
              component={component}
              animation="wave"
              className={externalClasses['root'] || ''}
              height={lineHeight}
              role={skeleton}
            />
          ))
      }
    </>
  );
};

export default CustomSkeleton;
