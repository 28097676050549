import { makeStyles } from '@theme/appTheme';

export const useStylesRecentlyViewed = makeStyles()(() => ({
  noInfoText: {
    fontWeight: 400,
  },
  list: {
    padding: 0,
  },
}));
