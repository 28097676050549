import { Box, styled } from '@mui/material';

import { rem } from '@helpers/remUtils';

import { IBarProps } from './bar.interface';

export const BarWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'bgColor' && prop !== 'trackColor' && prop !== 'percent',
})<IBarProps>(({ theme, bgColor, trackColor, percent }) => ({
  position: 'relative',
  height: rem(8),
  borderRadius: rem(4),
  backgroundColor: bgColor || theme.palette.background.default,
  overflow: 'hidden',
  '.track': {
    display: 'block',
    width: '100%',
    height: '100%',
    backgroundColor: trackColor || theme.palette.background.paper,
    transform: `translateX(${percent - 100}%)`,
    transition: '1s',
  },
}));
