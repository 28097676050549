import { AnimationDirection } from '@enums/animationDirection';

export const getTransformValue = (animationDirection: AnimationDirection, px: number): string => {
  const transitionScale =
    animationDirection === AnimationDirection.LEFT || animationDirection === AnimationDirection.RIGHT ? 'X' : 'Y';
  const transitionOperator =
    animationDirection === AnimationDirection.RIGHT || animationDirection === AnimationDirection.DOWN ? '-' : '';

  return `translate${transitionScale}(${transitionOperator}${px}px)`;
};
