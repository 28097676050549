import { Locale } from 'date-fns';
import { enUS, frCA } from 'date-fns/locale';
import { ELocalization } from '@enums/localization.enum';

export const getDateLocalizationModule = (): Locale => {
  const { language } = navigator;

  if (language === ELocalization.FRENCH) {
    return frCA;
  }

  return enUS;
};
