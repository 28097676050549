import { createAsyncThunk } from '@reduxjs/toolkit';
import { stringify } from 'qs';

import api from '@configs/urls/api';
import { IHistoricalDayStatisticsDto } from '@interfaces/projectPlusHistorical/historicalDayStatisticsDto.interface';

import instance from '../instanceDefiner';
import { IGetHistoricalDayStatisticsParams } from './getHistoricalDayStatisticsParams.interface';

export const getHistoricalDayStatistics = createAsyncThunk(
  'projectPlusHistorical/getDayStatistics',
  async (inputParams: IGetHistoricalDayStatisticsParams, thunkAPI) => {
    try {
      const response = await instance.get<IHistoricalDayStatisticsDto[]>(api.getDayStatistics, {
        params: { ...inputParams },
        paramsSerializer: {
          serialize: params => stringify(params, { encode: true }),
        },
        signal: thunkAPI.signal,

      });
      return response.data;
    } catch {
      return Promise.reject();
    }
  },
);
