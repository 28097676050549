import { TextField, styled } from '@mui/material';
import { rem, m } from '@helpers/remUtils';

export const StyledTextField = styled(TextField)(({ theme }) => ({
  width: rem(265),
  height: rem(26),
  margin: m(0, 0, 11, 0),
  fontWeight: 400,
  border: 'none',
  borderRadius: rem(6),
  backgroundColor: theme.palette.additionalColors.searchFieldBackground.main,

  '.search-icon-wrapper': {
    paddingLeft: rem(8),
  },
}));
