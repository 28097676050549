import { PopupStyleTypeEnum } from '../enums/popupStyleTypeEnum.enum';
import useStylesDefaultModal from '../UiKit/defaultModal.theme';
import useStylesFlyOutModal from '../UiKit/flyOutModal.theme';
import useStylesFullPageModal from '../UiKit/fullPageModal.theme';
import useStylesTopCenterModal from '../UiKit/topCenterModal.theme';

export const popupStyleTypes = {
  [PopupStyleTypeEnum.DEFAULT]: useStylesDefaultModal,
  [PopupStyleTypeEnum.FLY_OUT]: useStylesFlyOutModal,
  [PopupStyleTypeEnum.TOP_CENTER]: useStylesTopCenterModal,
  [PopupStyleTypeEnum.FULL_PAGE]: useStylesFullPageModal,
};
