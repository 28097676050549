import {
  endOfWeek,
  startOfMonth,
  startOfWeek,
  subDays,
} from 'date-fns';

import { IDateRangeButton } from '../interfaces/dateRangeButton.interface';

const today = new Date();

export const defaultDateRangeButtonsConfigList: Record<string, IDateRangeButton> = {
  THIS_WEEK: {
    label: 'This Week',
    value: 'THIS_WEEK',
    from: startOfWeek(today),
    to: today,
  },
  LAST_WEEK: {
    label: 'Last Week',
    value: 'LAST_WEEK',
    from: startOfWeek(subDays(today, 7)),
    to: endOfWeek(subDays(today, 7)),
  },
  LAST_SEVEN_DAYS: {
    label: 'Last 7 Days',
    value: 'LAST_SEVEN_DAYS',
    from: subDays(today, 7),
    to: subDays(today, 1),
  },
  CURRENT_MONTH: {
    label: 'Current Month',
    value: 'CURRENT_MONTH',
    from: startOfMonth(today),
    to: today,
  },
};
