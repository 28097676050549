import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ESubMenuTabs } from '@pages/projectPlusModule/components/optionsTabLayout/enums/subMenuTabs.enum';
import { ProjectPlusSettingsTabsEnum } from '@pages/projectPlusModule/enums/projectPlusSettingsTabs.enum';

import { IConfigPopupNavigationSlice } from './configPopupNavigationSlice.interface';

const initialState: IConfigPopupNavigationSlice = {
  configPopupPopupStatus: false,
  configPopupMainNavigation: ProjectPlusSettingsTabsEnum.FILTERS,
  subMenuCurrentTab: ESubMenuTabs.TICKET_TYPES,
};

const configPopupNavigationSlice = createSlice({
  name: 'configPopupNavigationSlice',
  initialState,
  reducers: {
    openConfigPopup: (state: IConfigPopupNavigationSlice): void => {
      state.configPopupPopupStatus = true;
    },
    closeConfigPopup: (state: IConfigPopupNavigationSlice): void => {
      state.configPopupPopupStatus = false;
      state.configPopupMainNavigation = ProjectPlusSettingsTabsEnum.SETTINGS;
    },
    setConfigPopupMainNavigation: (
      state: IConfigPopupNavigationSlice,
      action: PayloadAction<ProjectPlusSettingsTabsEnum>,
    ): void => {
      state.configPopupMainNavigation = action.payload;
    },
    setSubMenuCurrentTab: (
      state: IConfigPopupNavigationSlice,
      action: PayloadAction<ESubMenuTabs>,
    ): void => {
      state.subMenuCurrentTab = action.payload;
    },
  },
});

export const {
  openConfigPopup,
  closeConfigPopup,
  setSubMenuCurrentTab,
  setConfigPopupMainNavigation,
} = configPopupNavigationSlice.actions;

export default configPopupNavigationSlice.reducer;
