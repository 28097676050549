import { useState } from 'react';

import { getItemById, replaceItem, isItemExist } from '@helpers/arrayUtils';
import { IColumPreference } from '@interfaces/calibration/storedTablePreference.interface';

import { IUseTableColumnsManagement } from './useTableColumnsManagement.interface';

export const useTableColumnsManagement = (): IUseTableColumnsManagement => {
  const [columnsList, setColumnsList] = useState<IColumPreference[] | null>(null);
  const [isDefaultColumnsList, setIsDefaultColumnsList] = useState(false);
  const visibleColumns = columnsList?.filter(column => column.visible);
  const hiddenColumns = columnsList?.filter(column => !column.visible);

  const updateColumnsList = (newColumnsList: IColumPreference[]): void => {
    // TODO: temp solution, need to support it on backend side
    setColumnsList(newColumnsList.filter((column) => column.id !== 'district'));
  };

  const updateColumnVisibleStatus = (id: string, visible: boolean): IColumPreference[] | null => {
    if (!columnsList) return null;
    const currentColumn = getItemById(columnsList, id);
    if (!currentColumn) return null;
    const updatedItem = { ...currentColumn, visible };
    const updatedColumnsList = replaceItem(columnsList, updatedItem);
    // TODO: temp solution, need to support it on backend side
    setColumnsList(updatedColumnsList.filter((column) => column.id !== 'district'));
    return updatedColumnsList;
  };

  const moveColumnToVisibleList = (id: string): IColumPreference[] | null => {
    return updateColumnVisibleStatus(id, true);
  };

  const moveColumnToHiddenList = (id: string, locked?: boolean): IColumPreference[] | null => {
    return updateColumnVisibleStatus(id, !!locked);
  };

  const updateIsDefaultColumnsList = (newIsDefaultColumnsList: boolean): void => {
    setIsDefaultColumnsList(newIsDefaultColumnsList);
  };

  const moveColumnsToVisibleList = (groupsList: IColumPreference[]): IColumPreference[] | null => {
    if (!columnsList) return null;
    const updatedColumnsList = columnsList.map((column) => {
      if (!isItemExist(groupsList, column)) return column;
      return {
        ...column,
        visible: true,
      };
    });
    // TODO: temp solution, need to support it on backend side
    setColumnsList(updatedColumnsList.filter((column) => column.id !== 'district'));
    return updatedColumnsList;
  };

  return ({
    values: {
      columnsList,
      isDefaultColumnsList,
      visibleColumns,
      hiddenColumns,
    },
    actions: {
      updateColumnsList,
      moveColumnToVisibleList,
      moveColumnToHiddenList,
      updateIsDefaultColumnsList,
      moveColumnsToVisibleList,
    },
  });
};
