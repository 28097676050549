import { createContext, FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';

import { IMaterialTypes } from '@interfaces/materialTypes.interface';
import { getItemById } from '@helpers/arrayUtils';
import {
  resetToDefaultMaterialsList,
} from '@pages/admin/pages/ticketTypeManagement/hooks/useTicketTypeManagementList/helpers/resetToDefaultMaterialsList.helper';

import { IMaterialTypesProviderContext } from './MaterialTypesProvider.interface';
import { useGetMaterialTypes } from './hooks/useGetMaterialTypes/useGetMaterialTypes';

export const MaterialTypesContext = createContext<IMaterialTypesProviderContext | undefined>(undefined);

const MaterialTypesProvider: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const [activeMaterials, setActiveMaterials] = useState<IMaterialTypes[]>([]);
  const {
    values,
    handlers,
  } = useGetMaterialTypes();
  const {
    materials,
  } = values;
  const {
    getMaterialTypes,
    setMaterials,
  } = handlers;

  const getHumanizeMaterialName = useCallback((id: string): string => {
    const correctElement = getItemById(materials, id);
    return correctElement ? correctElement.label : 'Unknown';
  }, [materials]);

  const resetMaterialsToDefaultLocally = useCallback((): void => {
    setMaterials(prevState => resetToDefaultMaterialsList(prevState));
  }, []);

  const changeMaterialTypeStateLocally = useCallback((changedMaterialId: string): void => {
    setMaterials((prevState) => {
      return prevState.map((material) => {
        if (material.id !== changedMaterialId) return material;
        return {
          ...material,
          active: !material.active,
        };
      });
    });
  }, []);

  useEffect(() => {
    setActiveMaterials(materials.filter(material => material.active));
  }, [materials]);

  const value = {
    materials,
    activeMaterials,
    getHumanizeMaterialName,
    resetMaterialsToDefaultLocally,
    changeMaterialTypeStateLocally,
    getMaterialTypes,
  };

  return (
    <MaterialTypesContext.Provider value={value}>
      {children}
    </MaterialTypesContext.Provider>
  );
};

export default MaterialTypesProvider;
