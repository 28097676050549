import React, { FC } from 'react';
import { Button } from '@mui/material';
import { useGlobalFlexStyles } from '@assets/globalStyles';

import { useStylesExperienceButton } from './experienceButton.theme';
import { IExperienceButton } from './experienceButton.interface';

const ExperienceButton: FC<IExperienceButton> = (props) => {
  const {
    title,
    description,
    isActive,
    experience,
    experienceButtonHandler,
    externalClasses = {},
  } = props;
  const { classes, cx } = useStylesExperienceButton();
  const { classes: flexClasses } = useGlobalFlexStyles();

  const onClickHandler = (): void => {
    experienceButtonHandler(experience);
  };

  return (
    <Button
      className={cx(
        flexClasses.flexColumnStartLeft,
        classes.wrapper,
        isActive && classes.active,
        externalClasses['button'] || null,
      )}
      onClick={onClickHandler}
      disabled={isActive}
    >
      <h3 className={classes.title}>
        {title}
      </h3>

      <p className={classes.description}>
        {description}
      </p>
    </Button>
  );
};

export default ExperienceButton;
