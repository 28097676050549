import { IOktaAuthState } from './oktaProvider.interface';

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <OktaProvider>.');
};

export const initialAuthState: IOktaAuthState = {
  isAuthenticated: false,
  isLoading: true,
};

export const initialContext = {
  ...initialAuthState,
  getAccessTokenSilently: stub,
  login: stub,
  logout: stub,
  sendResetPasswordEmail: stub,
  resetPasswordWithCode: stub,
  getTokenData: stub,
  loadSession: stub,
  logoutWithRedirectUri: stub,
  authenticateWithAuthorizationCode: stub,
};
