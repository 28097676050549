import { styled } from '@mui/material';
import { p, rem } from '@helpers/remUtils';

import { IAnimatedTableContainer } from './animatedTableContainer.interface';

const AnimatedTableContainer = styled('div', { shouldForwardProp: (prop) => prop !== 'open' })<IAnimatedTableContainer>(({ theme, open }) => ({
  flexGrow: 1,
  overflow: 'hidden',
  height: `calc(100vh - ${rem(180)})`,
  padding: p(11, 15),
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
 // marginLeft: leftOffset || `-${rem(311)}`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: 0,
  }),
}));

export { AnimatedTableContainer };
