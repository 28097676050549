import { RootStateType } from '@store/interfaces/rootState.type';
import { reformatDate } from '@helpers/dateFormatter';

import { IGetProjectPlusRankingRequestParams } from '../getProjectPlusRankingRequestParams.interface';

export const generateRequestParams = (
  state: RootStateType,
  params?: IGetProjectPlusRankingRequestParams,
): IGetProjectPlusRankingRequestParams => {
  const pageSize = 100;
  if (params) {
    const { useReformatDate = true, ...otherParams } = params;
    return ({
      ...otherParams,
      fromDate: useReformatDate ? reformatDate(otherParams.fromDate) : otherParams.fromDate,
      pageSize,
    });
  }

  const { rankingSliceReducer } = state;
  const {
    dateInterval,
    tableSorting,
  } = rankingSliceReducer;
  const { from, interval } = dateInterval;

  return ({
    fromDate: reformatDate(from),
    interval,
    pageSize,
    sort: tableSorting.map(item => `${item.column},${item.direction.toLowerCase()}`),
  });
};
