import React, { FC, ReactElement } from 'react';
import { Outlet } from 'react-router';

const AuthenticateLoginChecker: FC = (): ReactElement => {
  return (
    <Outlet />
  );
};

export default AuthenticateLoginChecker;
