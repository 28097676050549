import { createAsyncThunk } from '@reduxjs/toolkit';

import { IGeneralOptions } from '@store/projectPlus/optionsPopup/interfaces/generalOptions.interface';
import api from '@configs/urls/api';

import instance from '../instanceDefiner';

export const getGeneralOptionsProjectPlus = createAsyncThunk<IGeneralOptions | null>(
  'projectPlusOptions/getGeneralOptionsProjectPlus',
  async () => {
    try {
      const response = await instance.get(api.getGeneralSettingsProjectPlus);
      return response.data;
    } catch {
      return null;
    }
  },
);
