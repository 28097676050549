import { useContext } from 'react';

import { FeatureFlagsContext } from './featureFlagsProvider';
import { IFeatureFlagsContext } from './featureFlagsProvider.interface';

const useFeatureFlags = (): IFeatureFlagsContext => {
  const context = useContext(FeatureFlagsContext);

  if (context === undefined) {
    throw new Error('useFeatureFlags must be used within a FeatureFlagsProvider');
  }

  return context;
};

export default useFeatureFlags;
