import { Box, styled } from '@mui/material';

import { rem } from '@helpers/remUtils';

export const DefaultModalContentWrapper = styled(Box)(({ theme }) => ({
  minHeight: rem(450),
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: rem(720),
  background: theme.palette.background.paper,
  boxShadow: '0px 2.7px 9px rgba(0, 0, 0, 0.09)',
  borderRadius: rem(10),
}));
