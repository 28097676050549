
export const confirmationPopupContainer = 'confirmationPopupContainer';
export const cancelButtonTestId = 'cancelButtonTestId';
export const accessButtonTestId = 'accessButtonTestId';

export const confirmPopupText = {
  CANCEL_BUTTON: 'cancel',
  ACCESS_BUTTON: 'ok',
  DEFAULT_TITLE_TEXT: 'title',
};
