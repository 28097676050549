import { Active, Over } from '@dnd-kit/core/dist/store';

import { arrayCutter, removeItem } from '@helpers/arrayUtils';
import {
  IUseColumnManagementLists,
} from '@pages/admin/pages/calibration/hooks/useColumnManagementList/useColumnManagementListStates.interface';
import { IColumPreference } from '@interfaces/calibration/storedTablePreference.interface';

import { findContainer } from './findContainer';

export const handleDragOverHandler = (
  active: Active,
  over: Over,
  items: IUseColumnManagementLists,
): IUseColumnManagementLists | null => {
  const { id } = active;
  const { id: overId } = over;
  const activeContainer = findContainer(items, id);
  const overContainer = findContainer(items, overId);

  if (!activeContainer || !overContainer || activeContainer === overContainer) return null;

  const activeContainerKey = activeContainer as keyof IUseColumnManagementLists;
  const overContainerKey = overContainer as keyof IUseColumnManagementLists;
  const activeItems = items[activeContainerKey];
  const overItems = items[overContainerKey];
  const activeIndex = activeItems.findIndex(item => item.id == id);
  const overIndex = overItems.findIndex(item => item.id == overId);
  const currentItem = items[activeContainerKey][activeIndex];
  const updatedItem = { ...currentItem, visible: !currentItem.visible };
  let newIndex;

  if (overId in items) {
    newIndex = overItems.length + 1;
  } else {
    const isBelowLastItem =
      over &&
      overIndex === overItems.length - 1 &&
      active.rect.current.translated &&
      active.rect.current.translated.top > over.rect.top + over.rect.height;

    const modifier = isBelowLastItem ? 1 : 0;

    newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
  }

  return {
    ...items,
    [activeContainer]: [
      ...removeItem<IColumPreference>(items[activeContainerKey], id as string),
    ],
    [overContainer]: [
      ...arrayCutter(items[overContainerKey], newIndex),
      updatedItem,
      ...arrayCutter(items[overContainerKey], items[overContainerKey].length, newIndex),
    ],
  };
};
