export const checkHeldKeysRecursive = (
  shortcutKey: string,
  shortcutArray: string[],
  heldKeysArray: string[],
  shortcutKeyRecursionIndex = 0,
): boolean => {
  const shortcutIndexOfKey = shortcutArray.indexOf(shortcutKey);
  const keyPartOfShortCut = shortcutArray.indexOf(shortcutKey) >= 0;

  if (!keyPartOfShortCut) return false;

  const comparisonIndex = Math.max(heldKeysArray.length - 1, 0);
  if (heldKeysArray.length && heldKeysArray[comparisonIndex] !== shortcutArray[comparisonIndex]) return false;

  if (shortcutIndexOfKey === 0) {
    if (shortcutKeyRecursionIndex > 0)
      return heldKeysArray.indexOf(shortcutKey) >= 0;
    return true;
  }

  const previousShortcutKeyIndex = shortcutIndexOfKey - 1;
  const previousShortcutKey = shortcutArray[previousShortcutKeyIndex];
  const previousShortcutKeyHeld = heldKeysArray[previousShortcutKeyIndex] === previousShortcutKey;

  if (!previousShortcutKeyHeld) return false;

  return checkHeldKeysRecursive(
    previousShortcutKey,
    shortcutArray,
    heldKeysArray,
    shortcutIndexOfKey,
  );
};
