import { useContext } from 'react';

import { IMaterialTypesProviderContext } from './MaterialTypesProvider.interface';
import { MaterialTypesContext } from './MaterialTypesProvider';

const useMaterialTypesProvider = (): IMaterialTypesProviderContext => {
  const context = useContext(MaterialTypesContext);

  if (context === undefined) {
    throw new Error('useMaterialTypesProvider must be used within a MaterialTypesProvider');
  }

  return context;
};

export default useMaterialTypesProvider;
