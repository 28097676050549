import common from './common.json';
import login from './login.json';
import formElements from './formElements.json';
import forgotPassword from './forgotPaasword.json';
import forgotPasswordInfo from './forgotPasswordInfo.json';
import widgetWizard from './widgetWizard.json';
import date from './date.json';
import informationMessages from './informationMessages.json';
import navigation from './navigation.json';
import experienceSwitcher from './experienceSwitcher.json';
import roles from './roles.json';
import watchlists from './watchlists.json';
import entities from './entities.json';
import notification from './notification.json';
import adminPage from './admin/adminPage.json';
import plantHealthPage from './admin/plantHealthPage.json';
import ticketTypeManagementPage from './admin/ticketTypeManagementPage.json';
import settingsSidebarNavigation from './settingsSidebarNavigation.json';
import personalInformation from './personalInformation.json';
import widgetPopups from './widgetPopups.json';
import dashboard from './dashboard.json';
import invitation from './invitation.json';
import details from './details.json';
import ticketTypeClassification from './ticketTypeClassification.json';
import projectPlus from './projectPlus.json';
import live from './live.json';
import shippingLog from './shippingLog.json';
import rankings from './rankings.json';
import truckLogPage from './truckLogPage.json';
import projectPlusModule from './projectPlusModule.json';
import sharing from './sharing.json';
import superAdmin from './superAdmin/superAdmin.json';
import epd from './admin/epdPage.json';
import eTicketCustomers from './admin/eTicketCustomers.json';
import bid from './bid.json';
import calibration from './admin/calibrationPage.json';
import ticketMonitoringPage from './admin/ticketMonitoringPage.json';
import tickets from './tickets.json';

export default {
  common,
  login,
  formElements,
  forgotPassword,
  forgotPasswordInfo,
  widgetWizard,
  entities,
  date,
  informationMessages,
  navigation,
  experienceSwitcher,
  roles,
  watchlists,
  notification,
  settingsSidebarNavigation,
  personalInformation,
  widgetPopups,
  dashboard,
  invitation,
  details,
  ticketTypeClassification,
  live,
  shippingLog,
  projectPlusModule,
  sharing,
  bid,

  // no translates
  epd,
  eTicketCustomers,
  calibration,
  tickets,
  ticketMonitoringPage,

  // TODO: check duplicates
  rankings,
  adminPage,
  truckLogPage,
  plantHealthPage,
  ticketTypeManagementPage,
  projectPlus,

  // Super Admin
  superAdmin,
};
