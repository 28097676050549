import { FC, PropsWithChildren, useEffect, useState } from 'react';
import { configure } from 'axios-hooks';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useOkta } from '@features/oktaClient';
import { AxiosHeaders } from 'axios';

import useErrorHandler from '@providers/errorHandlerProvider/useErrorHandler';
import texts from '@helpers/notificationsMessages/texts';
import { NotificationTypeEnum } from '@helpers/notificationsMessages';
import { appClientPlatform, appClientVersion } from '@configs/version';

import { axiosInstance } from './axiosInstance';

const OktaAxiosClient: FC<PropsWithChildren> = ({ children }) => {
  const { getTokenData, getAccessTokenSilently, logout } = useOkta();
  const { enqueueSnackbar } = useSnackbar();
  const [clientInit, setClientInit] = useState<boolean>(false);
  const {
    setErrorStatusCode,
    setErrorText,
  } = useErrorHandler();
  const { t } = useTranslation('informationMessages');
  let refreshingToken: Promise<string | null> | null;

  useEffect(() => {
    axiosInstance.interceptors.request.use((config) => {
      const token = getTokenData();
      const timeZone = localStorage.getItem('timeZoneId') || Intl.DateTimeFormat().resolvedOptions().timeZone;
      const interceptorConfig = {
        ...config,
        headers: new AxiosHeaders({
          'Authorization': `Bearer ${process.env.REACT_APP_E2E_TESTS ? 'no-token' : token}`,
          'Time-Zone': timeZone,
          'ClientVersion': appClientVersion,
          'ClientPlatform': appClientPlatform,
        }),
      };

      return interceptorConfig;
    });

    axiosInstance.interceptors.response.use(
      response => response,
      async (error) => {
        const { config } = error;
        if (error?.response?.data?.type === 'MATERIAL_PRODUCER_NOT_ACTIVE') {
          localStorage.clear();
        }

        if (error.response && error.response.status === 401 && !config._retry) {
          config._retry = true;
          try {
            refreshingToken = refreshingToken || getAccessTokenSilently();
            await refreshingToken;
            refreshingToken = null;
            return await axiosInstance(config);
          } catch (err) {
            return Promise.reject(err);
          }
        }

        if (error.response && error.response.status === 406) {
          logout();
        }

        if (error.response) {
          let errorMessage = error?.response?.data?.error;
          const isRubiApiWithoutCustomErrorMessage = !error?.response?.data?.error && !error.config.baseURL.includes('supplierci');
          if (error.response.status === 403 || isRubiApiWithoutCustomErrorMessage) {
            errorMessage = t(texts.commonError);
          }
          if (error.response.status === 401) {
            errorMessage = t(texts.userNotExist);
          }
          setErrorStatusCode(error.response?.status);
          setErrorText(errorMessage);
          enqueueSnackbar(
            errorMessage,
            {
              variant: NotificationTypeEnum.error,
              preventDuplicate: error.response.status === 401,
            },
          );
        }

        return Promise.reject(error);
      },
    );

    setClientInit(true);
    configure({ axios: axiosInstance, cache: false });
  }, []);

  return (
    <>
      {clientInit ? children : null}
    </>
  );
};

export default OktaAxiosClient;
