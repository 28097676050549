import { EToasterVariant } from '@enums/toasterVariant.enum';
import { ReactComponent as SuccessIcon } from '@assets/images/toasterSuccessIcon.svg';
import { ReactComponent as ErrorIcon } from '@assets/images/toasterErrorIcon.svg';

export const baseToasterConfig = {
  [EToasterVariant.SUCCESS]: {
    className: 'success',
    IconComponent: SuccessIcon,
  },
  [EToasterVariant.ERROR]: {
    className: 'error',
    IconComponent: ErrorIcon,
  },
};
