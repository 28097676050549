import { ListItem, styled } from '@mui/material';

import { p, rem } from '@helpers/remUtils';

export const EntityListItemWrapper = styled(ListItem)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: p(5, 20, 5, 0),
  borderBottom: `${rem(1)} solid ${theme.palette.additionalColors.divider.main}`,
  '&:first-of-type': {
    borderTop: `${rem(1)} solid ${theme.palette.additionalColors.divider.main}`,
  },

  '.label-wrapper': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },

  '.label': {
    maxWidth: rem(260),
    margin: 0,
    fontSize: rem(13),
    fontWeight: 500,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },

  '.selected-status-icon-button': {
    margin: 0,
    padding: 0,
    outline: 'none',
    border: 'none',
    background: 'transparent',
    cursor: 'pointer',
  },

  '.selected-status-icon': {
    width: rem(15),
    height: rem(15),
  },
}));
