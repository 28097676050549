import { rem } from '@helpers/remUtils';
import { makeStyles } from '@theme/appTheme';

const useStylesNoInfo = makeStyles()((theme) => ({
  noInfo: {
    fontStyle: 'italic',
    fontSize: rem(12),
    color: theme.palette.text.hint,
  },
}));

export default useStylesNoInfo;
