export enum EventBusTypes {
  OPEN_MANAGE_POPUP = 'OPEN_MANAGE_POPUP',
  SHARE_CHART_VALUES = 'SHARE_CHART_VALUES',
  WIDGET_TILE_NAME_CHANGE = 'WIDGET_TILE_NAME_CHANGE',
  UPDATE_ENTITY_DETAILS_LIST = 'UPDATE_ENTITY_DETAILS_LIST',
  REMOVE_FROM_WATCHLIST = 'REMOVE_FROM_WATCHLIST',
  UPDATE_ENTITY_WATCHLIST_STATUS = 'UPDATE_ENTITY_WATCHLIST_STATUS',
  ROUNDS_MANIPULATION = 'ROUNDS_MANIPULATION',
  UPDATE_DATA_IN_PULSE_BLOCK = 'UPDATE_DATA_IN_PULSE_BLOCK',
  TICKET_TYPE_NOTIFICATION = 'TICKET_TYPE_NOTIFICATION',
  MATERIAL_PRODUCER_NOTIFICATION = 'MATERIAL_PRODUCER_NOTIFICATION',
  USER_INFO_NOTIFICATION = 'USER_INFO_NOTIFICATION',
  GENERAL_SETTINGS_NOTIFICATION = 'GENERAL_SETTINGS_NOTIFICATION',
}
