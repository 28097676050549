import { styled } from '@mui/material';
import { rem } from '@helpers/remUtils';

interface ITimePickerDayPeriodButtonWrapper {
  isActive: boolean;
}

export const TimePickerDayPeriodButtonWrapper = styled('button', {
  shouldForwardProp: (prop) => prop !== 'isActive',
})<ITimePickerDayPeriodButtonWrapper>(({ theme, isActive }) => ({
  flexGrow: 1,
  margin: 0,
  padding: 0,
  width: rem(35),
  outline: 'none',
  border: 'none',
  background: isActive ? theme.palette.primary.main : theme.palette.additionalColors.background.eighth,
  fontSize: rem(11),
  fontWeight: 500,
  color: isActive ? theme.palette.text.hint : theme.palette.text.secondary,
  cursor: 'pointer',
}));
