import {
  addDays,
  addQuarters,
  addWeeks,
  addYears,
  endOfDay,
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  format,
  getMonth,
  setMonth,
  startOfDay,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subDays,
  subQuarters,
  subWeeks,
  subYears,
} from 'date-fns';
import { CurrentPreviousDataSwitcherEnum } from '@enums/currentPreviousDataSwitcher.enum';

const today = new Date();

export const generateDay = (currentDate: string, counter: CurrentPreviousDataSwitcherEnum): Date => {
  let date = new Date(currentDate);
  date = counter === CurrentPreviousDataSwitcherEnum.CURRENT ? addDays(date, 1) : subDays(date, 1);
  return date;
};

export const generateWeek = (currentDate: string, counter: CurrentPreviousDataSwitcherEnum): Date => {
  let date = new Date(currentDate);
  date = counter === CurrentPreviousDataSwitcherEnum.CURRENT ? addWeeks(date, 1) : subWeeks(date, 1);
  return date;
};

export const generateMoth = (currentDate: string, counter: CurrentPreviousDataSwitcherEnum): Date => {
  let date = new Date(currentDate);
  const counterValue = counter === CurrentPreviousDataSwitcherEnum.CURRENT
    ? getMonth(date) + 1
    : getMonth(date) - 1;
  date = setMonth(date, counterValue);
  return date;
};

export const generateQuarter = (currentDate: string, counter: CurrentPreviousDataSwitcherEnum): Date => {
  let date = new Date(currentDate);
  date = counter === CurrentPreviousDataSwitcherEnum.CURRENT ? addQuarters(date, 1) : subQuarters(date, 1);
  return date;
};

export const generateYear = (currentDate: string, counter: CurrentPreviousDataSwitcherEnum): Date => {
  let date = new Date(currentDate);
  date = counter === CurrentPreviousDataSwitcherEnum.CURRENT ? addYears(date, 1) : subYears(date, 1);
  return date;
};

export const generateStartOfCurrentDay = (currentDate?: string, counter?: CurrentPreviousDataSwitcherEnum): string => {
  if (currentDate && counter) {
    const date = generateDay(currentDate, counter);
    return format(startOfDay(date), 'yyyy-MM-dd\'T\'HH:mm:ss');
  }

  return format(startOfDay(today), 'yyyy-MM-dd\'T\'HH:mm:ss');
};

export const generateEndOfCurrentDay = (currentDate?: string, counter?: CurrentPreviousDataSwitcherEnum): string => {
  if (currentDate && counter) {
    const date = generateDay(currentDate, counter);
    return format(endOfDay(date), 'yyyy-MM-dd\'T\'HH:mm:ss');
  }

  return format(endOfDay(today), 'yyyy-MM-dd\'T\'HH:mm:ss');
};

export const generateStartOfWeekDay = (currentDate?: string, counter?: CurrentPreviousDataSwitcherEnum): string => {
  if (currentDate && counter) {
    const date = generateWeek(currentDate, counter);

    return format(startOfWeek(date), 'yyyy-MM-dd\'T\'HH:mm:ss');
  }

  return format(startOfWeek(today), 'yyyy-MM-dd\'T\'HH:mm:ss');
};

export const generateEndOfWeekDay = (currentDate?: string, counter?: CurrentPreviousDataSwitcherEnum): string => {
  if (currentDate && counter) {
    const date = generateWeek(currentDate, counter);

    return format(endOfWeek(date), 'yyyy-MM-dd\'T\'HH:mm:ss');
  }

  return format(endOfWeek(today), 'yyyy-MM-dd\'T\'HH:mm:ss');
};

export const generateStartOfMonthDay = (currentDate?: string, counter?: CurrentPreviousDataSwitcherEnum): string => {
  if (currentDate && counter) {
    const date = generateMoth(currentDate, counter);
    return format(startOfMonth(date), 'yyyy-MM-dd\'T\'HH:mm:ss');
  }

  return format(startOfMonth(today), 'yyyy-MM-dd\'T\'HH:mm:ss');
};

export const generateEndOfMonthDay = (currentDate?: string, counter?: CurrentPreviousDataSwitcherEnum): string => {
  if (currentDate && counter) {
    const date = generateMoth(currentDate, counter);
    return format(endOfMonth(date), 'yyyy-MM-dd\'T\'HH:mm:ss');
  }

  return format(endOfMonth(today), 'yyyy-MM-dd\'T\'H:mm:ss');
};

export const generateStartOfQuarterDay = (currentDate?: string, counter?: CurrentPreviousDataSwitcherEnum): string => {
  if (currentDate && counter) {
    const date = generateQuarter(currentDate, counter);
    return format(startOfQuarter(date), 'yyyy-MM-dd\'T\'HH:mm:ss');
  }

  return format(startOfQuarter(today), 'yyyy-MM-dd\'T\'HH:mm:ss');
};

export const generateEndOfQuarterDay = (currentDate?: string, counter?: CurrentPreviousDataSwitcherEnum): string => {
  if (currentDate && counter) {
    const date = generateQuarter(currentDate, counter);
    return format(endOfQuarter(date), 'yyyy-MM-dd\'T\'HH:mm:ss');
  }

  return format(endOfQuarter(today), 'yyyy-MM-dd\'T\'HH:mm:ss');
};

export const generateStartOfYearDay = (currentDate?: string, counter?: CurrentPreviousDataSwitcherEnum): string => {
  if (currentDate && counter) {
    const date = generateYear(currentDate, counter);
    return format(startOfYear(date), 'yyyy-MM-dd\'T\'HH:mm:ss');
  }

  return format(startOfYear(today), 'yyyy-MM-dd\'T\'HH:mm:ss');
};

export const generateEndOfYearDay = (currentDate?: string, counter?: CurrentPreviousDataSwitcherEnum): string => {
  if (currentDate && counter) {
    const date = generateYear(currentDate, counter);
    return format(endOfYear(date), 'yyyy-MM-dd\'T\'HH:mm:ss');
  }

  return format(endOfYear(today), 'yyyy-MM-dd\'T\'HH:mm:ss');
};

export const reformatDate = (value: string, formatPattern = 'yyyy-MM-dd'): string => {
 return format(new Date(value), formatPattern);
};

export const currentDate = (): string => {
  return format(new Date(), 'yyyy-MM-dd');
};
