import React, { FC, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import { SnackbarProvider } from 'notistack';

import { ErrorHandlerProvider } from '@providers/errorHandlerProvider/errorHandlerProvider';
import RedirectionToMobileApplication from '@components/redirectionToMobileApplication/RedirectionToMobileApplication';
import GlobalSearchProvider from '@features/globalSearch/providers/globalSearch/globalSearchProvider';
import { useGlobalContainerStyles } from '@assets/globalStyles';
import appTheme from '@theme/appTheme';
import { SuccessToaster, ErrorToaster } from '@components/notistackToasters';

import SuspenseLoading from './components/suspenseLoading';
import NoOpsAuthorizationChecker from './NoOpsAuthorizationChecker';
import InitialOktaApp from './okta/intialOktaApp/InitialOktaApp';
import OktaUnAuthorizationClient from './okta/oktaUnAuthorizationClient/OktaUnAuthorizationClient';

const FeaturesRoutingModule = React.lazy(() => import('./pages/FeaturesRoutingModule'));
const NotFound = React.lazy(() => import('./pages/notFound'));

export const muiCache = createCache({
  key: 'mui',
  prepend: true,
});

const App: FC = () => {
  const { classes: containerClasses } = useGlobalContainerStyles();

  localStorage.setItem('timeZoneId', Intl.DateTimeFormat().resolvedOptions().timeZone);

  return (
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={appTheme}>
        <RedirectionToMobileApplication>
          <ErrorHandlerProvider>
            <SnackbarProvider
              preventDuplicate={true}
              classes={{
                containerRoot: containerClasses.containerRoot,
              }}
              Components={{
                success: SuccessToaster,
                error: ErrorToaster,
              }}
              maxSnack={10}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <CssBaseline />
              <Suspense fallback={<SuspenseLoading showLogo={true} />}>
                {process.env.REACT_APP_E2E_TESTS
                  ? <Routes>
                      <Route
                        path="*"
                        element={
                          <OktaUnAuthorizationClient>
                            <NoOpsAuthorizationChecker>
                              <GlobalSearchProvider>
                                <FeaturesRoutingModule />
                              </GlobalSearchProvider>
                            </NoOpsAuthorizationChecker>
                          </OktaUnAuthorizationClient>
                        }
                      />

                      <Route
                        path="/not-found"
                        element={<NotFound />}
                      />
                    </Routes>
                  : <InitialOktaApp />
                }
              </Suspense>
            </SnackbarProvider>
          </ErrorHandlerProvider>
        </RedirectionToMobileApplication>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default App;
