import { FC } from 'react';
import { Skeleton } from '@mui/material';

import { ICustomSkeletonProps } from './customSkeletonProps.interface';
import { skeleton } from './customSkeleton.const';

const CustomSkeleton: FC<ICustomSkeletonProps> = props => {
  const {
    externalClassName,
    lines = 1,
    lineHeight = '100px',
    variant = 'text',
    width = '100%',
    component = 'li',
  } = props;

  return (
    <>
      {Array.from({ length: lines }, (v, i) => (
        <Skeleton
          key={i}
          className={externalClassName}
          variant={variant}
          component={component}
          animation="wave"
          height={lineHeight}
          width={width}
          role={skeleton}
        />
      ))}
    </>
  );
};

export { CustomSkeleton };
