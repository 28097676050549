import { FC } from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import MemoizedButton from '../memoizedButton/MemoizedButton';
import { TabsList } from '../tabsList';
import { IFlyoutPopupProps } from './flyoutPopup.interface';
import { FlyoutPopupWrapper } from './flyoutPopup.theme';
import {
  closeFlyoutPopupButtonTestId,
  flyoutPopupTexts,
  resetFlyoutPopupButtonTestId,
  saveFlyoutPopupButtonTestId,
} from './flyoutPopup.const';

const FlyoutPopup: FC<IFlyoutPopupProps> = (props) => {
  const {
    title,
    activeTab,
    tabsListConfig,
    isResetButtonDisabled,
    isSaveButtonDisabled,
    options,
    children,
    closePopupHandler,
    onTabChangeHandler,
    onResetButtonClick,
    onSaveButtonClick,
  } = props;
  const {
    resetButtonLabel,
    saveButtonLabel,
  } = options || {};

  return (
    <FlyoutPopupWrapper>
      <header className="flyout-popup-header">
        <div className="title-wrapper">
          <IconButton
            className="close-popup-icon-button"
            size="small"
            color="secondary"
            data-testid={closeFlyoutPopupButtonTestId}
            onClick={closePopupHandler}
          >
            <CloseIcon fontSize="small" />
          </IconButton>

          <h3 className="title">
            {title}
          </h3>
        </div>

        <TabsList
          activeTab={activeTab}
          tabsListConfig={tabsListConfig}
          onTabChangeHandler={onTabChangeHandler}
        />
      </header>

      <div className="flyout-popup-content">
        {children}
      </div>

      <footer className="flyout-popup-footer">
        <MemoizedButton
          variant="text"
          color="secondary"
          data-testid={resetFlyoutPopupButtonTestId}
          disabled={isResetButtonDisabled}
          onClick={onResetButtonClick}
        >
          {resetButtonLabel || flyoutPopupTexts.RESET_BUTTON_LABEL}
        </MemoizedButton>

        <MemoizedButton
          variant="text"
          color="primary"
          data-testid={saveFlyoutPopupButtonTestId}
          disabled={isSaveButtonDisabled}
          onClick={onSaveButtonClick}
        >
          {saveButtonLabel || flyoutPopupTexts.SAVE_BUTTON_LABEL}
        </MemoizedButton>
      </footer>
    </FlyoutPopupWrapper>
  );
};

export { FlyoutPopup };
