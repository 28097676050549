import { DeviceType } from '../enums/device.type';

export const detectDevice = (): DeviceType => {
  const { userAgent } = navigator;
  const isAndroid = /android/i.test(userAgent);
  const isOldIOS = /iPad|iPhone|iPod/.test(userAgent);
  const isIOS = navigator.userAgent.includes('Mac') && navigator.maxTouchPoints > 1;

  if (isAndroid) return DeviceType.ANDROID;

  if (isOldIOS || isIOS) return DeviceType.IOS;

  return DeviceType.PC;
};
