import { FC, PropsWithChildren } from 'react';
import { withAuthenticationRequired } from '@features/oktaClient';
import { Navigate } from 'react-router';

import { ConfirmationProvider } from '@features/confirmationPopup/providers/confirmationProvider/ConfirmationProvider';
import { FeatureFlagsProvider } from '@providers/featureFlagsProvider/featureFlagsProvider';
import SuspenseLoading from '@components/suspenseLoading';
import {
  EntityManagementProvider,
} from '@features/recentlyViewed/providers/entityManagementProvider/entityManagementProvider';
import { PushNotificationsProvider } from '@features/sharedWidgets/pushNotificationsProvider/pushNotificationsProvider';
import ReduxWrapperProvider from '@store/reduxProviderWrapper/ReduxWrapperProvider';
import { TenantProvider } from '@providers/tenantProvider/TenantProvider';
import MaterialTypesProvider from '@providers/materialTypesProvider/MaterialTypesProvider';
import GlobalSearchProvider from '@features/globalSearch/providers/globalSearch/globalSearchProvider';
import NetworkChecker from '@components/networkChecker';
import { RefreshPageWarningProvider } from '@providers/refreshPageWarningProvider/RefreshPageWarningProvider';
import InitAppChecker from '@components/initAppChecker/InitAppChecker';
import { DownloadTicketsProvider } from '@providers/downloadTicketsProvider/DownloadTicketsProvider';
import { ManageModalsProvider } from '@features/haulhubUi';

import OktaAxiosClient from '../OktaAxiosClient';

const OktaAuthorizationChecker: FC<PropsWithChildren> = ({ children }) => {
  return (
    <OktaAxiosClient>
      <ReduxWrapperProvider>
        <ConfirmationProvider>
          <FeatureFlagsProvider>
            <TenantProvider>
              <MaterialTypesProvider>
                <InitAppChecker>
                  <PushNotificationsProvider>
                    <EntityManagementProvider>
                      <GlobalSearchProvider>
                        <RefreshPageWarningProvider>
                          <NetworkChecker>
                            <ManageModalsProvider>
                              <DownloadTicketsProvider>
                                {children}
                              </DownloadTicketsProvider>
                            </ManageModalsProvider>
                          </NetworkChecker>
                        </RefreshPageWarningProvider>
                      </GlobalSearchProvider>
                    </EntityManagementProvider>
                  </PushNotificationsProvider>
                </InitAppChecker>
              </MaterialTypesProvider>
            </TenantProvider>
          </FeatureFlagsProvider>
        </ConfirmationProvider>
      </ReduxWrapperProvider>
    </OktaAxiosClient>
  );
};

export default withAuthenticationRequired(
  OktaAuthorizationChecker,
  Navigate,
  () => <SuspenseLoading showLogo={true} />,
);
