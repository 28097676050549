import { useCallback, useEffect, useMemo, useRef } from 'react';

import { IUseKeyboardShortcutProps } from './interfaces/useKeyboardShortcutProps.interface';
import { IUseKeyboardShortcut } from './interfaces/useKeyboardShortcut.interface';
import { BLACKLISTED_DOM_TARGETS, DEFAULT_OPTIONS } from './useKayboardShortcut.const';
import { checkHeldKeysRecursive, overrideSystemHandling } from './helpers';

const useKeyboardShortcut = (props: IUseKeyboardShortcutProps): IUseKeyboardShortcut => {
  const { shortcutKeys, callback, options: customOptions } = props;
  const options = { ...DEFAULT_OPTIONS, ...customOptions };
  const heldKeys = useRef<string[]>([]);

  const shortcutKeysId = useMemo(() => shortcutKeys.join(), [shortcutKeys]);

  const shortcutArray = useMemo(
    () => shortcutKeys.map((key) => String(key).toLowerCase()),
    [shortcutKeysId],
  );

  const keydownListener = useCallback((keydownEvent: any) => {
      const loweredKey = String(keydownEvent.key).toLowerCase();
      if (!(shortcutArray.indexOf(loweredKey) >= 0)) return;

      if (options.ignoreInputFields && BLACKLISTED_DOM_TARGETS.indexOf(keydownEvent.target?.tagName) >= 0) return;

      if (keydownEvent.repeat && !options.repeatOnHold) return;

      if (options.overrideSystem) {
        overrideSystemHandling(keydownEvent);
      }

      const isHeldKeyCombinationValid = checkHeldKeysRecursive(
        loweredKey,
        shortcutArray,
        heldKeys.current,
      );

      if (!isHeldKeyCombinationValid) {
        return;
      }

      const nextHeldKeys = [...heldKeys.current, loweredKey];
      if (nextHeldKeys.join() === shortcutArray.join()) {
        callback(shortcutKeys);
        return false;
      }

      heldKeys.current = nextHeldKeys;

      return false;
    },
    [
      shortcutKeysId,
      callback,
      options.overrideSystem,
      options.ignoreInputFields,
    ],
  );

  const keyupListener = useCallback((keyupEvent: any) => {
      const raisedKey = String(keyupEvent.key).toLowerCase();
      if (!(shortcutArray.indexOf(raisedKey) >= 0)) return;

      const raisedKeyHeldIndex = heldKeys.current.indexOf(raisedKey);
      if (!(raisedKeyHeldIndex >= 0)) return;

      const nextHeldKeys = [];
      let loopIndex;
      for (loopIndex = 0; loopIndex < heldKeys.current.length; ++loopIndex) {
        if (loopIndex !== raisedKeyHeldIndex) {
          nextHeldKeys.push(heldKeys.current[loopIndex]);
        }
      }
      heldKeys.current = nextHeldKeys;

      return false;
    },
    [shortcutKeysId],
  );

  const flushHeldKeys = useCallback(() => {
    heldKeys.current = [];
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', keydownListener);
    window.addEventListener('keyup', keyupListener);
    return () => {
      window.removeEventListener('keydown', keydownListener);
      window.removeEventListener('keyup', keyupListener);
    };
  }, [
    keydownListener,
    keyupListener,
    shortcutKeysId,
  ]);

  useEffect(() => {
    flushHeldKeys();
  }, [
    shortcutKeysId,
    flushHeldKeys,
  ]);

  return {
    flushHeldKeys,
  };
};

export { useKeyboardShortcut };
