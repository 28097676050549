import eventBus from '@eventBus/index';

import { EventBusTypes } from '../../enums/eventBusTypes.enum';
import { EventBusCallbackType } from '../../interfaces/callback.interface';
import { EmptyFunction } from '../../interfaces/emptyFunction.interface';
import { IRemoveFromWatchlistProps } from './removeFromWatchlistProps.interface';

const eventName = EventBusTypes.REMOVE_FROM_WATCHLIST;

const subscribe = (
  callback: EventBusCallbackType<IRemoveFromWatchlistProps>
): EmptyFunction => {
  return eventBus.subscribe<IRemoveFromWatchlistProps>(eventName, callback);
};

const broadcast = (data: IRemoveFromWatchlistProps): void => {
  eventBus.broadcast<IRemoveFromWatchlistProps>(eventName, data);
};

export default {
  subscribe,
  broadcast,
};
