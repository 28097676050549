import { useContext } from 'react';

import { IErrorHandlerContext } from '@providers/errorHandlerProvider/errorHandlerContext.types';
import { ErrorHandlerContext } from '@providers/errorHandlerProvider/errorHandlerProvider';

const useErrorHandler = (): IErrorHandlerContext => {
  const context = useContext(ErrorHandlerContext);

  if (context === undefined) {
    throw new Error('useErrorHandler must be used withing a ErrorHandlerProvider');
  }

  return context;
};

export default useErrorHandler;
