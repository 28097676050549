import { m, rem } from '@helpers/remUtils';
import { makeStyles } from '@theme/appTheme';

const useStylesNoInternetMessage = makeStyles()((theme) => ({
  noInternetMessage: {
    padding: rem(10),
    borderRadius: rem(10),
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: m(0, 10, 0, 10),
    fontSize: rem(20),
  },
  closePopupButton: {
    width: rem(12),
    height: rem(12),
    background: theme.palette.additionalColors.buttonIconBackgroundColor.main,
    color: theme.palette.background.default,
    '&:hover': {
      background: theme.palette.additionalColors.buttonIconBackgroundColor.main,
    },
  },
  closePopupButtonIcon: {
    width: rem(12),
    height: rem(12),
  },
}));

export default useStylesNoInternetMessage;
