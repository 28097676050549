import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { isToday } from 'date-fns';

import { getHistoricalDayStatistics } from '@store/thunk/projectPlusHistorical/getHistoricalDayStatistics';
import { IHistoricalDayStatisticsDto } from '@interfaces/projectPlusHistorical/historicalDayStatisticsDto.interface';
import {
  getFullDate,
  getInitFromDate,
  getInitToDate,
} from '@pages/projectPlusModule/pages/live/components/historical/helpers/historicalDateUtils';

import { IProjectPlusHistoricalSlice } from './projectPlusHistoricalSlice.interface';

const initHistoricalDate = getFullDate(new Date());
const initFromDate = getInitFromDate();
const initToDate = getInitToDate();

const initialState: IProjectPlusHistoricalSlice = {
  historicalDate: initHistoricalDate,
  error: false,
  loading: false,
  isCurrentDate: true,
  dayStatistics: null,
  monthFromDate: initFromDate,
  monthToDate: initToDate,
};

export const projectPlusHistoricalSlice = createSlice({
  name: 'projectPlusHistorical',
  initialState,
  reducers: {
    changeHistoricalDate(
      state: IProjectPlusHistoricalSlice,
      action: PayloadAction<string>,
    ): void {
      state.isCurrentDate = isToday(new Date(action.payload));
      state.historicalDate = action.payload;
    },
    changeCurrentDate(
      state: IProjectPlusHistoricalSlice,
      action: PayloadAction<boolean>,
    ): void {
      state.isCurrentDate = action.payload;
    },
    changeMonthDate(
      state: IProjectPlusHistoricalSlice,
      action: PayloadAction<Record<string, string>>,
    ): void {
      const { payload } = action;
      state.monthFromDate = payload.fromDate;
      state.monthToDate = payload.toDate;
    },
    resetHistoricalDate(
      state: IProjectPlusHistoricalSlice,
    ): void {
      state.isCurrentDate = true;
      state.historicalDate = initHistoricalDate;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getHistoricalDayStatistics.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getHistoricalDayStatistics.fulfilled, (
        state: IProjectPlusHistoricalSlice,
        action: PayloadAction<IHistoricalDayStatisticsDto[]>,
      ) => {
        const { payload } = action;
        state.dayStatistics = payload;
        state.loading = false;
        state.error = false;
      })
      .addCase(getHistoricalDayStatistics.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});
export const {
  changeHistoricalDate,
  changeCurrentDate,
  changeMonthDate,
  resetHistoricalDate,
} = projectPlusHistoricalSlice.actions;
export default projectPlusHistoricalSlice.reducer;
