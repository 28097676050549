import {
  generateEndOfMonthDay,
  generateStartOfCurrentDay,
  generateStartOfMonthDay,
  generateEndOfCurrentDay,
  generateStartOfWeekDay,
  generateEndOfWeekDay,
  generateStartOfQuarterDay,
  generateEndOfQuarterDay,
  generateStartOfYearDay,
  generateEndOfYearDay,
} from '@helpers/dateFormatter';
import { DateFilterEnum } from '@enums/dateFilter.enum';

import { IDateRange } from './interfaces/dateRange';

const dayRange = {
  from: generateStartOfCurrentDay(),
  to: generateEndOfCurrentDay(),
};

const weekRange = {
  from: generateStartOfWeekDay(),
  to: generateEndOfWeekDay(),
};

const monthRange = {
  from: generateStartOfMonthDay(),
  to: generateEndOfMonthDay(),
};

const quarterRange = {
  from: generateStartOfQuarterDay(),
  to: generateEndOfQuarterDay(),
};

const yearRange = {
  from: generateStartOfYearDay(),
  to: generateEndOfYearDay(),
};

export const dateRanges: { [key in DateFilterEnum]: IDateRange } = {
  [DateFilterEnum.DAY]: dayRange,
  [DateFilterEnum.WEEK]: weekRange,
  [DateFilterEnum.MONTH]: monthRange,
  [DateFilterEnum.QUARTER]: quarterRange,
  [DateFilterEnum.YEAR]: yearRange,
};

export const defaultDataForRankingRequest = {
  dateInterval: {
    interval: DateFilterEnum.WEEK,
    from: dateRanges[DateFilterEnum.WEEK].from,
    to: dateRanges[DateFilterEnum.WEEK].to,
  },
  materialTypes: [],
  selectedTonnageRange: 0,
  selectedCustomers: [],
  selectedPlants: [],
};
