export const downloadURL = (
  url: string,
  name?: string,
): void => {
  const link = document.createElement('a');
  if (name) {
    link.download = name;
  }
  link.href = url;
  link.click();
};
