import Cookies from 'universal-cookie';

import { IOktaData, IOktaStoredPayload } from '../oktaClient.interface';
import { wrapDataEntry } from './helpers/wrapDataEntry';

export class CookieStore {
  private httpOnly = process.env.NODE_ENV !== 'development';
  store = new Cookies();

  saveData(entry: IOktaData, storageKey: string): void {
    const data: IOktaStoredPayload = wrapDataEntry(entry);
    this.store.set(storageKey, data, { path: '/', secure: true, httpOnly: this.httpOnly });
  }

  getData(fieldKey: string): Partial<IOktaData> | null {
    const data = this.store.get(fieldKey);
    const now = new Date().getTime();

    if (!data) {
      return null;
    }

    if (data.expiresAt < now) {
      if (data.body.refreshToken) {
        data.body = {
          refreshToken: data.body.refreshToken,
        };

        return data.body;
      }

      return null;
    }

    return data.body;
  }

  clearData(storageKey: string): void {
    this.store.remove(storageKey, { path: '/', secure: true, httpOnly: this.httpOnly });
  }
}
