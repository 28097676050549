import { IOktaAuthState } from './oktaProvider.interface';

export type ActionType =
  | { type: 'LOGIN_STARTED' }
  | { type: 'LOGIN_COMPLETE' }
  | { type: 'LOGOUT_STARTED' }
  | { type: 'LOGOUT_COMPLETE' }
  | { type: 'SEND_EMAIL_STARTED' }
  | { type: 'SEND_EMAIL_COMPLETE' }
  | { type: 'ERROR' }

export const reducer = (state: IOktaAuthState, action: ActionType): IOktaAuthState => {
  switch (action.type) {
    case 'LOGIN_STARTED':
      return {
        ...state,
        isLoading: true,
      };
    case 'LOGIN_COMPLETE':
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
      };
    case 'LOGOUT_STARTED':
      return {
        ...state,
        isLoading: true,
      };
    case 'LOGOUT_COMPLETE':
      return {
        ...state,
        isLoading: false,
        isAuthenticated: false,
      };
    case 'SEND_EMAIL_STARTED':
      return {
        ...state,
        isLoading: true,
      };
    case 'SEND_EMAIL_COMPLETE':
      return {
        ...state,
        isLoading: false,
      };
    case 'ERROR':
      return {
        ...state,
        isLoading: false,
      };
    default:
      return state;
  }
};
