import { FC, memo } from 'react';
import { SvgIcon } from '@mui/material';

import { ReactComponent as PlusIcon } from '@assets/images/icons/plus_icon.svg';
import { ReactComponent as MinusIcon } from '@assets/images/icons/minus_icon.svg';

import { IEntityListItemProps } from './entityListItem.interface';
import { minusIconTestId, plusIconTestId } from './entityListItem.const';
import { EntityListItemWrapper } from './entityListItem.theme';

const EntityListItem: FC<IEntityListItemProps> = (props) => {
  const {
    id,
    label,
    isSelected,
    manageSelectedEntity,
  } = props;

  const manageSelectedEntityHandler = (): void => {
    manageSelectedEntity(id);
  };

  return (
    <EntityListItemWrapper>
      <div className="label-wrapper">
        <h4 className="label">
          {label}
        </h4>
      </div>

      <button
        type="button"
        className="selected-status-icon-button"
        onClick={manageSelectedEntityHandler}
      >
        {isSelected
          ? <SvgIcon
              className="selected-status-icon"
              component={MinusIcon}
              data-testid={minusIconTestId}
            />
          : <SvgIcon
              className="selected-status-icon"
              component={PlusIcon}
              data-testid={plusIconTestId}
            />
        }
      </button>
    </EntityListItemWrapper>
  );
};

export default memo(EntityListItem);
