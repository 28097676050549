import { FC, PropsWithChildren, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

const Portal: FC<PropsWithChildren> = ({ children }) => {
  const portalRootElementRef = useRef<HTMLDivElement | null>(null);

  if (!portalRootElementRef.current) {
    portalRootElementRef.current = document.createElement('div');
  }

  useEffect(() => {
    const rootPopupContainer = document.getElementById('portal-root');
    const { current } = portalRootElementRef;
    if (current && rootPopupContainer) {
      rootPopupContainer.appendChild(current);
    }

    return (): void => {
      if (current && rootPopupContainer) {
        rootPopupContainer.removeChild(current);
      }
    };
  }, []);

  return createPortal(children, portalRootElementRef.current);
};

export default Portal;
