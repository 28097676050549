import { IProjectPlus } from '@pages/projectPlusModule/pages/live/interfaces/projectPlus.interface';
import { ISelectedProjectPlus } from '@pages/projectPlusModule/pages/live/interfaces/selectedProjectPlus.interface';
import { getCompetitionType } from '../../lastLoadTimeContainer/helpers/getCompetitionType.helper';
import { getTimeByTruckNumber } from '../../lastLoadTimeContainer/helpers/getTimeByTruckNumber.helper';

export const convertToSelectedProjectPlus = (
  newSelectedProjectPlus: IProjectPlus
): ISelectedProjectPlus => {
  const {
    projectId,
    customer,
    plant,
    trucksOnProject,
    roundStatistics,
    targetTonnage,
    ticketedTonnage,
    version,
    uniqueId,
    projectedTargetTime,
    status,
    precalculatedCompletions,
    assignedTrucks,
  } = newSelectedProjectPlus;
  const {
    bestRoundDurationInMinutes,
    worstRoundDurationInMinutes,
    latestRoundDurationInMinutes,
  } = roundStatistics || {};
  const {
    projectName,
    materialType,
    plantId,
    customerId,
  } = projectId;
  const customerName = customer?.name || '';
  const plantName = plant?.name || '';
  const completionTime = getTimeByTruckNumber(precalculatedCompletions, assignedTrucks || trucksOnProject);
  const newTime = getCompetitionType(completionTime, projectedTargetTime);

  return {
    projectName,
    materialType,
    customerName,
    plantName,
    trucksOnProject,
    bestRoundDurationInMinutes,
    worstRoundDurationInMinutes,
    latestRoundDurationInMinutes,
    targetTonnage,
    ticketedTonnage,
    currentCompletionTime: newTime?.time,
    uniqueId,
    version,
    projectedTargetTime,
    status,
    plantId,
    customerId,
  };
};
