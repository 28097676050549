import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { EProjectPlusPagesType } from '@pages/projectPlusModule/enums/pagesType.enum';

import { IProjectPlusCommonSlice } from './projectPlusCommonSlice.interface';

const initialState: IProjectPlusCommonSlice = {
  projectPlusPagesType: EProjectPlusPagesType.LIVE,
  isProjectPlusUnhide: false,
  isListLoading: false,
};

const projectPlusCommonSlice = createSlice({
  name: 'projectPlusCommon',
  initialState,
  reducers: {
    openSettingsPopup(
      state: IProjectPlusCommonSlice,
      action: PayloadAction<EProjectPlusPagesType>,
    ): void {
      const { payload } = action;
      state.projectPlusPagesType = payload;
    },
    setIsProjectPlusUnhide(
      state: IProjectPlusCommonSlice,
      action: PayloadAction<boolean>,
    ): void {
      const { payload } = action;
      state.isProjectPlusUnhide = payload;
    },
    setIsListLoading(
      state: IProjectPlusCommonSlice,
      action: PayloadAction<boolean>,
    ): void {
      const { payload } = action;
      state.isListLoading = payload;
    },
  },
});

export const {
  openSettingsPopup,
  setIsProjectPlusUnhide,
  setIsListLoading,
} = projectPlusCommonSlice.actions;
export default projectPlusCommonSlice.reducer;
