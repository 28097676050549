import eventBus from '@eventBus/index';

import { EventBusTypes } from '../../enums/eventBusTypes.enum';
import { EventBusCallbackType } from '../../interfaces/callback.interface';
import { EmptyFunction } from '../../interfaces/emptyFunction.interface';
import { IWidgetTileNameChangeProps } from './widgetTileNameChange.interface';

const eventName = EventBusTypes.WIDGET_TILE_NAME_CHANGE;

const subscribe = (
  callback: EventBusCallbackType<IWidgetTileNameChangeProps>
): EmptyFunction => {
  return eventBus.subscribe<IWidgetTileNameChangeProps>(eventName, callback);
};

const broadcast = (data: IWidgetTileNameChangeProps): void => {
  eventBus.broadcast<IWidgetTileNameChangeProps>(eventName, data);
};

export default {
  subscribe,
  broadcast,
};
