import { ICompletion } from '@pages/projectPlusModule/pages/live/interfaces/completion.interface';
import {
  IPrecalculatedCompletion,
  IPrecalculatedCompletions,
} from '@pages/projectPlusModule/pages/live/interfaces/precalculatedCompletions.interface';

export const getTimeByTruckNumber = (
  precalculatedCompletions: IPrecalculatedCompletions,
  trucksNumber: number,
): ICompletion | null => {
  let competitionByTruck: IPrecalculatedCompletion | undefined;
  if (!precalculatedCompletions) return null;
  const { completions } = precalculatedCompletions;
  const firstItemAssignedTrucksNumber = completions[0].assignedTrucks;
  const lastItemAssignedTrucksNumber = completions[completions.length - 1].assignedTrucks;
  competitionByTruck = completions.find((item: IPrecalculatedCompletion) => item.assignedTrucks === trucksNumber);
  if (!competitionByTruck && firstItemAssignedTrucksNumber && firstItemAssignedTrucksNumber < trucksNumber) {
    competitionByTruck = completions[completions.length - 1];
  }
  if (!competitionByTruck && lastItemAssignedTrucksNumber && lastItemAssignedTrucksNumber > trucksNumber) {
    [competitionByTruck] = completions;
  }
  if (!competitionByTruck) return null;
  const { completion } = competitionByTruck;
  return completion;
};
