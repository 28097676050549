import { createContext, FC, PropsWithChildren, useMemo, useRef, useState } from 'react';

import { IConfirmationPopupValues } from '@features/confirmationPopup/interfaces/confirmationPopupValues.interface';
import ConfirmationPopup from '@features/confirmationPopup/components/confirmationPopup/ConfirmationPopup';
import Modal from '@components/popupWrapper/modal/Modal';

import { IConfirmationProvider } from './confirmationProvider.interface';

export const ConfirmationContext = createContext<IConfirmationProvider | undefined>(undefined);

type TPromiseResolver = (value: boolean | PromiseLike<boolean>) => void;

const ConfirmationProvider: FC<PropsWithChildren> = ({ children }) => {
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);
  const resolverRef = useRef<TPromiseResolver>();
  const popupContentRef = useRef<IConfirmationPopupValues | undefined>(undefined);

  const onConfirmationProcessing = (popupContent?: IConfirmationPopupValues): Promise<boolean> => {
    setIsConfirmationOpen(true);
    popupContentRef.current = popupContent;

    return new Promise((resolve) => {
      resolverRef.current = resolve;
    });
  };

  const handleConfirmationAccess = (): void => {
    if (resolverRef.current) resolverRef.current(true);
    setIsConfirmationOpen(false);
  };

  const handleConfirmationCancel = (): void => {
    if (resolverRef.current) resolverRef.current(false);
    setIsConfirmationOpen(false);
  };

  const providerValue = useMemo(() => ({
    isConfirmationOpen,
    onConfirmationProcessing,
  }), [
    isConfirmationOpen,
  ]);

  return (
    <ConfirmationContext.Provider value={providerValue}>
      {children}

      <Modal
        open={isConfirmationOpen}
        locked={true}
        zIndex={popupContentRef.current?.zIndex}
      >
        <ConfirmationPopup
          popupContent={popupContentRef.current}
          handleConfirmationAccess={handleConfirmationAccess}
          handleConfirmationCancel={handleConfirmationCancel}
          zIndex={popupContentRef.current?.zIndex}
          externalClasses={popupContentRef.current?.externalClasses}
        />
      </Modal>
    </ConfirmationContext.Provider>
  );
};

export { ConfirmationProvider };
