import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { mutateProjectPlusRequest } from '@store/thunk/mutateProjectPlusRequest/mutateProjectPlusRequest';

import { IManageProjectPlusSliceProps } from './interfaces/manageProjectPlusSlice.interface';
import { IMutateProjectPlusValues } from './interfaces/manageProjectPlusValues.interface';

const initialState: IManageProjectPlusSliceProps = {
  trackProjectPlusValues: null,
  preserveTargetTonnage: false,
  trackProjectedTargetPopupStatus: false,
  editProjectPlusTimePopupStatus: false,
  error: {
    status: false,
    statusCode: null,
    message: null,
  },
  loading: false,
  processing: false,
};

const manageProjectPlusSlice = createSlice({
  name: 'manageProjectPlusSlice',
  initialState,
  reducers: {
    setProjectPlusForManagement(
      state: IManageProjectPlusSliceProps,
      action: PayloadAction<IMutateProjectPlusValues>
    ): void {
      const { payload } = action;
      state.trackProjectPlusValues = payload;
    },
    clearProjectPlusForManagement(state: IManageProjectPlusSliceProps): void {
      state.trackProjectPlusValues = null;
    },
    openTrackProjectedTargetPopup(state: IManageProjectPlusSliceProps): void {
      state.trackProjectedTargetPopupStatus = true;
    },
    closeTrackProjectedTargetPopup(state: IManageProjectPlusSliceProps): void {
      state.trackProjectedTargetPopupStatus = false;
    },
    openEditTimeProjectPlusPopup(state: IManageProjectPlusSliceProps): void {
      state.editProjectPlusTimePopupStatus = true;
    },
    closeEditTimeProjectPlusPopup(state: IManageProjectPlusSliceProps): void {
      state.editProjectPlusTimePopupStatus = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(mutateProjectPlusRequest.pending, (state) => {
        state.loading = true;
      })
      .addCase(mutateProjectPlusRequest.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(mutateProjectPlusRequest.rejected, (state) => {
        state.loading = false;
        state.error.status = true;
      });
  },
});

export const {
  setProjectPlusForManagement,
  clearProjectPlusForManagement,
  openTrackProjectedTargetPopup,
  closeTrackProjectedTargetPopup,
  closeEditTimeProjectPlusPopup,
  openEditTimeProjectPlusPopup,
} = manageProjectPlusSlice.actions;
export default manageProjectPlusSlice.reducer;
