import { FC, useEffect, useState, useCallback } from 'react';

import Modal from '@components/popupWrapper/modal/Modal';
import NoInternetMessage from '@components/noInternetMessage';
import { PopupStyleTypeEnum } from '@components/popupWrapper/modal/enums/popupStyleTypeEnum.enum';

import { INetworkCheckerProps } from './networkChecker.interface';

const NetworkChecker: FC<INetworkCheckerProps> = (props) => {
  const { children } = props;
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);

  const updateNetworkStatus = (): void => {
    setIsOnline(navigator.onLine);
  };

  const removeEventListeners = (): void => {
    window.removeEventListener('online', updateNetworkStatus);
    window.removeEventListener('offline', updateNetworkStatus);
  };

  const closePopupHandler = useCallback(() => {
    setIsOnline(true);
    removeEventListeners();
  }, []);

  useEffect(() => {
    window.addEventListener('online', updateNetworkStatus);
    window.addEventListener('offline', updateNetworkStatus);

    return (): void => removeEventListeners();
  }, []);

  return (
    <>
      {children}

      <Modal
        open={!isOnline}
        mode={PopupStyleTypeEnum.TOP_CENTER}
      >
        <NoInternetMessage closePopupHandler={closePopupHandler} />
      </Modal>
    </>
  );
};

export default NetworkChecker;
