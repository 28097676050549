import { FC, ReactElement, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import {
  verticalListSortingStrategy,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
  closestCorners,
  KeyboardSensor,
  DragOverlay,
} from '@dnd-kit/core';
import { DragOverEvent } from '@dnd-kit/core/dist/types';

import { IColumPreference } from '@interfaces/calibration/storedTablePreference.interface';
import { IUseColumnManagementLists } from '@pages/admin/pages/calibration/hooks/useColumnManagementList/useColumnManagementListStates.interface';
import { DndContextWrapper } from '@features/haulhubUi/uiKit/dndContextWrapper/dndContextWrapper.theme';
import { InformationMessageWrapper } from '@features/haulhubUi/uiKit/informationMessageWrapper/informationMessageWrapper.theme';
import { deepCompare } from '@helpers/deepCompare';

import ManageTableModalFooter from '../manageColumnsModalFooter/ManageColumnsModalFooter';
import { handleDragOverHandler } from './helpers/handleDragOverHandler';
import { handleDragEndHandler } from './helpers/handleDragEndHandler';
import DndListItemContent from './components/dndListItemContent/DndListItemContent';
import { IManageColumnsModalContentProps } from './manageColumnsModalContent.interface';
import { getColumnsList } from './helpers/getColumnsList';
import { hiddenColumnsListId, manageColumnsModalContentTexts, visibleColumnsListId } from './manageColumnsModalContent.const';
import DndList from './components/dndList/DndList';
import DndListItemWrapper from './components/dndListItemWrapper/DndListItemWrapper';

const ManageColumnsModalContent: FC<IManageColumnsModalContentProps> = (props) => {
  const {
    visibleColumns,
    hiddenColumns,
    isDefaultColumnsList,
    options,
    updateColumnsListHandler,
    resetButtonClickHandler,
    closeHandler,
    updateIsDefaultColumnsList,
  } = props;
  const [items, setItems] = useState<IUseColumnManagementLists>({ visibleColumns, hiddenColumns });
  const [activeDndItem, setActiveDndItem] = useState<IColumPreference | null>(null);
  const [activeOverlayContainer, setActiveOverlayContainer] = useState<string | null>(null);
  const activeItemContainer = useRef<string | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor,
      {
        coordinateGetter: sortableKeyboardCoordinates,
      }),
  );
  const maximumCounter = items.hiddenColumns.length + items.visibleColumns.length;

  useEffect(() => {
    const newItems = { visibleColumns, hiddenColumns };
    setItems((prevState) => {
      if (deepCompare(prevState, newItems)) return prevState;
      return newItems;
    });
  }, [visibleColumns, hiddenColumns]);

  const updateColumnsHandler = (): void => {
    updateColumnsListHandler(getColumnsList(items));
    closeHandler();
  };
  const handleDragStart = (event: DragOverEvent): void => {
    const { active } = event;
    activeItemContainer.current = active.data.current?.sortable.containerId;
    if (!activeItemContainer.current) return;
    const correctList = items[activeItemContainer.current as keyof IUseColumnManagementLists];
    const activeElement = correctList.find((item) => item.id === active.id);
    if (!activeElement) return;
    setActiveDndItem(activeElement);
  };

  const handleDragOver = (event: DragOverEvent): void => {
    const { active, over } = event;
    setActiveOverlayContainer(active.data.current ? active.data.current.sortable.containerId : null);
    if (!over) return;
    const newState = handleDragOverHandler(active, over, items);
    if (!newState) return;
    setItems(newState);
  };

  const handleDragEnd = (event: DragOverEvent): void => {
    setActiveDndItem(null);
    setActiveOverlayContainer(null);
    const { active, over } = event;
    if (!over) return;
    const newState = handleDragEndHandler(active, over, items);
    if (activeItemContainer.current !== active.data.current?.sortable.containerId) {
      updateIsDefaultColumnsList(false);
      activeItemContainer.current = null;
    }
    if (!newState) return;
    updateIsDefaultColumnsList(false);
    setItems(newState);
  };

  return (
    <>
      <InformationMessageWrapper>
        {options?.maximumColumnsText || manageColumnsModalContentTexts.MAXIMUM_COLUMN_MESSAGE(items.visibleColumns.length, maximumCounter)}
      </InformationMessageWrapper>

      <DndContextWrapper>
        <DndContext
          sensors={sensors}
          collisionDetection={closestCorners}
          onDragStart={handleDragStart}
          onDragOver={handleDragOver}
          onDragEnd={handleDragEnd}
        >
          <DndList<IColumPreference>
            id={visibleColumnsListId}
            items={items.visibleColumns}
            listTitle={options?.visibleListTitle || manageColumnsModalContentTexts.VISIBLE_LIST_TITLE}
            strategy={verticalListSortingStrategy}
            renderItem={(item): ReactElement => (
              <DndListItemWrapper
                key={item.id}
                useSortableConfig={{
                  id: item.id,
                  disabled: items?.visibleColumns.length === 1 || item.locked,
                }}
                isItemFreezed={item.frozen}
                renderContent={(renderProps): ReactElement => (
                  <DndListItemContent
                    item={item}
                    containerId={visibleColumnsListId}
                    useDragOverlay={true}
                    {...renderProps}
                  />
                )}
              />
            )}
          />

          <DndList<IColumPreference>
            id={hiddenColumnsListId}
            items={items.hiddenColumns}
            listTitle={options?.hiddenListTitle || manageColumnsModalContentTexts.HIDDEN_LIST_TITLE}
            strategy={verticalListSortingStrategy}
            renderItem={(item): ReactElement => (
              <DndListItemWrapper
                key={item.id}
                useSortableConfig={{
                  id: item.id,
                  disabled: item.locked,
                }}
                renderContent={(renderProps): ReactElement => (
                  <DndListItemContent
                    item={item}
                    containerId={hiddenColumnsListId}
                    useDragOverlay={true}
                    {...renderProps}
                  />
                )}
              />
            )}
          />

          {activeDndItem && activeItemContainer.current && activeOverlayContainer
            ? createPortal(
              <DragOverlay zIndex={999999}>
                <DndListItemContent
                  item={activeDndItem}
                  containerId={activeOverlayContainer}
                  isDragging={true}
                />
              </DragOverlay>,
              document.body,
            )
            : null
          }
        </DndContext>
      </DndContextWrapper>

      <ManageTableModalFooter
        isDefaultColumnsList={isDefaultColumnsList}
        resetButtonLabel={options?.resetButtonLabel}
        saveButtonLabel={options?.saveButtonLabel}
        saveButtonClickHandler={updateColumnsHandler}
        resetButtonClickHandler={resetButtonClickHandler}
      />
    </>
  );
};

export default ManageColumnsModalContent;
