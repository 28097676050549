import { createAsyncThunk } from '@reduxjs/toolkit';

import api from '@configs/urls/api';

import instance from '../instanceDefiner';
import { IExcludedSlipsParams } from './excludedSlipsParams.interface';

export const addExcludedSlips = createAsyncThunk(
  'projectPlusDetails/addExcludedSlips',
  async (inputParams: IExcludedSlipsParams) => {
    try {
      const response = await instance.post(api.excludedSlips, {
        ...inputParams,
      });
      return response.data;
    } catch {
      return Promise.reject();
    }
  },
);
