import { styled } from '@mui/material';
import { br, m, rem } from '@helpers/remUtils';

export const TimePickerWrapper = styled('div')(({ theme }) => ({
  '& .label': {
    marginBottom: rem(9),
    fontSize: rem(14),
    fontWeight: 500,
    letterSpacing: rem(-0.5),
  },
  '& .displays-wrapper': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  '& .separator': {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: m(0, 6),
    height: rem(20),
  },
  '& .separator-item': {
    width: rem(4),
    height: rem(4),
    borderRadius: '50%',
    background: theme.palette.text.primary,
  },
  '& .day-period-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    margin: m(0, 0, 0, 9),
    height: rem(49),
    borderRadius: br(6),
    border: `${rem(1)} solid ${theme.palette.additionalColors.divider.secondary}`,
    overflow: 'hidden',
  },
}));
