import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IAssociatedSelectedEntity } from './associatedSelectedEntity.interface';
import { IDrillingEntitiesSlice, IOpenModalPayload } from './drillingEntitiesSlice.interface';

const initialState: IDrillingEntitiesSlice = {
  isDrillingMode: false,
  drillingEntityType: null,
  parentName: '',
  parentDateRange: null,
  drillingEntityCount: 0,
  parentInterval: null,
  parentDateGroup: null,
  associatedSelectedEntity: null,
  parentEntity: null,
};

const drillingEntitiesSlice = createSlice({
  name: 'drillingEntities',
  initialState,
  reducers: {
    openDrillingMode: (
      state: IDrillingEntitiesSlice,
      action: PayloadAction<IOpenModalPayload>,
    ): void => {
      const {
        drillingEntityType,
        dateRange, parentName,
        parentInterval,
        parentDateGroup,
        drillingEntityCount,
        parentId,
        parentEntity,
      } = action.payload;
      state.isDrillingMode = true;
      state.drillingEntityType = drillingEntityType;
      state.drillingEntityCount = drillingEntityCount;
      state.parentDateRange = dateRange;
      state.parentName = parentName;
      state.parentDateGroup = parentDateGroup;
      state.parentInterval = parentInterval;
      state.parentId = parentId;
      state.parentEntity = parentEntity;
    },
    closeDrillingMode: (
      state: IDrillingEntitiesSlice,
    ): void => {
      state.isDrillingMode = false;
    },
    resetDrillingModeState: (): IDrillingEntitiesSlice => initialState,
    setActiveDrillingItem: (
      state: IDrillingEntitiesSlice,
      action: PayloadAction<IAssociatedSelectedEntity | null>,
    ): void => {
      state.associatedSelectedEntity = action.payload;
    },
  },
});

export const {
  openDrillingMode,
  closeDrillingMode,
  setActiveDrillingItem,
  resetDrillingModeState,
} = drillingEntitiesSlice.actions;
export default drillingEntitiesSlice.reducer;
