import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { useWebSocket } from '@hooks/useWebSocket/useWebSocket';
import { ITicketsReportLastJsonMessage } from '@providers/downloadTicketsProvider/interfaces/ticketsReportLastJsonMessage.interface';

import { IUseTicketsReportWebSocket } from './useTicketsReportWebSocket.interface';

export const useTicketsReportWebSocket = (): IUseTicketsReportWebSocket => {
  const { t } = useTranslation(['tickets']);
  const { enqueueSnackbar } = useSnackbar();
  const { actions } = useWebSocket<ITicketsReportLastJsonMessage>({
    socketUrl: process.env.REACT_APP_API_WEBSOCKET_URL || '',
    autoConnect: false,
  });

  const showErrorToaster = (): void => {
    enqueueSnackbar(t('download_tickets_report_error'), { variant: 'error' });
  };

  const getConnectionId = async (): Promise<string | undefined> => {
    let unsubscribe: VoidFunction;
    const { responseType } = await actions.connect();
    if (responseType !== 'SUCCESS') {
      showErrorToaster();
      return;
    }
    return new Promise((resolve) => {
      actions.sendMessage('getConnectionId');
      const processConnectionId = (newData: ITicketsReportLastJsonMessage): void => {
        if (newData.error) {
          showErrorToaster();
          actions.disconnect();
          return;
        }
        if (!newData.connectionId) return;
        resolve(newData.connectionId);
        unsubscribe();
      };

      unsubscribe = actions.subscribeToData(processConnectionId);
    });
  };

  const getReportUrl = (): Promise<string> => {
    let unsubscribe: VoidFunction;
    return new Promise((resolve) => {
      const processReportUrl = (newData: ITicketsReportLastJsonMessage): void => {
        if (newData.error) {
          showErrorToaster();
          actions.disconnect();
          return;
        }
        if (!newData.reportUrl) return;
        resolve(newData.reportUrl);
        unsubscribe();
        actions.disconnect();
      };

      unsubscribe = actions.subscribeToData(processReportUrl);
    });
  };

  return {
    actions: {
      getConnectionId,
      getReportUrl,
      disconnectWebSocket: actions.disconnect,
    },
  };
};
