import { FC } from 'react';

import { CenteredModal } from '@features/haulhubUi/uiKit/centeredModal/centeredModal.theme';
import { DefaultModalContentWrapper } from '@features/haulhubUi/uiKit/modalContent/defaultModalContentWrapper.theme';

import DefaultModalHeader from '../defaultModalHeader/DefaultModalHeader';
import { IManageColumnsModalProps } from './manageColumnsModal.interface';
import ManageColumnsModalContent from './components/manageColumnsModalContent/ManageColumnsModalContent';
import { manageColumnsModalTexts } from './manageColumnsModal.const';

const ManageColumnsModal: FC<IManageColumnsModalProps> = (props) => {
  const {
    isOpen,
    visibleColumns,
    hiddenColumns,
    isDefaultColumnsList,
    options,
    closeHandler,
    updateColumnsListHandler,
    resetButtonClickHandler,
    updateIsDefaultColumnsList,
  } = props;
  const {
    title,
    ...restOptions
  } = options || {};

  return (
    <CenteredModal open={isOpen}>
      <DefaultModalContentWrapper>
        <DefaultModalHeader
          title={title || manageColumnsModalTexts.HEADER_TITLE}
          closeHandler={closeHandler}
        />

        <ManageColumnsModalContent
          visibleColumns={visibleColumns}
          hiddenColumns={hiddenColumns}
          isDefaultColumnsList={isDefaultColumnsList}
          options={restOptions}
          updateColumnsListHandler={updateColumnsListHandler}
          resetButtonClickHandler={resetButtonClickHandler}
          updateIsDefaultColumnsList={updateIsDefaultColumnsList}
          closeHandler={closeHandler}
        />
      </DefaultModalContentWrapper>
    </CenteredModal>
  );
};

export { ManageColumnsModal };
