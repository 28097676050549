import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  projectPlusLapTrackTrafficPercentages,
} from '@store/thunk/projectPlusLapTrackTrafficPercentages/projectPlusLapTrackTrafficPercentages';
import { projectPlusLapTrackTraffic } from '@store/thunk/projectPlusLapTrackTraffic/projectPlusLapTrackTraffic';
import { IProjectPlusDetailsDto } from '@interfaces/projectPlusDetails/projectPlusDetailsResponseDto.interface';
import { getRounds } from '@store/thunk/projectPlusDetails/getProjectPlusRounds';
import { addExcludedSlips } from '@store/thunk/projectPlusExcludedSlips/addExcludedSlips';
import { removeExcludedSlips } from '@store/thunk/projectPlusExcludedSlips/removeExcludedSlips';
import { IFilteredTruck } from '@pages/projectPlusModule/pages/live/interfaces/filteredTrucks.interface';

import { IProjectPlusDetailsSliceState } from './projectPlusDetailsSlice.interface';
import { ActiveTabChartEnum } from './enums/activeTabChart.enum';
import { LeftOfTableModeEnum } from './enums/leftOfTableMode.enum';
import { ITrafficRound } from './interfaces/trafficRound.interface';
import { ITrafficPercentageValues } from './interfaces/trafficPercentageValues.interface';

const initialState: IProjectPlusDetailsSliceState = {
  detailsData: null,
  chevronsDate: null,
  lapTrackTrafficValues: null,
  lapTrackTrafficPercents: null,
  filteredTrucks: [],
  activeTabChart: ActiveTabChartEnum.LAP_TRACK,
  leftOfTableDetailsMode: LeftOfTableModeEnum.LOTT,
  isTrafficOn: false,
  activeRound: null,
  activeTruck: null,
  loading: false,
  loadingTraffic: false,
  loadingTrafficPercentages: false,
  error: false,
  isOnTableClick: false,
  selectedHaulers: [],
};

const projectPlusDetailsSlice = createSlice({
  name: 'projectPlusDetails',
  initialState,
  reducers: {
    changeActiveChartTab(
      state: IProjectPlusDetailsSliceState,
      action: PayloadAction<ActiveTabChartEnum>,
    ): void {
      state.activeTabChart = action.payload;
      state.isOnTableClick = false;
    },
    changeActiveRound(
      state: IProjectPlusDetailsSliceState,
      action: PayloadAction<number | null>,
    ): void {
      state.activeRound = action.payload;
    },
    changeLeftTableChartMode(
      state: IProjectPlusDetailsSliceState,
      action: PayloadAction<LeftOfTableModeEnum>,
    ): void {
      state.leftOfTableDetailsMode = action.payload;
      state.activeRound = null;
      state.isOnTableClick = false;
    },
    changeActiveTruck(
      state: IProjectPlusDetailsSliceState,
      action: PayloadAction<string | null>,
    ): void {
      state.activeTruck = action.payload;
    },
    changeFilteredTrucks(
      state: IProjectPlusDetailsSliceState,
      action: PayloadAction<IFilteredTruck[]>,
    ): void {
      state.filteredTrucks = action.payload;
    },
    resetFilteredTrucks(
      state: IProjectPlusDetailsSliceState,
    ): void {
      state.filteredTrucks = [];
    },
    changeSelectedHaulers(
      state: IProjectPlusDetailsSliceState,
      action: PayloadAction<IFilteredTruck[]>,
    ): void {
      state.selectedHaulers = action.payload;
    },
    resetSelectedHaulers(
      state: IProjectPlusDetailsSliceState,
    ): void {
      state.selectedHaulers = [];
    },
    setIsOnTableClick(
      state: IProjectPlusDetailsSliceState,
      action: PayloadAction<boolean>,
    ): void {
      state.isOnTableClick = action.payload;
    },
    trafficOn(
      state: IProjectPlusDetailsSliceState,
    ): void {
      state.isTrafficOn = true;
      state.filteredTrucks = [];
      state.activeRound = null;
      state.activeTruck = null;
    },
    trafficOff(
      state: IProjectPlusDetailsSliceState,
    ): void {
      state.isTrafficOn = false;
    },
    resetLapTrackTrafficValues(
      state: IProjectPlusDetailsSliceState,
    ): void {
      state.lapTrackTrafficValues = null;
    },
    resetLapTrackTrafficPercents(
      state: IProjectPlusDetailsSliceState,
    ): void {
      state.lapTrackTrafficPercents = null;
    },
    resetDetailsData(state: IProjectPlusDetailsSliceState): void {
      state.detailsData = null;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getRounds.pending, (state, { meta }) => {
        const isCurrentShortPolling = meta.arg?.isShortPolling;
        state.loading = !isCurrentShortPolling;
        state.error = false;
      })
      .addCase(getRounds.fulfilled, (
        state: IProjectPlusDetailsSliceState,
        action: PayloadAction<IProjectPlusDetailsDto>,
      ) => {
        const { payload } = action;
        const {
          rounds,
          statistics,
          nextDayWithRounds,
          previousDayWithRounds,
          truckStatistics,
        } = payload;
        return ({
          ...state,
          detailsData: {
            rounds: rounds.content,
            statistics,
            truckStatistics,
          },
          chevronsDate: {
            nextDayWithRounds,
            previousDayWithRounds,
          },
          lapTrackTrafficValues: null,
          lapTrackTrafficPercents: null,
          activeRound: null,
          loading: false,
          error: false,
        });
      })
      .addCase(getRounds.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(projectPlusLapTrackTraffic.pending, (state) => {
        state.loadingTraffic = true;
        state.error = false;
      })
      .addCase(projectPlusLapTrackTraffic.fulfilled, (
        state: IProjectPlusDetailsSliceState,
        action: PayloadAction<ITrafficRound[] | null>,
      ) => {
        const { payload } = action;
        if (!payload) return state;

        return ({
          ...state,
          lapTrackTrafficValues: payload,
          loadingTraffic: false,
          error: false,
        });
      })
      .addCase(projectPlusLapTrackTraffic.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(projectPlusLapTrackTrafficPercentages.pending, (state) => {
        state.loadingTrafficPercentages = true;
        state.error = false;
      })
      .addCase(projectPlusLapTrackTrafficPercentages.fulfilled, (
        state: IProjectPlusDetailsSliceState,
        action: PayloadAction<ITrafficPercentageValues | null>,
      ) => {
        const { payload } = action;
        if (!payload) return state;

        return ({
          ...state,
          lapTrackTrafficPercents: payload,
          loadingTrafficPercentages: false,
          error: false,
        });
      })
      .addCase(projectPlusLapTrackTrafficPercentages.rejected, (state) => {
        state.loadingTrafficPercentages = false;
        state.error = true;
      })
      .addCase(addExcludedSlips.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(addExcludedSlips.fulfilled, (state) => {
        state.loading = false;
        state.error = false;
      })
      .addCase(addExcludedSlips.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(removeExcludedSlips.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(removeExcludedSlips.fulfilled, (state) => {
        state.loading = false;
        state.error = false;
      })
      .addCase(removeExcludedSlips.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const {
  changeActiveChartTab,
  changeActiveRound,
  changeLeftTableChartMode,
  changeActiveTruck,
  changeFilteredTrucks,
  setIsOnTableClick,
  trafficOn,
  trafficOff,
  resetFilteredTrucks,
  resetLapTrackTrafficValues,
  resetLapTrackTrafficPercents,
  resetDetailsData,
  changeSelectedHaulers,
  resetSelectedHaulers,
} = projectPlusDetailsSlice.actions;
export default projectPlusDetailsSlice.reducer;
