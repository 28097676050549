import React, { FC, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';

import { useGlobalFlexStyles } from '@assets/globalStyles';

import ExperienceButton from '../experienceButton';
import { getExperienceSwitcherConfig } from './configs/getExperienceSwitcherConfig';
import { IExperienceButtonContainer } from './experienceButtonContainer.interface';

const ExperienceButtonContainer: FC<IExperienceButtonContainer> = (props) => {
  const {
    experienceHandler,
    experience,
    externalClasses = {},
  } = props;
  const { classes: flexClasses } = useGlobalFlexStyles();
  const { t } = useTranslation(['experienceSwitcher', 'roles']);

  const renderExperienceButton = (): ReactElement[] => {
    return getExperienceSwitcherConfig(t).map((item) => {
      const isActive = experience === item.experience;
      return (
        <ExperienceButton
          key={item.id}
          title={item.title}
          description={item.description}
          isActive={isActive}
          experience={item.experience}
          experienceButtonHandler={experienceHandler}
          externalClasses={{
            button: externalClasses['button'],
          }}
        />
      );
    });
  };

  return (
    <div className={flexClasses.flexRowWrap}>
      {renderExperienceButton()}
    </div>
  );
};

export default ExperienceButtonContainer;
