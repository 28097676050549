enum NamesEnum {
  copiedInfo = 'copiedInfo',
  commonError = 'commonError',
  addFavoriteWidget = 'addFavoriteWidget',
  removeFavoriteWidget = 'removeFavoriteWidget',
  removeFavoriteWidgetDashboard = 'removeFavoriteWidgetDashboard',
  noFound = 'noFound',
  noEntityFound = 'noEntityFound',
  pageNotFound = 'pageNotFound',
  reportsNotFound = 'reportsNotFound',
  widgetFiltersNotFound = 'widgetFiltersNotFound',
  datePickerStartDateError = 'datePickerStartDateError',
  datePickerEndDateError = 'datePickerEndDateError',
  somethingWentWrong = 'somethingWentWrong',
  firstNameInvalidData = 'firstNameInvalidData',
  emailInvalidData = 'emailInvalidData',
  passwordInvalidData = 'passwordInvalidData',
  confirmationPassword = 'confirmationPassword',
  lastNameInvalidData = 'lastNameInvalidData',
  existedEmail = 'existedEmail',
  fieldRequired = 'fieldRequired',
  archiveUser = 'archiveUser',
  restoreUser = 'restoreUser',
  resetPassword = 'resetPassword',
  saveCurrentUser = 'saveCurrentUser',
  maxLength = 'maxLength',
  minLength = 'minLength',
  phoneNumberFormat = 'phoneNumberFormat',
  savedUserRoles = 'savedUserRoles',
  saveRolePermissions = 'saveRolePermissions',
  createNewRole = 'createNewRole',
  editRole = 'editRole',
  singleUserCreated = 'singleUserCreated',
  multipleUsersCreated = 'multipleUsersCreated',
  updateRoleStatus = 'updateRoleStatus',
  deleteSavedSearch = 'deleteSavedSearch',
  addedSavedSearch = 'addedSavedSearch',
  clearSavedSearches = 'clearSavedSearches',
  permissionRestriction = 'permissionRestriction',
  addedCustomerToWatchlist = 'addedCustomerToWatchlist',
  removeCustomerToWatchlist = 'removeCustomerToWatchlist',
  addedPlantToWatchlist = 'addedPlantToWatchlist',
  removePlantToWatchlist = 'removePlantToWatchlist',
  addedProjectToWatchlist = 'addedProjectToWatchlist',
  removeProjectToWatchlist = 'removeProjectToWatchlist',
  noDataAvailable = 'noDataAvailable',
  removeCustomWidget = 'removeCustomWidget',
  createWidget = 'createWidget',
  updateWidget = 'updateWidget',
  moveWidget = 'moveWidget',
  changeRoleSuccess = 'changeRoleSuccess',
  archiveUserSuccess = 'archiveUserSuccess',
  restoreUserSuccess = 'restoreUserSuccess',
  enableFeatureFlag = 'enableFeatureFlag',
  disableFeatureFlag = 'disableFeatureFlag',
  editUserSuccess = 'editUserSuccess',
  loginSuccess = 'loginSuccess',
  logoutSuccess = 'logoutSuccess',
  resetPasswordSuccess = 'resetPasswordSuccess',
  addToTrackedProjectPlus = 'addToTrackedProjectPlus',
  updateTargetTimeProjectPlus = 'updateTargetTimeProjectPlus',
  updateTrucksNumberProjectPlus = 'updateTrucksNumberProjectPlus',
  updateTargetTrackedProjectPlus = 'updateTargetTrackedProjectPlus',
  removeProjectPlusFromTrackedList = 'removeProjectPlusFromTrackedList',
  hiddenProjectPlus = 'hiddenProjectPlus',
  unhiddenProjectPlus = 'unhiddenProjectPlus',
  excludeEntity = 'excludeEntity',
  includeEntity = 'includeEntity',
  emailInvalidSymbol = 'emailInvalidSymbol',
  selectValueFromList = 'selectValueFromList',
  createBid = 'createBid',
  editBid = 'editBid',
  deleteBid = 'deleteBid',
  notValidUrl = 'notValidUrl',
  blockUser = 'blockUser',
  blockMaterialProducer = 'blockMaterialProducer',
  epdTicketTypeExists = 'epdTicketTypeExists',
  epdCreatingSuccess = 'epdCreatingSuccess',
  epdEditingSuccess = 'epdEditingSuccess',
  gwpTotalValidation = 'gwpTotalValidation',
  makeRequestOnNeRoleSuccess = 'makeRequestOnNeRoleSuccess',
}

export default NamesEnum;
