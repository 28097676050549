import { FC } from 'react';
import { CircularProgress, ListItem } from '@mui/material';
import { rem } from '@helpers/remUtils';

import { CustomSkeleton } from '../customSkeleton';
import { IEntityListProps } from './entityList.interface';
import { EntityListWrapper } from './entityList.theme';
import { entityListTexts } from './entityList.const';
import EntityListItem from './components/entityListItem/EntityListItem';

const EntityList: FC<IEntityListProps> = (props) => {
  const {
    entityList,
    firstLoading,
    options,
    newPageLoading,
    intervalRef,
    manageSelectedEntity,
    onListScrollHandler,
  } = props;
  const { emptyListMessage } = options || {};

  return (
    <EntityListWrapper
      ref={intervalRef}
      onScroll={onListScrollHandler}
    >
      {firstLoading
        ? <CustomSkeleton
            lines={8}
            lineHeight={rem(30)}
          />
        : null
      }

      {!firstLoading
        ? entityList.map((row) => {
            const {
              id,
              label,
              isSelected,
            } = row;

            return (
              <EntityListItem
                key={id}
                id={id}
                label={label}
                isSelected={isSelected}
                manageSelectedEntity={manageSelectedEntity}
              />
            );
          })
        : null
      }

      {newPageLoading && !firstLoading
        ? <ListItem className="loading-wrapper">
            <CircularProgress size={30} />
          </ListItem>
        : null
      }

      {!entityList.length && !newPageLoading && !firstLoading
        ? <ListItem className="empty-state">
            {emptyListMessage || entityListTexts.NO_DATA_AVAILABLE}
          </ListItem>
        : null
      }
    </EntityListWrapper>
  );
};

export { EntityList };
