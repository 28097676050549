import { FC, PropsWithChildren, useEffect } from 'react';

import { detectDevice } from './helpers/detectDevice.helper';
import { DeviceType } from './enums/device.type';
import { redirectLinks } from './redirectionToMobileApplication.const';
import MobileView from './mobileView/MobileView';

const RedirectionToMobileApplication:FC<PropsWithChildren> = ({ children }) => {
  const device = detectDevice();
  const isAndroid = device === DeviceType.ANDROID;
  const isIOS = device === DeviceType.IOS;
  const isPC = device === DeviceType.PC;

  useEffect(() => {
    if (isAndroid || isIOS) {
      window.location.replace(redirectLinks.deepLink);
    }
  }, []);

  return (
    <>
      {!isPC
        ? <MobileView
          link={isAndroid
            ? redirectLinks.downloadAndroidApp
            : redirectLinks.downloadIOSApp
          }
          />
        : children
      }
    </>
  );
};

export default RedirectionToMobileApplication;
