import { createContext, FC, PropsWithChildren, useCallback, useMemo, useState } from 'react';

import { useEntityManagement } from '@features/recentlyViewed';
import { EntityTypeEnum } from '@enums/entityType.enum';

import { SearchContentEnum } from '../../enums/searchContent.enum';
import { BaseSearchResultUi } from '../../classes/baseSearchResult.ui';
import {
  IGlobalSearchProviderValue,
  ITransferDataForResultPage,
  TransferTab,
} from './globalSearchProvider.interface';

export const GlobalSearchContext = createContext<IGlobalSearchProviderValue | undefined>(undefined);
GlobalSearchContext.displayName = 'GlobalSearchProvider';

const initialTransferDataForResultPage = {
  queryString: '',
  selectedTab: SearchContentEnum.CUSTOMER,
};

const GlobalSearchProvider: FC<PropsWithChildren> = (props) => {
  const { children } = props;
  const [searchValue, setSearchValue] = useState<string>('');
  const { setSelectedItemHandler } = useEntityManagement();
  const [
    transferDataForResultPage,
    setTransferDataForResultPage,
  ] = useState<ITransferDataForResultPage>(initialTransferDataForResultPage);
  const [isSearchInputDirty, setIsSearchInputDirty] = useState<boolean>(false);
  const [isSearchBox, setIsSearchBox] = useState<boolean>(false);
  const [selectedTab, setSelectedTab] = useState<SearchContentEnum>(SearchContentEnum.TOP_RESULT);
  const [selectedSearchResultItem, setSelectedSearchResultItem] = useState<BaseSearchResultUi | null>(null);

  const setSearchStringValueHandler = useCallback((value: string): void => {
    setSearchValue(value);
  }, []);

  const resetSearchValueHandler = useCallback((): void => {
    setSearchValue('');
  }, []);

  const openSearchBoxHandler = useCallback((): void => {
    setIsSearchBox(true);
  }, []);

  const resetSearchInputDirtyHandler = useCallback((): void => {
    setIsSearchInputDirty(false);
  }, []);

  const resetTabHandler = useCallback((): void => {
    setSelectedTab(SearchContentEnum.TOP_RESULT);
  }, []);

  const closeSearchBoxHandler = useCallback((): void => {
    setIsSearchBox(false);
    resetSearchInputDirtyHandler();
    resetTabHandler();
  }, []);

  const selectCorrectTabHandler = useCallback((item: SearchContentEnum): void => {
    setSelectedTab(item);
  }, []);

  const setSearchInputDirtyHandler = useCallback((): void => {
    if (!isSearchInputDirty) {
      setIsSearchInputDirty(true);
    }
  }, [isSearchInputDirty]);

  const setSearchResultItemHandler = useCallback((
    result: BaseSearchResultUi,
    callback?: () => void,
  ) => {
    const { uniqueId, name, id, entityType, projectType, customerName } = result;
    setSelectedSearchResultItem(result);

    setSelectedItemHandler({
      selectedEntity: {
        name,
        categoryId: id,
        uniqueId,
        type: entityType as unknown as EntityTypeEnum,
        projectType: entityType === EntityTypeEnum.PROJECT
          ? projectType as unknown as EntityTypeEnum
          : '',
      },
      isRedirected: true,
      isFromEntityList: true,
      ...(entityType === EntityTypeEnum.PROJECT && { customerName }),
    });

    closeSearchBoxHandler();
    if (callback) {
      callback();
    }
  }, []);

  const resetSelectedSearchResultHandler = useCallback(() => {
    setSelectedSearchResultItem(null);
  }, []);

  const setTransferDataHandler = useCallback((tab: TransferTab) => {
    const newState = {
      queryString: searchValue,
      selectedTab: tab,
    };
    closeSearchBoxHandler();
    setTransferDataForResultPage(newState);
  }, [searchValue]);

  const resetTransferDataHandler = useCallback(() => {
    setTransferDataForResultPage(initialTransferDataForResultPage);
  }, []);

  const value = useMemo(() => ({
    searchValue,
    transferDataForResultPage,
    isSearchInputDirty,
    isSearchBox,
    selectedTab,
    selectedSearchResultItem,
    setSearchStringValueHandler,
    openSearchBoxHandler,
    closeSearchBoxHandler,
    selectCorrectTabHandler,
    resetTabHandler,
    resetSearchValueHandler,
    setSearchInputDirtyHandler,
    resetSearchInputDirtyHandler,
    setSearchResultItemHandler,
    resetSelectedSearchResultHandler,
    setTransferDataHandler,
    resetTransferDataHandler,
  }), [
    searchValue,
    transferDataForResultPage,
    isSearchInputDirty,
    isSearchBox,
    selectedTab,
    selectedSearchResultItem,
    setSearchInputDirtyHandler,
    setTransferDataHandler,
  ]);

  return (
    <GlobalSearchContext.Provider value={value}>
      {children}
    </GlobalSearchContext.Provider>
  );
};

export default GlobalSearchProvider;
