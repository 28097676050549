import eventBus from '@eventBus/index';
import { EventBusCallbackType } from '@eventBus/interfaces/callback.interface';

import { EventBusTypes } from '../../enums/eventBusTypes.enum';

const eventName = EventBusTypes.GENERAL_SETTINGS_NOTIFICATION;

const subscribe = (callback: EventBusCallbackType<number>): VoidFunction => {
  return eventBus.subscribe(eventName, callback);
};

const broadcast = (data: number): void => {
  eventBus.broadcast<number>(eventName, data);
};

export default {
  subscribe,
  broadcast,
};
