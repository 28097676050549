import { FC, createContext, useEffect, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import { DndContext, DragStartEvent, DragOverEvent, DragOverlay, rectIntersection } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';

import { deepCompare } from '@helpers/deepCompare';
import { addItemLast, getIndex, getItemById, removeItem } from '@helpers/arrayUtils';
import { IColumPreference } from '@interfaces/calibration/storedTablePreference.interface';

import { IGroupsDndValues } from './groupsDndProvider.interface';
import { IGroupsDndProviderProps } from './groupsDndProviderProps.interface';

export const IGroupsDndProviderContext = createContext<IGroupsDndValues | undefined>(undefined);

const GroupsDndProvider: FC<IGroupsDndProviderProps> = (props) => {
  const {
    dropZoneId,
    groupsList,
    children,
    dragOverlayUi,
    onDragEndHandler,
  } = props;
  const [localGroupsList, setLocalGroupsList] = useState<IColumPreference[]>(groupsList || []);
  const [activeGroup, setActiveGroup] = useState<IColumPreference | null>(null);
  const [isGroupFromTable, setIsGroupFromTable] = useState(false);

  useEffect(() => {
    if (!groupsList || deepCompare(groupsList, localGroupsList)) return;
    setLocalGroupsList(groupsList);
  }, [groupsList]);

  const handleDragStart = (event: DragStartEvent): void => {
    const { active } = event;
    if (!active) return;
    setIsGroupFromTable(!active.data.current?.sortable);
    setActiveGroup(active.data.current as IColumPreference);
  };

  const handleDragOver = (event: DragOverEvent): void => {
    const { over } = event;
    if (!activeGroup) return;
    if (!over && isGroupFromTable) {
      setLocalGroupsList(removeItem(localGroupsList, activeGroup.id));
    }
    if (!over || activeGroup?.id === over.id) return;
    const oldIndex = getIndex(localGroupsList, activeGroup.id);
    const newIndex = getIndex(localGroupsList, over.id as string);
    setLocalGroupsList(arrayMove(localGroupsList, oldIndex, newIndex));
    if (over.id === dropZoneId && !getItemById(localGroupsList, activeGroup.id)) {
      setLocalGroupsList(addItemLast(localGroupsList, activeGroup));
    }
  };

  const handleDragEnd = (): void => {
    setActiveGroup(null);
    if (!groupsList || deepCompare(groupsList, localGroupsList) || !activeGroup) return;
    onDragEndHandler(localGroupsList, isGroupFromTable ? activeGroup : undefined);
  };

  const values = useMemo(() => ({
    localGroupsList,
  }), [
    localGroupsList,
  ]);

  return (
    <IGroupsDndProviderContext.Provider value={values}>
      <DndContext
        onDragStart={handleDragStart}
        onDragEnd={handleDragEnd}
        onDragOver={handleDragOver}
        collisionDetection={rectIntersection}
      >
        {children}

        {activeGroup
          ? createPortal(
              <DragOverlay
                dropAnimation={null}
                style={{ width: '100%' }}
              >
                {dragOverlayUi(activeGroup)}
              </DragOverlay>,
              document.body,
            )
          : null
        }
      </DndContext>
    </IGroupsDndProviderContext.Provider>
  );
};

export { GroupsDndProvider };
