import { ReactElement, createContext, useCallback, useMemo, useState } from 'react';

import { IManageModalsProviderValues } from './manageModalsProvider.interface';
import { IManageModalsActions } from './manageModalsActions.interface';
import { IManageModalsProviderProps } from './manageModalsProviderProps.interface';
import { TModalsState } from './interfaces/modalsState.type';

export const ManageModalsContext = createContext<IManageModalsProviderValues | undefined>(undefined);

export let manageModalsActions: IManageModalsActions;

const ManageModalsProvider = (props: IManageModalsProviderProps): ReactElement => {
  const { children, initialState } = props;
  const [modalsState, setModalsState] = useState<TModalsState>(initialState || {});

  const isOpen = useCallback((modalName: string): boolean => {
    if (!modalsState[modalName]?.isOpen) return false;
    return true;
  }, [modalsState]);

  const getModalData = useCallback((modalName: string): any | null => {
    if (!modalsState[modalName]?.data) return null;
    return modalsState[modalName].data;
  }, [modalsState]);

  const updateModalData = useCallback((modalName: string, data: any): void => {
    if (modalsState[modalName]) {
      setModalsState((prevState) => {
        return {
          ...prevState,
          [modalName]: {
            isOpen: modalsState[modalName].isOpen,
            data: {
              ...modalsState[modalName].data,
              ...data,
            },
          },
        };
      });
    }
  }, [modalsState]);

  const openModal = useCallback((modalName: string, modalData?: any): void => {
    setModalsState((prevState) => {
      return {
        ...prevState,
        [modalName]: {
          isOpen: true,
          data: modalData,
        },
      };
    });
  }, []);

  const closeModal = useCallback((modalName: string): void => {
    setModalsState((prevState) => {
      return {
        ...prevState,
        [modalName]: {
          isOpen: false,
          data: null,
        },
      };
    });
  }, []);

  manageModalsActions = {
    openModal,
    updateModalData,
    closeModal,
  };

  const values = useMemo(() => ({
    isOpen,
    getModalData,
  }), [
    isOpen,
    getModalData,
  ]);

  return (
    <ManageModalsContext.Provider value={values}>
      {children}
    </ManageModalsContext.Provider>
  );
};

export { ManageModalsProvider };
