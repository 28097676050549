import { alpha, styled } from '@mui/material';

import { p, rem } from '@helpers/remUtils';

export const ManageColumnsModalFooterWrapper = styled('footer')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: p(17, 20),
  borderTop: `${rem(1)} solid ${alpha(theme.palette.common.white, 0.1)}`,
}));
