import {
  updateGeneralOptionsProjectPlus,
} from '@store/thunk/generalOptionsProjectPlus/updateGeneralOptionsProjectPlus';
import { getGeneralOptionsProjectPlus } from '@store/thunk/generalOptionsProjectPlus/getGeneralOptionsProjectPlus';
import { ProjectedTargetTimeEnum } from '@pages/projectPlusModule/enums/projectedTargetTime.enum';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  filtersUpdateLocally,
  getFieldsForUpdating,
  settingsUpdateLocally,
} from './helpers/getFieldsForUpdateRequest.helper';
import { saveOptionFieldsInLocalStorage, setOptionsAfterInitiations } from './helpers/localStorageConnection.helper';
import { initialGeneralOptions, initialLocalOptions, initialOptionsOptions } from './optionsPopup.const';
import { IOptionsPopupStateState } from './interfaces/optionsPopupState.interface';
import { IGeneralOptions } from './interfaces/generalOptions.interface';

const initialState: IOptionsPopupStateState = {
  options: null,
  originOptions: null,
  loading: false,
  error: false,
  updateLoading: false,
  updateError: false,
  isSharedMode: false,
};

const optionsPopupSlice = createSlice({
  name: 'projectPlusOptions',
  initialState,
  reducers: {
    setProjectedTargetTime: (
      state: IOptionsPopupStateState,
      action: PayloadAction<ProjectedTargetTimeEnum>,
    ): void => {
      const { payload } = action;
      if (!state.options) return;
      state.options.projectedTargetTime = payload;
    },
    setExclusionThresholdEnabled: (
      state: IOptionsPopupStateState,
      action: PayloadAction<boolean>,
    ): void => {
      const { payload } = action;
      if (!state.options) return;
      state.options.exclusionThresholdEnabled = payload;
    },
    setExclusionThresholdInMinutes: (
      state: IOptionsPopupStateState,
      action: PayloadAction<number>,
    ): void => {
      const { payload } = action;
      if (!state.options) return;
      state.options.exclusionThresholdInMinutes = payload;
    },
    setStartTime: (
      state: IOptionsPopupStateState,
      action: PayloadAction<string>,
    ): void => {
      const { payload } = action;
      if (!state.options) return;
      state.options.startTime = payload;
    },
    setEndTime: (
      state: IOptionsPopupStateState,
      action: PayloadAction<string>,
    ): void => {
      const { payload } = action;
      if (!state.options) return;
      state.options.endTime = payload;
    },
    setCustomersList: (
      state: IOptionsPopupStateState,
      action: PayloadAction<number[]>,
    ): void => {
      const { payload } = action;
      if (!state.options) return;
      state.options.customerIds = payload;
    },
    setPlantsList: (
      state: IOptionsPopupStateState,
      action: PayloadAction<number[]>,
    ): void => {
      const { payload } = action;
      if (!state.options) return;
      state.options.plantIds = payload;
    },
    initSharedOptions: (
      state: IOptionsPopupStateState,
      action: PayloadAction<{
        materialTypes: string[];
        customerIds: number[];
        plantIds: number[];
      }>,
    ) => {
      const { payload } = action;
      return ({
        ...state,
        options: {
          ...initialGeneralOptions,
          ...initialLocalOptions,
          plantIds: payload.plantIds,
          customerIds: payload.customerIds,
          materialTypes: payload.materialTypes,
        },
        originOptions: {
          ...initialGeneralOptions,
          ...initialLocalOptions,
          plantIds: payload.plantIds,
          customerIds: payload.customerIds,
          materialTypes: payload.materialTypes,
        },
      });
    },
    setFleetsList: (
      state: IOptionsPopupStateState,
      action: PayloadAction<number[]>,
    ): void => {
      const { payload } = action;
      if (!state.options) return;
      state.options.fleetIds = payload;
    },
    setTonnageRange: (
      state: IOptionsPopupStateState,
      action: PayloadAction<number>,
    ): void => {
      const { payload } = action;
      if (!state.options) return;
      state.options.tonnageRange = payload;
    },
    setTrucks: (
      state: IOptionsPopupStateState,
      action: PayloadAction<number>,
    ): void => {
      const { payload } = action;
      if (!state.options) return;
      state.options.trucks = payload;
    },
    setTicketTypesList: (
      state: IOptionsPopupStateState,
      action: PayloadAction<string[]>,
    ): void => {
      const { payload } = action;
      if (!state.options) return;
      state.options.materialTypes = payload;
    },
    resetCustomersList: (state: IOptionsPopupStateState): void => {
      if (!state.options || !state.originOptions) return;
      state.options.customerIds = [];
      state.originOptions.customerIds = [];
    },
    resetPlantsList: (state: IOptionsPopupStateState): void => {
      if (!state.options || !state.originOptions) return;
      state.options.plantIds = [];
      state.originOptions.plantIds = [];
    },
    resetTicketTypesList: (state: IOptionsPopupStateState): void => {
      if (!state.options || !state.originOptions) return;
      state.options.materialTypes = [];
      state.originOptions.materialTypes = [];
    },
    resetFleetsList: (state: IOptionsPopupStateState): void => {
      if (!state.options || !state.originOptions) return;
      state.options.fleetIds = [];
      state.originOptions.fleetIds = [];
      saveOptionFieldsInLocalStorage({ fleetIds: initialOptionsOptions.fleetIds });
    },
    resetTonnageRangeList: (state: IOptionsPopupStateState): void => {
      if (!state.options || !state.originOptions) return;
      state.options.tonnageRange = initialOptionsOptions.tonnageRange;
      state.originOptions.tonnageRange = initialOptionsOptions.tonnageRange;
      saveOptionFieldsInLocalStorage({ tonnageRange: initialOptionsOptions.tonnageRange });
    },
    resetCommonChips: (state: IOptionsPopupStateState): void => {
      if (!state.options || !state.originOptions) return;
      state.options.plantIds = [];
      state.options.customerIds = [];
      state.options.materialTypes = [];
      state.originOptions.plantIds = [];
      state.originOptions.customerIds = [];
      state.originOptions.materialTypes = [];
    },
    resetSettingsOptions: (state: IOptionsPopupStateState): void => {
      if (!state.options || !state.originOptions) return;
      const defaultValues = {
        ...state.options,
        ...getFieldsForUpdating(initialOptionsOptions, settingsUpdateLocally),
      };
      state.options = defaultValues;
      state.originOptions = defaultValues;
    },
    resetFiltersOptions: (state: IOptionsPopupStateState): void => {
      if (!state.options || !state.originOptions) return;
      const defaultValues = {
        ...state.options,
        ...getFieldsForUpdating(initialOptionsOptions, filtersUpdateLocally),
      };
      state.options = defaultValues;
      state.originOptions = defaultValues;
      saveOptionFieldsInLocalStorage({ trucks: initialOptionsOptions.trucks });
      saveOptionFieldsInLocalStorage({ tonnageRange: initialOptionsOptions.tonnageRange });
      saveOptionFieldsInLocalStorage({ tonnageRange: initialOptionsOptions.fleetIds });
    },
    resetAllOptionsLocally: (state: IOptionsPopupStateState): void => {
      if (!state.options || !state.originOptions) return;
      state.options = state.originOptions;
    },
    saveFiltersOptions: (state: IOptionsPopupStateState): void => {
      if (!state.options || !state.originOptions) return;
      state.originOptions = {
        ...state.options,
        ...getFieldsForUpdating(state.options, filtersUpdateLocally),
      };
      saveOptionFieldsInLocalStorage({ tonnageRange: state.options.tonnageRange });
      saveOptionFieldsInLocalStorage({ trucks: state.options.trucks });
      saveOptionFieldsInLocalStorage({ fleetIds: state.options.fleetIds });
    },
    saveSettingsOptions: (state: IOptionsPopupStateState): void => {
      if (!state.options || !state.originOptions) return;
      state.originOptions = {
        ...state.options,
        ...getFieldsForUpdating(state.options, settingsUpdateLocally),
      };
    },
    setIsSharingMode: (state: IOptionsPopupStateState): void => {
      state.isSharedMode = true;
    },
    resetIsSharingMode: (state: IOptionsPopupStateState): void => {
      state.isSharedMode = false;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(
        getGeneralOptionsProjectPlus.pending, (
          state: IOptionsPopupStateState,
        ) => {
          state.loading = true;
        })
      .addCase(getGeneralOptionsProjectPlus.fulfilled, (
        state: IOptionsPopupStateState,
        action: PayloadAction<IGeneralOptions | null>,
      ) => {
        const { payload } = action;
        state.loading = false;
        if (!payload) return;
        state.options = setOptionsAfterInitiations(payload);
        state.originOptions = setOptionsAfterInitiations(payload);
      })
      .addCase(getGeneralOptionsProjectPlus.rejected, (state) => {
        state.options = setOptionsAfterInitiations(initialGeneralOptions);
        state.originOptions = setOptionsAfterInitiations(initialGeneralOptions);
        state.loading = false;
        state.error = true;
      })
      .addCase(updateGeneralOptionsProjectPlus.pending, (
        state: IOptionsPopupStateState,
      ) => {
        state.updateLoading = true;
      })
      .addCase(updateGeneralOptionsProjectPlus.fulfilled, (
        state: IOptionsPopupStateState,
      ) => {
        state.updateLoading = false;
      })
      .addCase(updateGeneralOptionsProjectPlus.rejected, (state) => {
        state.updateLoading = false;
        state.updateError = true;
      });
  },
});

export const {
  setTrucks,
  setEndTime,
  setStartTime,
  setPlantsList,
  setFleetsList,
  setTonnageRange,
  setCustomersList,
  setTicketTypesList,
  setProjectedTargetTime,
  setExclusionThresholdEnabled,
  setExclusionThresholdInMinutes,
  saveFiltersOptions,
  saveSettingsOptions,
  resetPlantsList,
  resetFleetsList,
  resetCommonChips,
  resetCustomersList,
  resetFiltersOptions,
  resetSettingsOptions,
  resetTicketTypesList,
  resetTonnageRangeList,
  resetAllOptionsLocally,
  initSharedOptions,
  setIsSharingMode,
  resetIsSharingMode,
} = optionsPopupSlice.actions;

export default optionsPopupSlice.reducer;
