import React, { FC } from 'react';
import { Route, Routes } from 'react-router';
import { OktaProvider, OktaStoreEnum } from '@features/oktaClient';
import { useSnackbar } from 'notistack';

import { AuthenticatePortalChecker } from '@components/authenticatePortalChecker';
import { AuthenticateLoginChecker } from '@components/authenticateLoginChecker';
import { useNavigation } from '@hooks/useNavigation/useNavigation';

import OktaAuthorizationChecker from '../oktaAuthorizationChecker/OktaAuthorizationChecker';
import { isPortalType } from './helpers/isPortalType.helper';
import { oktaApiLib } from './oktaApiLibrary.const';

const FeaturesRoutingModule = React.lazy(() => import('../../pages/FeaturesRoutingModule'));
const AuthRoutingModule = React.lazy(() => import('../../pages/auth/AuthRoutingModule'));
const NotFound = React.lazy(() => import('../../pages/notFound'));

const InitialOktaApp: FC = () => {
  const { redirect } = useNavigation();
  const { enqueueSnackbar } = useSnackbar();

  return (
    <OktaProvider
      domain={process.env.REACT_APP_API_URI || ''}
      redirectUrl="/dashboard"
      logoutRedirectUrl="/authorization"
      redirect={redirect}
      apiLibrary={oktaApiLib}
      location={OktaStoreEnum.LOCALSTORAGE}
      requestCallback={enqueueSnackbar}
    >
      <Routes>
        <Route element={isPortalType() ? <AuthenticatePortalChecker /> : <AuthenticateLoginChecker />}>
          <Route
            path="/authorization/*"
            element={<AuthRoutingModule />}
          />

          <Route
            path="*"
            element={
              <OktaAuthorizationChecker>
                <FeaturesRoutingModule />
              </OktaAuthorizationChecker>
            }
          />

          <Route
            path="*"
            element={<NotFound />}
          />
        </Route>
      </Routes>
    </OktaProvider>
  );
};

export default InitialOktaApp;
