import { isAxiosError } from 'axios';

import api from '@configs/urls/api';
import { IApiHookResponseError } from '@api/interfaces/apiHookResponseError.interface';
import { IApiHookRequestParams } from '@api/interfaces/apiHookRequestParams.interface';
import { IApiHook } from '@api/interfaces/apiHook.interface';
import { useRequest } from '@api/useRequest/useRequest';
import { httpClient } from '@okta/axiosInstance';
import { IUseTicketsReportParamsBody } from './useTicketsReportParamsBody.interface';

const useTicketsReportParams = (): IApiHook<unknown, IApiHookResponseError, unknown, IUseTicketsReportParamsBody> => {
  const { requestStatusState, makeRequest, setSuccess, setError } = useRequest();

  const makeRequestHandler = async (
    configs?: IApiHookRequestParams<unknown, IUseTicketsReportParamsBody>,
  ): Promise<void> => {
    makeRequest();
    try {
      await httpClient.post<object, unknown>({
        url: api.downloadTickets,
        config: {
          baseURL: process.env.REACT_APP_HH_ADMIN_API_URI,
        },
        postData: configs?.body,
      });

      setSuccess();
    } catch (error: unknown) {
      if (isAxiosError(error) && error.response) {
        const errorValues = error.response;
        setError({
          status: errorValues.status,
          message: errorValues.statusText,
        });

        throw new Error(error.response.data.message);
      } else {
        throw error;
      }
    }
  };

  return {
    ...requestStatusState,
    data: null,
    makeRequestHandler,
  };
};

export default useTicketsReportParams;
