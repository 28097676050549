import React, { FC } from 'react';
import useFeatureFlags from '../useFeatureFlags';

import { IFeatureFlagCheckerProps } from './featureFlagCheckerProps.interface';

const FeatureFlagChecker: FC<IFeatureFlagCheckerProps> = (props) => {
  const {
    children,
    fflagName,
    customTitle,
    inversion = false,
  } = props;
  const { isUnderFeatureFlag } = useFeatureFlags();
  const fflagChecker = inversion ? !isUnderFeatureFlag(fflagName) : isUnderFeatureFlag(fflagName);
  const customMessage = customTitle || null;

  return (
    <>
      {fflagChecker ? children : customMessage}
    </>
  );
};

export default FeatureFlagChecker;
