import { EventBusTypes } from './enums/eventBusTypes.enum';
import { EventBusCallbackType } from './interfaces/callback.interface';
import { EmptyFunction } from './interfaces/emptyFunction.interface';

type TSubscriptions = Partial<{ [key in keyof typeof EventBusTypes]: any }>;

const subscriptions: TSubscriptions = {};

export const subscribe = <T>(
  eventName: EventBusTypes,
  callback: EventBusCallbackType<T> | VoidFunction,
): EmptyFunction => {
  if (!subscriptions[eventName]) {
    Object.assign(subscriptions, { [eventName]: new Set() });
  }

  const callbacks = subscriptions[eventName];
  callbacks.add(callback);

  return (): void => {
    callbacks.delete(callback);

    if (callbacks.size === 0) {
      delete subscriptions[eventName];
    }
  };
};

export const broadcast = <T, >(
  eventName: EventBusTypes,
  arg?: T,
): void => {
  if (!subscriptions[eventName]) {
    return;
  }

  const callbacks = subscriptions[eventName];
  if (arg) {
    callbacks.forEach((callback: EventBusCallbackType<T>) => {
      callback(arg);
    });
    return;
  }
  callbacks.forEach((callback: VoidFunction) => {
    callback();
  });
};
