import React, { FC } from 'react';

import BaseLogo from '@components/baseLogo';

import { useStylesUnauthorizedHeader } from './unauthorizedHeader.theme';

const UnauthorizedHeader: FC = () => {
  const { classes } = useStylesUnauthorizedHeader();

  return (
    <header className={classes.header}>
      <BaseLogo />
    </header>
  );
};

export default UnauthorizedHeader;
