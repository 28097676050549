import { useEffect, useRef } from 'react';

import { AbstractObjectFieldsMixedAsset } from '@enums/abstractObjectField';
import { IUseChurnZero } from '@hooks/useChurnZero/useChurnZero.intreface';
import { getStorageItem, setStorageItem } from '@helpers/localStorage';
import { LocalStorageKeysEnum } from '@enums/localStorageKeys.enum';

export const useChurnZero = (): IUseChurnZero => {
  const isChurnZero = useRef<boolean>(false);
  const { REACT_APP_CHURN_ZERO_API_KEY, REACT_APP_CHURN_ZERO_MODE } = process.env || {};
  const churnZeroInitCondition = window['ChurnZero' as keyof Window] &&
    REACT_APP_CHURN_ZERO_API_KEY && REACT_APP_CHURN_ZERO_MODE === 'ON';

  useEffect(() => {
    if (churnZeroInitCondition) {
      isChurnZero.current = true;
    }
  }, []);

  const trackEvent = (
    eventName?: string,
    description?: string,
    quantity?: number,
    customFields?: AbstractObjectFieldsMixedAsset,
  ): void => {
    if (!isChurnZero.current) return;

    window['ChurnZero' as keyof Window].push([
      'trackEvent',
      eventName,
      description,
      quantity,
      customFields,
    ]);
  };

  const initChurnZero = (
    accountExternalId: number | string,
    contactExternalId: number | string,
    accountName: string,
  ): void => {
    if (!isChurnZero.current) return;

    window['ChurnZero' as keyof Window].push(['setAppKey', REACT_APP_CHURN_ZERO_API_KEY]);
    window['ChurnZero' as keyof Window].push(['setContact', accountExternalId, contactExternalId]);
    window['ChurnZero' as keyof Window].push(['setAttribute', 'account', { Name: accountName, 'Company name': accountName }]);

    if (!getStorageItem(LocalStorageKeysEnum.CHARNZERO_LOGIN)) {
      trackEvent('SCI_Web_Login');
      setStorageItem(LocalStorageKeysEnum.CHARNZERO_LOGIN, true);
    }
  };

  const stopTrackEvent = (): void => {
    if (!isChurnZero.current) return;

    trackEvent('Logout from the SupplierCI');
    window['ChurnZero' as keyof Window].push('stop');
  };

  return ({
    initChurnZero,
    trackEvent,
    stopTrackEvent,
  });
};
