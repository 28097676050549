import { styled } from '@mui/material';
import { br, m, p, rem } from '@helpers/remUtils';

export const DropdownContent = styled('div')(({ theme }) => ({
  margin: m(5, 0, 0),
  width: rem(244),
  borderRadius: br(7),
  border: `${rem(1)} solid ${theme.palette.additionalColors.divider.secondary}`,
  backgroundColor: theme.palette.additionalColors.background.secondary,
  boxShadow: `0px 10px 32px 0px ${theme.palette.additionalColors.boxShadow.main}`,
  '.dropdown-content-header': {
    padding: p(17, 24, 13),
    borderBottom: `${rem(1)} solid ${theme.palette.additionalColors.divider.secondary}`,
  },
  '.time-pickers-wrapper': {
    alignItems: 'center',
    padding: p(4, 0, 2),
  },
  '.time-picker-wrapper': {
    display: 'flex',
    padding: p(8, 25, 18),
    '&:not(:last-child)': {
      borderBottom: `${rem(1)} solid ${theme.palette.additionalColors.divider.secondary}`,
    },
  },
  '.dropdown-content-footer': {
    display: 'flex',
    justifyContent: 'flex-end',
    padding: p(8),
    borderTop: `${rem(1)} solid ${theme.palette.additionalColors.divider.secondary}`,
  },
}));
