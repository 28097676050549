import { FC, useCallback } from 'react';
import { IconButton } from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useTranslation } from 'react-i18next';

import { useGlobalButtonStyles, useGlobalContainerStyles, useGlobalFlexStyles } from '@assets/globalStyles';

import useStylesRefreshPageNotificationList from './refreshPageNotificationList.theme';
import { IRefreshPageNotificationList } from './refreshPageNotificationList.interface';
import { refreshPageNotificationButtonTestId, warningMessagesConfig } from './refreshPageNotificationList.const';

const RefreshPageNotificationList: FC<IRefreshPageNotificationList> = (props) => {
  const { refreshWarnings } = props;
  const { classes: containerClasses } = useGlobalContainerStyles();
  const { classes: buttonClasses } = useGlobalButtonStyles();
  const { classes: flexClasses } = useGlobalFlexStyles();
  const { classes, cx } = useStylesRefreshPageNotificationList();
  const { t } = useTranslation('informationMessages');

  const refreshPage = useCallback((): void => {
    window.location.reload();
  }, []);

  return (
    <ul
      className={cx(
        containerClasses.resetList,
        classes.refreshPageNotificationList,
      )}
    >
      {refreshWarnings.map((warningType) => {
        return (
          <li
            key={warningType}
            className={cx(
              containerClasses.resetListItem,
              flexClasses.flexRowItemCenter,
              classes.refreshPageNotification,
            )}
          >
            <h3 className={classes.refreshPageNotificationMessage}>
              {t(warningMessagesConfig[warningType], { ns: 'informationMessages' })}
            </h3>

            <IconButton
              className={cx(
                buttonClasses.buttonHoverEffect,
                classes.refreshPageNotificationButton,
              )}
              data-testid={refreshPageNotificationButtonTestId}
              onClick={refreshPage}
            >
              <RefreshIcon />
            </IconButton>
          </li>
        );
      })}
    </ul>
  );
};

export default RefreshPageNotificationList;
