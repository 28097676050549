import { FC } from 'react';
import ShortcutIcon from '@mui/icons-material/Shortcut';
import LockIcon from '@mui/icons-material/Lock';

import { hiddenColumnsListId, visibleColumnsListId } from '../../manageColumnsModalContent.const';
import { DndListItemContentWrapper } from './dndListItemContent.theme';
import { IDndListItemContentProps } from './dndListItemContent.interface';

const DndListItemContent: FC<IDndListItemContentProps> = (props) => {
  const {
    item,
    containerId,
    isDragging,
    disabled,
    useDragOverlay,
  } = props;

  return (
    <DndListItemContentWrapper
      isDragging={isDragging}
      useDragOverlay={useDragOverlay}
    >
      {!disabled && containerId === visibleColumnsListId
        ? <ShortcutIcon className="default-icon disable-icon dragging-icon" />
        : null
      }

      {!disabled && containerId === hiddenColumnsListId
        ? <ShortcutIcon className="default-icon disable-icon hidden-icon dragging-icon" />
        : null
      }

      {disabled
        ? <LockIcon className="default-icon disable-icon" />
        : null
      }

      <span className="content-label">
        {item.label}
      </span>
    </DndListItemContentWrapper>
  );
};

export default DndListItemContent;
