import { FC } from 'react';

import { TabsList } from '@features/haulhubUi';

import { BoxWithVerticalTabsWrapper } from './boxWithVerticalTabs.theme';
import { IBoxWithVerticalTabsProps } from './boxWithVerticalTabs.interface';

const BoxWithVerticalTabs: FC<IBoxWithVerticalTabsProps> = (props) => {
  const {
    children,
    activeTab,
    tabsListConfig,
    onTabChangeHandler,
  } = props;

  return (
    <BoxWithVerticalTabsWrapper>
      <TabsList
        activeTab={activeTab}
        tabsListConfig={tabsListConfig}
        tabsProps={{
          orientation: 'vertical',
        }}
        onTabChangeHandler={onTabChangeHandler}
      />

      <div className="tab-content-wrapper">
        {children}
      </div>
    </BoxWithVerticalTabsWrapper>
  );
};

export { BoxWithVerticalTabs };
