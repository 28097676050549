import { ListItem, styled } from '@mui/material';
import { p, rem } from '@helpers/remUtils';

export const GroupDropdownItemWrapper = styled(ListItem)(() => ({
  '&:not(:last-child)': {
    marginBottom: rem(5),
  },
  '.dropdown-list-item-button': {
    justifyContent: 'left',
    padding: p(8, 0),
    width: '100%',
    textAlign: 'left',
    fontSize: rem(14),
    fontWeight: 400,
  },
}));
