import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IActiveEntity } from '@interfaces/selectedEntity/activeEntity.interface';

import { IRecentlyViewedSliceState } from './recentlyViewedSliceState.interface';

const initialState: IRecentlyViewedSliceState = {
  selectedRecentlyViewed: null,
};

const recentlyViewedSlice = createSlice({
  name: 'recentlyViewed',
  initialState,
  reducers: {
    setSelectedRecentlyViewed(
      state: IRecentlyViewedSliceState,
      action: PayloadAction<IActiveEntity | null>,
    ): void {
      const { payload } = action;
      state.selectedRecentlyViewed = payload;
    },
  },
});

export const { setSelectedRecentlyViewed } = recentlyViewedSlice.actions;
export default recentlyViewedSlice.reducer;
