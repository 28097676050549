import { p, rem } from '@helpers/remUtils';
import { makeStyles } from '@theme/appTheme';

const useStylesBaseToaster = makeStyles()((theme) => ({
  baseToasterRoot: {
    display: 'flex',
    alignItems: 'center',
    width: rem(400),
    padding: p(14, 16, 14, 20),
    borderRadius: rem(4),
    borderLeft: `${rem(4)} solid #27AE60`,
    backgroundColor: theme.palette.common.white,
    color: '#3B3B3B',
    '&.success': {
      borderLeft: `${rem(4)} solid #27AE60`,
    },
    '&.error': {
      borderLeft: `${rem(4)} solid #E96666`,
    },
  },
  baseToasterMessage: {
    fontSize: rem(13),
    fontWeight: 600,
  },
  baseToasterIcon: {
    minWidth: rem(16),
    height: rem(16),
    marginRight: rem(15),
  },
}));

export default useStylesBaseToaster;
