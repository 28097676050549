import eventBus from '@eventBus/index';

import { EventBusTypes } from '../../enums/eventBusTypes.enum';
import { EventBusCallbackType } from '../../interfaces/callback.interface';
import { EmptyFunction } from '../../interfaces/emptyFunction.interface';
import { IUpdateEntityDetailsListProps } from './updateEntityDetailsList.interface';

const eventName = EventBusTypes.UPDATE_ENTITY_DETAILS_LIST;

const subscribe = (
  callback: EventBusCallbackType<IUpdateEntityDetailsListProps>
): EmptyFunction => {
  return eventBus.subscribe<IUpdateEntityDetailsListProps>(eventName, callback);
};

const broadcast = (data: IUpdateEntityDetailsListProps): void => {
  eventBus.broadcast<IUpdateEntityDetailsListProps>(eventName, data);
};

export default {
  subscribe,
  broadcast,
};
