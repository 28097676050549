import { INotification } from './notifications.type';
import NamesEnum from './notificationsFieldNames.enum';
import NotificationTypeEnum from './notificationsTypes.enum';
import texts from './texts';

const notifications: { [key in NamesEnum]: INotification } = {
  [NamesEnum.copiedInfo]: {
    body: texts[NamesEnum.copiedInfo],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.commonError]: {
    body: texts[NamesEnum.commonError],
    type: NotificationTypeEnum.error,
  },
  [NamesEnum.addFavoriteWidget]: {
    body: texts[NamesEnum.addFavoriteWidget],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.removeFavoriteWidget]: {
    body: texts[NamesEnum.removeFavoriteWidget],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.removeFavoriteWidgetDashboard]: {
    body: texts[NamesEnum.removeFavoriteWidgetDashboard],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.noEntityFound]: {
    body: texts[NamesEnum.noEntityFound],
  },
  [NamesEnum.noFound]: {
    body: texts[NamesEnum.noFound],
  },
  [NamesEnum.pageNotFound]: {
    body: texts[NamesEnum.pageNotFound],
  },
  [NamesEnum.reportsNotFound]: {
    body: texts[NamesEnum.reportsNotFound],
  },
  [NamesEnum.widgetFiltersNotFound]: {
    body: texts[NamesEnum.widgetFiltersNotFound],
  },
  [NamesEnum.datePickerStartDateError]: {
    body: texts[NamesEnum.datePickerStartDateError],
  },
  [NamesEnum.datePickerEndDateError]: {
    body: texts[NamesEnum.datePickerEndDateError],
  },
  [NamesEnum.somethingWentWrong]: {
    body: texts[NamesEnum.somethingWentWrong],
  },
  [NamesEnum.fieldRequired]: {
    body: texts[NamesEnum.fieldRequired],
  },
  [NamesEnum.firstNameInvalidData]: {
    body: texts[NamesEnum.firstNameInvalidData],
  },
  [NamesEnum.emailInvalidData]: {
    body: texts[NamesEnum.emailInvalidData],
  },
  [NamesEnum.passwordInvalidData]: {
    body: texts[NamesEnum.passwordInvalidData],
  },
  [NamesEnum.confirmationPassword]: {
    body: texts[NamesEnum.confirmationPassword],
  },
  [NamesEnum.lastNameInvalidData]: {
    body: texts[NamesEnum.lastNameInvalidData],
  },
  [NamesEnum.existedEmail]: {
    body: texts[NamesEnum.existedEmail],
  },
  [NamesEnum.archiveUser]: {
    body: texts[NamesEnum.archiveUser],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.savedUserRoles]: {
    body: texts[NamesEnum.savedUserRoles],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.resetPassword]: {
    body: texts[NamesEnum.resetPassword],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.saveCurrentUser]: {
    body: texts[NamesEnum.saveCurrentUser],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.restoreUser]: {
    body: texts[NamesEnum.restoreUser],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.maxLength]: {
    body: texts[NamesEnum.maxLength],
  },
  [NamesEnum.minLength]: {
    body: texts[NamesEnum.minLength],
  },
  [NamesEnum.phoneNumberFormat]: {
    body: texts[NamesEnum.phoneNumberFormat],
  },
  [NamesEnum.saveRolePermissions]: {
    body: texts[NamesEnum.saveRolePermissions],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.createNewRole]: {
    body: texts[NamesEnum.createNewRole],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.editRole]: {
    body: texts[NamesEnum.editRole],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.singleUserCreated]: {
    body: texts[NamesEnum.singleUserCreated],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.multipleUsersCreated]: {
    body: texts[NamesEnum.multipleUsersCreated],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.updateRoleStatus]: {
    body: texts[NamesEnum.updateRoleStatus],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.deleteSavedSearch]: {
    body: texts[NamesEnum.deleteSavedSearch],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.addedSavedSearch]: {
    body: texts[NamesEnum.addedSavedSearch],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.clearSavedSearches]: {
    body: texts[NamesEnum.clearSavedSearches],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.permissionRestriction]: {
    body: texts[NamesEnum.permissionRestriction],
    type: NotificationTypeEnum.error,
  },
  [NamesEnum.addedCustomerToWatchlist]: {
    body: texts[NamesEnum.addedCustomerToWatchlist],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.removeCustomerToWatchlist]: {
    body: texts[NamesEnum.removeCustomerToWatchlist],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.addedPlantToWatchlist]: {
    body: texts[NamesEnum.addedPlantToWatchlist],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.removePlantToWatchlist]: {
    body: texts[NamesEnum.removePlantToWatchlist],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.addedProjectToWatchlist]: {
    body: texts[NamesEnum.addedProjectToWatchlist],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.removeProjectToWatchlist]: {
    body: texts[NamesEnum.removeProjectToWatchlist],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.noDataAvailable]: {
    body: texts[NamesEnum.noDataAvailable],
    type: NotificationTypeEnum.error,
  },
  [NamesEnum.removeCustomWidget]: {
    body: texts[NamesEnum.removeCustomWidget],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.createWidget]: {
    body: texts[NamesEnum.createWidget],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.updateWidget]: {
    body: texts[NamesEnum.updateWidget],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.moveWidget]: {
    body: texts[NamesEnum.moveWidget],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.changeRoleSuccess]: {
    body: texts[NamesEnum.changeRoleSuccess],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.archiveUserSuccess]: {
    body: texts[NamesEnum.archiveUserSuccess],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.restoreUserSuccess]: {
    body: texts[NamesEnum.restoreUserSuccess],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.enableFeatureFlag]: {
    body: texts[NamesEnum.enableFeatureFlag],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.disableFeatureFlag]: {
    body: texts[NamesEnum.disableFeatureFlag],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.editUserSuccess]: {
    body: texts[NamesEnum.editUserSuccess],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.loginSuccess]: {
    body: texts[NamesEnum.loginSuccess],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.logoutSuccess]: {
    body: texts[NamesEnum.logoutSuccess],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.resetPasswordSuccess]: {
    body: texts[NamesEnum.resetPasswordSuccess],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.addToTrackedProjectPlus]: {
    body: texts[NamesEnum.addToTrackedProjectPlus],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.updateTargetTrackedProjectPlus]: {
    body: texts[NamesEnum.updateTargetTrackedProjectPlus],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.removeProjectPlusFromTrackedList]: {
    body: texts[NamesEnum.removeProjectPlusFromTrackedList],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.updateTargetTimeProjectPlus]: {
    body: texts[NamesEnum.updateTargetTimeProjectPlus],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.updateTrucksNumberProjectPlus]: {
    body: texts[NamesEnum.updateTrucksNumberProjectPlus],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.hiddenProjectPlus]: {
    body: texts[NamesEnum.hiddenProjectPlus],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.unhiddenProjectPlus]: {
    body: texts[NamesEnum.unhiddenProjectPlus],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.excludeEntity]: {
    body: texts[NamesEnum.excludeEntity],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.includeEntity]: {
    body: texts[NamesEnum.includeEntity],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.emailInvalidSymbol]: {
    body: texts[NamesEnum.emailInvalidSymbol],
  },
  [NamesEnum.selectValueFromList]: {
    body: texts[NamesEnum.selectValueFromList],
  },
  [NamesEnum.createBid]: {
    body: texts[NamesEnum.createBid],
  },
  [NamesEnum.editBid]: {
    body: texts[NamesEnum.editBid],
  },
  [NamesEnum.deleteBid]: {
    body: texts[NamesEnum.deleteBid],
  },
  [NamesEnum.notValidUrl]: {
    body: texts[NamesEnum.notValidUrl],
    type: NotificationTypeEnum.error,
  },
  [NamesEnum.epdTicketTypeExists]: {
    body: texts[NamesEnum.epdTicketTypeExists],
    type: NotificationTypeEnum.error,
  },
  [NamesEnum.epdCreatingSuccess]: {
    body: texts[NamesEnum.epdCreatingSuccess],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.epdEditingSuccess]: {
    body: texts[NamesEnum.epdEditingSuccess],
    type: NotificationTypeEnum.success,
  },
  [NamesEnum.blockUser]: {
    body: texts[NamesEnum.blockUser],
  },
  [NamesEnum.blockMaterialProducer]: {
    body: texts[NamesEnum.blockMaterialProducer],
  },
  [NamesEnum.gwpTotalValidation]: {
    body: texts[NamesEnum.gwpTotalValidation],
  },
  [NamesEnum.makeRequestOnNeRoleSuccess]: {
    body: texts[NamesEnum.makeRequestOnNeRoleSuccess],
    type: NotificationTypeEnum.success,
  },
};

export default notifications;
