import React, { FC, useEffect, useRef } from 'react';

import { AnimationDirection } from '@enums/animationDirection';

import Portal from '../portal/Portal';
import { IModalProps } from './modalProps.interface';
import { portalBackdropTestId } from './modal.const';
import { PopupStyleTypeEnum } from './enums/popupStyleTypeEnum.enum';
import { popupStyleTypes } from './settings/popupStyleTypes.settings';

const Modal: FC<IModalProps> = (props) => {
  const {
    open,
    locked = false,
    mode = PopupStyleTypeEnum.DEFAULT,
    animationDirection = AnimationDirection.UP,
    externalClasses = {},
    onCloseHandler,
    children,
    zIndex,
  } = props;
  const { classes: modalMode, cx } = popupStyleTypes[mode](animationDirection);
  const [active, setActive] = React.useState(false);
  const backdropRef = useRef<HTMLElement | null>(null);

  const escapeKeyHandler = (event: KeyboardEvent): void => {
    if (onCloseHandler && !locked && event.key === 'Escape') {
      onCloseHandler();
    }
  };

  const onBackdropClick = (event: any): void => {
    if (onCloseHandler && !locked && event.target === backdropRef.current) {
      onCloseHandler();
    }
  };

  useEffect(() => {
    const { current } = backdropRef;
    if (current) {
      current.addEventListener('click', onBackdropClick);
      window.addEventListener('keyup', escapeKeyHandler);
    }
    if (open) {
      setTimeout(() => {
        setActive(open);
      }, 10);
    }
    return (): void => {
      if (current) {
        current.removeEventListener('click', onBackdropClick);
      }
      setActive(false);
      window.removeEventListener('keyup', escapeKeyHandler);
    };
  }, [open]);

  return (
    <>
      {open && (
        <Portal>
          <section
            ref={backdropRef}
            className={cx(
              modalMode.modalBackdrop,
              externalClasses ? externalClasses['modalBackdrop'] : null,
              open && active ? 'active' : null,
            )}
            style={{ zIndex: zIndex || 999996 }}
            data-testid={portalBackdropTestId}
          >
            <div
              className={cx(
                modalMode.modalContent,
                externalClasses ? externalClasses['modalContent'] : null,
              )}
            >
              {children}
            </div>
          </section>
        </Portal>
      )}
    </>
  );
};

export default Modal;
