import { v4 as uuidv4 } from 'uuid';
import { TFunction } from 'i18next';

import { ExperienceType } from '@enums/experience.enum';
import { IExperienceSwitcherConfig } from '../interfaces/experienceSwitcherConfig.interface';

export const getExperienceSwitcherConfig = (
  t: TFunction<('experienceSwitcher' | 'roles')[], undefined>
  ): IExperienceSwitcherConfig[] => {
  return [
    {
      id: uuidv4(),
      experience: ExperienceType.ALL,
      title: t('all_of_salesslip'),
      description: t('all_of_salesslip_description'),
    },
    {
      id: uuidv4(),
      experience: ExperienceType.PROJECTS_PLUS,
      title: t('projects_only', { ns: 'roles' }),
      description: t('projects_only_description'),
    },
  ];
};
