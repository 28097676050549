const indentConvertor = (indent: number[] | undefined): string => {
  if (indent) {
    let newIndent = '';

    for (let i = 0; i < indent.length; i++) {
      newIndent += ` ${indent[i]}px`;
    }

    return newIndent;
  } else {
    return '0';
  }
};

export default indentConvertor;
