import { List, styled } from '@mui/material';

import { rem } from '@helpers/remUtils';

export const ChipsListWrapper = styled(List)(() => ({
  display: 'flex',
  flexWrap: 'wrap',
  maxHeight: `calc(100vh - ${rem(323)})`,
  overflowY: 'auto',

  '.chips-list-item': {
    width: 'auto',
    marginBottom: rem(15),
    '&:not(:last-child)': {
      marginRight: rem(15),
    },
  },

  '.loading-wrapper': {
    justifyContent: 'center',
  },

  '.empty-state': {
    justifyContent: 'center',
  },
}));
