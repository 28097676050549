import { createAsyncThunk } from '@reduxjs/toolkit';
import { stringify } from 'qs';

import {
  IMutateProjectPlusValues,
} from '@store/projectPlus/manageProjectPlusSlice/interfaces/manageProjectPlusValues.interface';
import { IRawProjectPlus } from '@pages/projectPlusModule/pages/live/interfaces/projectPlus.interface';
import api from '@configs/urls/api';

import instance from '../instanceDefiner';

export const mutateProjectPlusRequest = createAsyncThunk(
  'manageProjectPlusSlice/mutateProjectPlusRequest',
  async (inputParams: Omit<IMutateProjectPlusValues, 'uniqueId'>) => {
    try {
      const response = await instance.patch<IRawProjectPlus>(
        api.mutateProjectPlus,
        { ...inputParams },
        {
          paramsSerializer: {
            serialize: params => stringify(params, { encode: true }),
          },
        }
      );
      return response.data;
    } catch {
      return Promise.reject();
    }
  },
);
