export const overrideSystemHandling = (event: KeyboardEvent): void => {
  if (event) {
    if (event.preventDefault) event.preventDefault();
    if (event.stopPropagation) {
      event.stopPropagation();
    } else if (window.event) {
      window.event.cancelBubble = true;
    }
  }
};
