import { styled } from '@mui/material';

import { m, p, rem } from '@helpers/remUtils';

export const FlyoutPopupWrapper = styled('div')(({ theme }) => ({
  width: rem(710),
  height: '100vh',

  '.flyout-popup-header': {
    backgroundColor: theme.palette.additionalColors.background.main,
    borderBottom: `${rem(1)} solid ${theme.palette.additionalColors.divider.primary}`,
  },

  '.title-wrapper': {
    display: 'flex',
    alignItems: 'center',
    padding: p(14, 14),
  },

  '.title': {
    margin: 0,
    fontSize: rem(18),
    fontWeight: 700,
  },

  '.close-popup-icon-button': {
    margin: m(0, 14, 0, 0),
  },

  '.flyout-popup-content': {
    height: `calc(100vh - ${rem(173)})`,
  },

  '.flyout-popup-footer': {
    display: 'flex',
    justifyContent: 'space-between',
    padding: p(19, 95, 19, 29),
    borderTop: `${rem(1)} solid ${theme.palette.additionalColors.divider.primary}`,
  },
}));
