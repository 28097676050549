import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getProjectPlusCounters } from '@store/thunk/projectPlusCounters/getProjectPlusCounters';
import { IProjectPlusCountersResponse } from '@store/thunk/projectPlusCounters/projectPlusCounters.interface';

import { IProjectPlusCountersSlice } from './projectPlusCounters.interface';

const initialState: IProjectPlusCountersSlice = {
  counters: {
    untrackedCount: undefined,
    trackedCount: undefined,
  },
  loading: false,
};

export const projectPlusCountersSlice = createSlice({
  name: 'projectPlusCounters',
  initialState,
  reducers: {
    increaseUntrackedCount(
      state: IProjectPlusCountersSlice,
    ): void {
      const value = state.counters.untrackedCount;
      if (value === undefined) return;
      state.counters.untrackedCount = value + 1;
    },
    decreaseUntrackedCount(
      state: IProjectPlusCountersSlice,
    ): void {
      const value = state.counters.untrackedCount;
      if (value && value !== 0) {
        state.counters.untrackedCount = value - 1;
      }
    },
    increaseTrackedCount(
      state: IProjectPlusCountersSlice,
    ): void {
      const value = state.counters.trackedCount;
      if (value === undefined) return;
      state.counters.trackedCount = value + 1;
    },
    decreaseTrackedCount(
      state: IProjectPlusCountersSlice,
    ): void {
      const value = state.counters.trackedCount;
      if (value && value !== 0) {
        state.counters.trackedCount = value - 1;
      }
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getProjectPlusCounters.pending, (state: IProjectPlusCountersSlice, { meta }) => {
        const isCurrentShortPolling = meta.arg?.isShortPolling;
        state.loading = !isCurrentShortPolling;
      })
      .addCase(getProjectPlusCounters.fulfilled, (
        state: IProjectPlusCountersSlice,
        action: PayloadAction<IProjectPlusCountersResponse | null>,
      ) => {
        const { payload } = action;
        state.loading = false;
        if (!payload) return;
        state.counters = payload;
      });
  },
});
export const {
  increaseUntrackedCount,
  decreaseUntrackedCount,
  increaseTrackedCount,
  decreaseTrackedCount,
} = projectPlusCountersSlice.actions;
export default projectPlusCountersSlice.reducer;
