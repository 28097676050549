import { FC, MouseEvent, ReactElement, useState } from 'react';
import { Button, Popper, ClickAwayListener, Fade, Tooltip, List } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { IColumPreference } from '@interfaces/calibration/storedTablePreference.interface';
import CancelIcon from '@mui/icons-material/Cancel';

import { ButtonsWrapper, DropdownContent } from './groupDropdown.theme';
import { IGroupDropdownProps } from './groupDropdown.interface';
import { groupDropdownTexts } from './groupDropdown.const';
import GroupDropdownItem from './components/groupDropdownItem/GroupDropdownItem';

const GroupDropdown: FC<IGroupDropdownProps> = (props) => {
  const {
    dropdownList,
    disabled,
    options,
    addNewGroup,
    clearGroups,
  } = props;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const {
    label,
    dropdownButtonTooltip,
    resetGroupsButtonTooltip,
  } = options || {};

  const toggleIsDropdownOpenHandler = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
    setIsDropdownOpen((previousOpen) => !previousOpen);
  };

  const closeDropdownHandler = (): void => {
    setIsDropdownOpen(false);
  };

  const addNewGroupClickEvent = async (group: IColumPreference | undefined): Promise<void> => {
    if (!group) return;
    await addNewGroup(group);
    closeDropdownHandler();
  };

  return (
    <>
      <ButtonsWrapper>
        <Tooltip
          title={dropdownButtonTooltip || groupDropdownTexts.DROPDOWN_BUTTON_TOOLTIP}
          disableFocusListener={!disabled}
          disableHoverListener={!disabled}
          disableTouchListener={!disabled}
        >
          <span>
            <Button
              variant="rounded"
              color="thirdly"
              size="small"
              endIcon={<ArrowDropDownIcon />}
              disabled={disabled}
              onClick={toggleIsDropdownOpenHandler}
            >
              {label || groupDropdownTexts.BUTTON_LABEL}
            </Button>
          </span>
        </Tooltip>

        {clearGroups
          ? <Tooltip title={resetGroupsButtonTooltip || groupDropdownTexts.RESET_GROUPS_BUTTON_TOOLTIP}>
              <Button
                className="reset-button"
                onClick={clearGroups}
              >
                <CancelIcon
                  fontSize='inherit'
                  color="inherit"
                />
              </Button>
            </Tooltip>
          : null
        }
      </ButtonsWrapper>

      <Popper
        open={isDropdownOpen}
        anchorEl={anchorEl}
        transition={true}
        placement="bottom-start"
        sx={{
          zIndex: 5,
        }}
      >
        {({ TransitionProps }): ReactElement => (
          <ClickAwayListener onClickAway={closeDropdownHandler}>
            <Fade
              {...TransitionProps}
              timeout={350}
            >
              {/* Fade don`t work with styled component */}
              <DropdownContent>
                <List className='group-dropdown-list'>
                  {dropdownList?.map((group) => {
                    return (
                      <GroupDropdownItem
                        key={group.id}
                        group={group}
                        addNewGroupClickEvent={addNewGroupClickEvent}
                      />
                    );
                  })}
                </List>
              </DropdownContent>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export { GroupDropdown };
