import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AbstractObjectField } from '@enums/abstractObjectField';
import { getProjectPlusOne } from '@store/thunk/projectPlusOne/getProjectPlusOne';
import { IProjectPlusOneResponse } from '@store/thunk/projectPlusOne/projectPlusOne.interface';
import { ISelectedProjectPlus } from '@pages/projectPlusModule/pages/live/interfaces/selectedProjectPlus.interface';
// eslint-disable-next-line max-len
import { convertToSelectedProjectPlus } from '@pages/projectPlusModule/pages/live/components/rightSideBarProjectPlus/helpers/convertToSelectedProjectPlus.helper';
import { IProjectPlus } from '@pages/projectPlusModule/pages/live/interfaces/projectPlus.interface';

import { ISelectedProjectPlusItemSlice } from './selectedProjectPlusItemSlice.interface';

const initialState: ISelectedProjectPlusItemSlice = {
  selectedProjectPlus: null,
  loading: false,
  error: false,
};

const selectedProjectPlusItemSlice = createSlice({
  name: 'selectedProjectPlusItem',
  initialState,
  reducers: {
    addSelectedProjectPlus(
      state: ISelectedProjectPlusItemSlice,
      action: PayloadAction<ISelectedProjectPlus | null>,
    ): void {
      const { payload } = action;
      state.selectedProjectPlus = payload;
    },
    clearSelectedProjectPlus(state: ISelectedProjectPlusItemSlice): void {
      state.selectedProjectPlus = null;
    },
    selectedProjectPlusUpdating(
      state: ISelectedProjectPlusItemSlice,
      action: PayloadAction<AbstractObjectField>,
    ): void {
      const { payload } = action;
      if (!state.selectedProjectPlus) return;
      state.selectedProjectPlus = { ...state.selectedProjectPlus, ...payload };
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getProjectPlusOne.pending, (state, { meta }) => {
        const isCurrentShortPolling = meta.arg?.isShortPolling;
        state.loading = !isCurrentShortPolling;
        state.error = false;
      })
      .addCase(getProjectPlusOne.fulfilled, (
        state: ISelectedProjectPlusItemSlice,
        action: PayloadAction<IProjectPlusOneResponse | null>,
      ) => {
        const { payload } = action;
        const newSelectedProjectPlusData = {
          ...state.selectedProjectPlus,
          ...payload,
        };
        if (!payload) return;
        state.selectedProjectPlus = convertToSelectedProjectPlus(newSelectedProjectPlusData as IProjectPlus);
        state.loading = false;
        state.error = false;
      })
      .addCase(getProjectPlusOne.rejected, (state) => {
        state.loading = false;
        state.error = true;
      });
  },
});

export const {
  addSelectedProjectPlus,
  clearSelectedProjectPlus,
  selectedProjectPlusUpdating,
} = selectedProjectPlusItemSlice.actions;
export default selectedProjectPlusItemSlice.reducer;
