
const isItemExist = <T>(
  array: T[],
  item: T,
  fieldName?: keyof T,
): boolean => {
  const currentFieldName = fieldName || 'id';
  return array.some((arrayItem: T) => arrayItem[currentFieldName as keyof T] === item[currentFieldName as keyof T]);
};

const isItemFieldExist = <T>(
  array: T[],
  value: T[keyof T],
  fieldName?: keyof T,
): boolean => {
  const currentFieldName = fieldName || 'id';
  return array.some(arrayItem => arrayItem[currentFieldName as keyof T] === value);
};

const isPrimitiveItemExist = <T>(
  array: T[],
  item: T,
): boolean => {
  return array.includes(item);
};

const removePrimitiveItem = <T>(
  array: T[],
  removedItem: T,
): T[] => {
  return array.filter(item => removedItem !== item);
};

const filterPrimitiveItem = <T>(
  array: T[],
  removedItem: T,
): T[] => {
  return array.filter(item => removedItem === item);
};

const removeItem = <T>(
  array: T[],
  value: T[keyof T],
  fieldName?: keyof T,
): T[] => {
  const currentFieldName = fieldName || 'id';
  return array.filter(item => item[currentFieldName as keyof T] !== value);
};

const filterItem = <T>(
  array: T[],
  value: T[keyof T],
  fieldName?: keyof T,
): T[] => {
  const currentFieldName = fieldName || 'id';
  return array.filter(item => item[currentFieldName as keyof T] === value);
};

const removeLastItem = <T>(array: T[]): T[] => {
  return array.slice(0, -1);
};

const replaceItem = <T>(
  array: T[],
  item: T,
  fieldName?: keyof T,
): T[] => {
    const currentFieldName = fieldName || 'id';
    const newArray = [...array];
    const foundIndex = array.findIndex(itemFromList => item[currentFieldName as keyof T] === itemFromList[currentFieldName as keyof T]);
    newArray[foundIndex] = item;
    return newArray;
};

const replaceFullItem = <T>(
  array: T[],
  id: T[keyof T],
  item: T,
  fieldName?: keyof T,
): T[] => {
    const currentFieldName = fieldName || 'id';
    const newArray = [...array];
    const foundIndex = array.findIndex(itemFromList => id === itemFromList[currentFieldName as keyof T]);
    newArray[foundIndex] = item;
    return newArray;
};

const isItemFromListObject = <T>(currentItemFromList: T, fieldName: keyof T | 'id'): T[keyof T] | T => {
  if (currentItemFromList && typeof currentItemFromList === 'object') return currentItemFromList[fieldName as keyof T];
  return currentItemFromList;
};

const getIndex = <T>(
  array: T[],
  id: T[keyof T] | T,
  fieldName?: keyof T,
): number => {
  const currentFieldName = fieldName || 'id';
  const foundIndex = array.findIndex(itemFromList => id === isItemFromListObject(itemFromList, currentFieldName));

  return foundIndex;
};

const getArrayByField = <T, P>(
  array: T[],
  fieldName?: keyof T,
): P[] => {
  const currentFieldName = fieldName || 'id';
  return array.map((item: T) => item[currentFieldName as keyof T] as P);
};

const getItemById = <T>(
  array: T[],
  itemId: number | string,
  fieldName?: keyof T,
): T | undefined => {
  const currentFieldName = fieldName || 'id';
  return array.find((item: T) => item[currentFieldName as keyof T] === itemId);
};

const addItemLast = <T>(
  array: T[],
  item: T,
): T[] => {
  return [...array, item];
};

const addItemFirst = <T>(
  array: T[],
  item: T,
): T[] => {
  return [item, ...array];
};

const removeDuplicateObjects = <T, Key extends keyof T>(data: T[], key: Key): T[] => {
  const callback = (item: T): T[Key] => item[key];

  return [
    ...new Map(
      data.map((item: T) => [callback(item), item])
    ).values()
  ];
};

const removeDuplicatesFromPrimitiveArray = <T>(data: T[]): T[] => {
  return [...new Set(data)];
};

const isSomeExist = function<T>(
  checkedArray: T[],
  checkingItemsArray: T[],
): boolean {
  return checkedArray.some((item: T) => checkingItemsArray.includes(item));
};

const changeItemPosition = <T, >(
  arr: T[],
  element: T,
  fromIndex: number,
  toIndex: number,
): T[] => {
  const duplicationArray = [...arr];
  duplicationArray.splice(fromIndex, 1);
  duplicationArray.splice(toIndex, 0, element);
  return duplicationArray;
};

const manageItemStateIntoArray = <T>(array: T[], item: T): T[] => {
  if (isPrimitiveItemExist(array, item)) {
    return removePrimitiveItem(array, item);
  }

  return addItemLast(array, item);
};

const arrayCutter = <T>(
  data: T[],
  number: number,
  startIndex = 0
): T[] => {
  return data.slice(startIndex, number);
};

const lastIndexNumber = <T, >(array: T[]): number => {
  return array.length - 1;
};

const changeListItemHandler = <T>(
  listItem: T[],
  item: T,
  id: T[keyof T],
  fieldName?: keyof T,
): T[] => {
  const currentFieldName = fieldName || 'id';
  if (isItemExist(listItem, item, currentFieldName as keyof T)) {
    return removeItem(listItem, id, currentFieldName as keyof T);
  }

  return addItemLast(listItem, item);
};

const arraysAreEqual = <T>(arr1: T[], arr2: T[], fieldName?: keyof T): boolean => {
  const currentFieldName = fieldName || 'id' as keyof T;

  if (arr1.length !== arr2.length) {
    return false;
  }

  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i][currentFieldName] !== arr2[i][currentFieldName]) {
      return false;
    }
  }

  return true;
};

const insertItemIntoSortedArray = <T>(sortedArray: T[], newItem: T, fieldName: keyof T): T[] => {
  const index = sortedArray.findIndex((item) => newItem[fieldName] <= item[fieldName]);
  sortedArray.splice(index !== -1 ? index : sortedArray.length, 0, newItem);
  return sortedArray;
};

const filterArrayBySearch = <T>(
  array: T[],
  searchQuery: string,
  fieldName: keyof T,
): T[] => {
  return array.filter((item) => {
    const fieldValue = item[fieldName];
    if (typeof fieldValue === 'string') {
      return fieldValue.toLowerCase().includes(searchQuery.toLowerCase());
    }
    return false;
  });
};

export {
  removeItem,
  filterItem,
  isItemExist,
  isItemFieldExist,
  isPrimitiveItemExist,
  removePrimitiveItem,
  filterPrimitiveItem,
  removeLastItem,
  getArrayByField,
  replaceItem,
  getItemById,
  addItemLast,
  addItemFirst,
  removeDuplicateObjects,
  isSomeExist,
  changeItemPosition,
  manageItemStateIntoArray,
  arrayCutter,
  lastIndexNumber,
  changeListItemHandler,
  removeDuplicatesFromPrimitiveArray,
  replaceFullItem,
  getIndex,
  arraysAreEqual,
  insertItemIntoSortedArray,
  filterArrayBySearch,
};
