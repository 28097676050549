import { useContext } from 'react';

import { IOktaAuthContext } from '@features/oktaClient';

import { OktaContext } from './OktaProvider';

const useOkta = (): IOktaAuthContext => {
  const context = useContext(OktaContext);

  if (context === undefined) {
    throw new Error('useOkta must be used with a OktaProvider');
  }

  return context;
};

export default useOkta;
