import eventBus from '@eventBus/index';

import { EventBusTypes } from '../../enums/eventBusTypes.enum';

const eventName = EventBusTypes.MATERIAL_PRODUCER_NOTIFICATION;

const subscribe = (callback: VoidFunction): VoidFunction => {
  return eventBus.subscribe(eventName, callback);
};

const broadcast = (): void => {
  eventBus.broadcast(eventName);
};

export default {
  subscribe,
  broadcast,
};
