import { styled, Tabs } from '@mui/material';

import { p, rem } from '@helpers/remUtils';

export const StyledTabs = styled(Tabs)(({ theme }) => ({
  display: 'flex',
  minHeight: 'auto',

  '.MuiTabs-scroller': {
    width: 'auto',
  },

  '.MuiTabs-indicator': {
    height: rem(3),
  },

  '.MuiTab-root': {
    minWidth: 'auto',
    minHeight: 'auto',
    padding: p(8, 17, 8, 17),
    color: theme.palette.text.secondary,
    fontSize: rem(16),
    fontWeight: 500,
    lineHeight: '1.75',
    letterSpacing: '-0.28px',
  },

  '.Mui-selected': {
    color: `${theme.palette.text.primary} !important`,
  },

  '.Mui-disabled': {
    opacity: 0.4,
  },
}));
