import { makeStyles } from '@theme/appTheme';

import { ISuspenseLoadingProps } from './suspenseLoading.types';

const useSuspenseLoadingStyles = makeStyles<ISuspenseLoadingProps>()((_theme, props) => ({
  wrapper: {
    position: 'absolute',
    top: 0,
    right: 0,
    left: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    background: props.backdropColor || 'none',
    zIndex: props.zIndex || 'auto',
  },
}));

export default useSuspenseLoadingStyles;
