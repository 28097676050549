export const isStorageItemExist = (key: string): boolean => {
  return localStorage.hasOwnProperty(key);
};

export const setStorageItem = <T>(
  key: string,
  state: T,
): T | undefined => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
    return state;
  } catch (error) {
    return undefined;
  }
};

export const getStorageItem = <T>(key: string): T | undefined => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) return undefined;
    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

export const replaceStorageItemField = <T>(
  key: string,
  partialState: Partial<T>,
): T | undefined => {
  try {
    const serializedState = localStorage.getItem(key);
    if (serializedState === null) return undefined;
    const parsedState = JSON.parse(serializedState);
    const newState = {
      ...parsedState,
      ...partialState,
    };
    setStorageItem(key, newState);
    return newState;
  } catch (error) {
    return undefined;
  }
};

export const removeStorageItem = (key: string): 'success' | undefined => {
  try {
    if (!isStorageItemExist(key)) return undefined;
    localStorage.removeItem(key);
    return 'success';
  } catch (error) {
    return undefined;
  }
};
