export const fFlagsNames = {
  fflagProjectsPlus: 'fflag-projects-plus', // for removing after release 1.22, need to check by BE team
  fflagTableViewDetails: 'fflag-details-table-view-list', // for removing after release 1.22, need to check by BE team
  fflagTableViewPlantsDetails: 'fflag-details-table-view-plants-list', // for removing after release 1.22, need to check by BE team
  fflagTableViewProjectsDetails: 'fflag-details-table-view-projects-list', // for removing after release 1.22, need to check by BE team
  fflagDynamicGraphsForTileView: 'fflag-dynamic-graphs-for-tile-view', // for removing after release 1.22, need to check by BE team
  fflagBarchartTooltip: 'fflag-barchart-tooltip', // for removing after release 1.22, need to check by BE team
  fflagDashboard: 'fflag-dashboard-skeleton', // for removing after release 1.22, need to check by BE team
  fflagBatchUsersInvitations: 'fflag-batch-users-invitations', // for removing after release 1.22, need to check by BE team
  fflagCategoryChartDetailsPrevData: 'fflag-category-chart-details-prev-data', // for removing after release 1.22, need to check by BE team
  fflagPieChartDetails: 'fflag-pie-chart-details', // for removing after release 1.22, need to check by BE team
  // Project Plus Ranking
  fflagNewRankingDrilling: 'fflag-new-ranking-drilling',
  fflagNewRankingDrillingShowDaysWithoutPulse: 'fflag-new-ranking-drilling-show-days-without-pulse',
  fflagNewRankingDailyPerformanceSync: 'fflag-new-ranking-daily-performance-sync',
  // Localization
  fflagLocalizationWithBE: 'fflag-localization-with-be',
  // admin page
  fflagAdminPage: 'fflag-admin-page',
  fflagPlantsHealthPage: 'fflag-plants-health-page',
  fflagETicketCustomersPage: 'fflag-e-ticket-customers-page',
  fflagTicketTypeManagement: 'fflag-ticket-type-management',
  fflagEpd: 'fflag-epd-page',
  fflagCalibration: 'fflag-calibration-page',
  fflag247: 'fflag-247',
  fflagPlantHealthAddress: 'fflag-plant-health-address',
  // TicketMonitoring
  fflagTicketMonitoring: 'fflag-ticket-monitoring',
  fflagTicketMonitoringFilters: 'fflag-ticket-monitoring-filters',
  fflagTicketMonitoringSorting: 'fflag-ticket-monitoring-sorting',
  fflagTicketsMonitoringLinking: 'fflag-tickets-monitoring-linking',
  // internal sharing
  fflagInternalSharing: 'fflag-internal-sharing',
  fflagInternalSharingRanking: 'fflag-internal-sharing-ranking',
  fflagInternalSharingSippingLog: 'fflag-internal-sharing-sipping-log',
  fflagInternalSharingTruckLog: 'fflag-internal-sharing-truck-log',
  // drivernomics
  fflagDrivernomics: 'fflag-drivernomics',
  fflagTickets: 'fflag-tickets',
  // other
  fflagOorBadgeTooltip: 'fflag-oor-badge-tooltip',
  fflagResetTicketTypeManagementButton: 'fflag-reset-ticket-type-management-button',
  fflagNewCustomerDetailsPage: 'fflag-new-customer-details-page', //for removing
  //Super admin
  fflagSuperAdminPage: 'fflag-super-admin-page',
  fflagSuperAdminUsersPage: 'fflag-super-admin-users-page',
  fflagBidPage: 'fflag-bid-page',
  fflagLivePageNewDesign: 'fflag-live-page-new-design',
  // Tickets
  fflagTicketsControlsColumn: 'fflag-tickets-controls-column',
  fflagTicketsSearch: 'fflag-tickets-search',
  fflagTicketsCalibration: 'fflag-tickets-calibration',
  fflagYearToDate: 'fflag-year-to-date',
  fflagTicketsFlagButton: 'fflag-tickets-flag-button',
};
