import { alpha, styled } from '@mui/material';
import { br, p, rem } from '@helpers/remUtils';

export const StyledDefaultModalHeader = styled('header')(({ theme }) => ({
  '.modal-header-title': {
    margin: 0,
    padding: p(13, 22, 13, 22),
    borderRadius: br(10, 10, 0, 0),
    fontSize: rem(18),
    fontWeight: 700,
    background: alpha(theme.palette.additionalColors.background.sixthly, 0.5),
  },
  '.modal-header-close-button': {
    position: 'absolute',
    top: rem(-12),
    left: rem(-12),
    background: theme.palette.text.primary,
    border: 'none',
    color: theme.palette.background.default,
    transition: 'opacity 0.2s ease-out',
    '&:hover': {
      background: theme.palette.text.primary,
      opacity: '.5',
    },
  },
}));
