import { FC, MouseEvent, ReactElement, memo, useCallback, useState } from 'react';
import { Fade, ClickAwayListener, Popper } from '@mui/material';

import TimeEntityList from '../timeEntityList/TimeEntityList';
import { ITimePickerButtonProps } from './timePickerButton.interface';
import { TimePickerButtonWrapper } from './timePickerButton.theme';
import { timePickerButtonTestId } from './timePickerButton.const';

const TimePickerButton: FC<ITimePickerButtonProps> = (props) => {
  const {
    currentValue,
    dropdownConfig,
    PopperComponent,
    updateTimeString,
  } = props;
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const CurrentPopperComponent = PopperComponent || Popper;

  const toggleIsDropdownOpenHandler = (event: MouseEvent<HTMLElement>): void => {
    setAnchorEl(event.currentTarget);
    setIsDropdownOpen((previousOpen) => !previousOpen);
  };

  const closeDropdownHandler = (): void => {
    setIsDropdownOpen(false);
  };

  const updateTimeStringHandler = useCallback((item: string): void => {
    updateTimeString(item);
    setIsDropdownOpen(false);
  }, []);

  return (
    <>
      <TimePickerButtonWrapper
        data-testid={timePickerButtonTestId}
        onClick={toggleIsDropdownOpenHandler}
      >
        {currentValue}
      </TimePickerButtonWrapper>

      <CurrentPopperComponent
        open={isDropdownOpen}
        anchorEl={anchorEl}
        transition={true}
        placement="bottom"
      >
        {({ TransitionProps }): ReactElement => (
          <ClickAwayListener onClickAway={closeDropdownHandler}>
            <Fade
              {...TransitionProps}
              timeout={350}
            >
              {/* Fade don`t work with styled component */}
              <div>
                <TimeEntityList
                  config={dropdownConfig}
                  currentValue={currentValue}
                  onItemClick={updateTimeStringHandler}
                />
              </div>
            </Fade>
          </ClickAwayListener>
        )}
      </CurrentPopperComponent>
    </>
  );
};

export default memo(TimePickerButton);
