import { styled } from '@mui/material';
import { rem } from '@helpers/remUtils';

export const ButtonsListWrapper = styled('ul')({
  display: 'flex',
  margin: 0,
  padding: 0,
  overflowX: 'auto',

  '.buttonsListItem': {
    listStyle: 'none',

    '&:not(:last-child)': {
      marginRight: rem(7),
    },
  },
});
