import { useContext } from 'react';

import { IGroupsDndValues } from './groupsDndProvider.interface';
import { IGroupsDndProviderContext } from './GroupsDndProvider';

const useGroupsDnd = (): IGroupsDndValues => {
  const context = useContext(IGroupsDndProviderContext);

  if (context === undefined) {
    throw new Error('useGroupsDnd must be used within a GroupsDndProvider');
  }

  return context;
};

export { useGroupsDnd };
