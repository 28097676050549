import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { v4 as uuidv4 } from 'uuid';

// eslint-disable-next-line max-len
import { IClassificationMaterialItem } from '@pages/settings/pages/companySettings/components/materialClassification/components/materialsTableContainer/interfaces/classificationMaterialItem.interface';
import {
  classificationMaterialsUpdating,
} from '@store/thunk/classificationMaterialsUpdating/classificationMaterialsUpdating';
import { getTicketTypesMaterials } from '@store/thunk/ticketTypesMaterials/ticketTypesMaterials';

import { IMaterialClassificationsState } from './materialClassificationsSlice.interface';
import { ITicketTypeItem } from './interfaces/ticketTypeItem.interface';
import { allTicketTypeItem } from './materialClassificationsSlice.const';

const initialState: IMaterialClassificationsState = {
  search: '',
  currentTicketTypeItem: null,
  ticketTypeList: null,
  totalRows: null,
  selectedMaterials: [],
  selectedMaterialPopupStatus: false,
  selectAllMaterialsStatus: false,
  loading: false,
  error: false,
  updateMaterialsLoading: false,
  updateMaterialsError: false,
  updateMaterialsLoadingKey: null,
};

export const materialClassificationsSlice = createSlice({
  name: 'materialClassifications',
  initialState,
  reducers: {
    setSearchField: (
      state: IMaterialClassificationsState,
      action: PayloadAction<string>,
    ): void => {
      const { payload } = action;
      state.search = payload;
    },
    setMaterialType: (
      state: IMaterialClassificationsState,
      action: PayloadAction<ITicketTypeItem>,
    ): void => {
      const { payload } = action;
      state.currentTicketTypeItem = payload;
    },
    setSelectedMaterials: (
      state: IMaterialClassificationsState,
      action: PayloadAction<IClassificationMaterialItem[]>,
    ): void => {
      const { payload } = action;
      state.selectedMaterials = payload;
    },
    clearSelectedMaterials: (state: IMaterialClassificationsState): void => {
      state.selectedMaterials = [];
    },
    setTotalRows: (
      state: IMaterialClassificationsState,
      action: PayloadAction<number>,
    ): void => {
      const { payload } = action;
      state.totalRows = payload;
    },
    openSelectedMaterialPopup: (state: IMaterialClassificationsState): void => {
      state.selectedMaterialPopupStatus = true;
    },
    closeSelectedMaterialPopup: (state: IMaterialClassificationsState): void => {
      state.selectedMaterialPopupStatus = false;
    },
    changeSelectAllMaterialsStatus: (
      state: IMaterialClassificationsState,
      action: PayloadAction<boolean>,
    ): void => {
      const { payload } = action;
      state.selectAllMaterialsStatus = payload;
    },
    resetSelectAllMaterialsStatus: (state: IMaterialClassificationsState): void => {
      state.selectAllMaterialsStatus = false;
    },
    setClassificationMaterialsList: (
      state: IMaterialClassificationsState,
      action: PayloadAction<ITicketTypeItem[]>,
    ): void => {
      const { payload } = action;
      state.ticketTypeList = payload;
    },
    clearValues: (state: IMaterialClassificationsState): void => {
      state.search = '';
      state.selectedMaterials = [];
      state.selectedMaterialPopupStatus = false;
      state.currentTicketTypeItem = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTicketTypesMaterials.pending, (state: IMaterialClassificationsState) => {
        state.loading = true;
      })
      .addCase(getTicketTypesMaterials.fulfilled, (
        state: IMaterialClassificationsState,
        action: PayloadAction<ITicketTypeItem[] | null>,
      ) => {
        const { payload } = action;
        const currentTicketTypeList = payload || [];
        state.ticketTypeList = [allTicketTypeItem, ...currentTicketTypeList];
        state.currentTicketTypeItem = allTicketTypeItem;
        state.loading = false;
      })
      .addCase(getTicketTypesMaterials.rejected, (state) => {
        state.loading = false;
        state.error = true;
      })
      .addCase(classificationMaterialsUpdating.pending, (state: IMaterialClassificationsState) => {
        state.updateMaterialsLoading = true;
      })
      .addCase(classificationMaterialsUpdating.fulfilled, (state: IMaterialClassificationsState) => {
        state.selectedMaterialPopupStatus = false;
        state.updateMaterialsLoadingKey = uuidv4();
        state.selectedMaterials = [];
        state.updateMaterialsLoading = false;
      })
      .addCase(classificationMaterialsUpdating.rejected, (state) => {
        state.updateMaterialsLoading = false;
        state.updateMaterialsError = true;
      });
  },
});

export const {
  clearValues,
  setTotalRows,
  setSearchField,
  setMaterialType,
  setSelectedMaterials,
  clearSelectedMaterials,
  openSelectedMaterialPopup,
  closeSelectedMaterialPopup,
  resetSelectAllMaterialsStatus,
  changeSelectAllMaterialsStatus,
  setClassificationMaterialsList,
 } = materialClassificationsSlice.actions;
export default materialClassificationsSlice.reducer;
