import { FC, PropsWithChildren, useEffect, useState } from 'react';

import SuspenseLoading from '@components/suspenseLoading';
import { featureFlagsActions } from '@providers/featureFlagsProvider/featureFlagsProvider';
import useTenantProvider from '@providers/tenantProvider/useTenantProvider';
import { refreshPageWarningProviderActions } from '@providers/refreshPageWarningProvider/RefreshPageWarningProvider';
import { ERefreshPageWarning } from '@enums/refreshPageWarning';
import eventBus from '@eventBus/index';
import useMaterialTypesProvider from '@providers/materialTypesProvider/useMaterialTypesProvider';
import UnauthorizedHeader from '@components/unauthorizedHeader/UnauthorizedHeader';
import { RolesEnum } from '@enums/tenantRoles.enum';
import { availableRoles } from '@providers/tenantProvider/tenantProvider.const';
import ExperienceSwitcher from '@features/experienceSwitcher';

const InitAppChecker: FC<PropsWithChildren> = ({ children }) => {
  const [isGlobalLoading, setIsGlobalLoading] = useState<boolean>(true);
  const { getFeatureFlags } = featureFlagsActions;
  const { getTenantHandler, value } = useTenantProvider();
  const { getMaterialTypes } = useMaterialTypesProvider();

  const currenRole: RolesEnum | null = value && value.roles[0] as RolesEnum || null;

  const isExperienceSwitcher = value?.experience === null && !value.roles.includes(RolesEnum.IT_ADMIN_ONLY)
    ? <ExperienceSwitcher />
    : <>{children}</>;

  const initialRequests = async (): Promise<void> => {
    setIsGlobalLoading(true);
    try {
      await Promise.allSettled([getFeatureFlags(), getTenantHandler(), getMaterialTypes()]);
    } finally {
      setIsGlobalLoading(false);
    }
  };

  const ticketTypeNotificationHandler = (id: number) => (sourceUserId: number): void => {
    if (sourceUserId === id) return;
    refreshPageWarningProviderActions.addWarningMessage(ERefreshPageWarning.TICKET_TYPES);
  };

  useEffect(() => {
    initialRequests();
  }, []);

  useEffect(() => {
    if (!value) return;

    const unsubscribe = eventBus.ticketTypeNotification.subscribe(ticketTypeNotificationHandler(value.id));
    return () => unsubscribe();
  }, [value]);

  if (!isGlobalLoading && !value) return <UnauthorizedHeader />;

  if (!process.env.REACT_APP_E2E_TESTS) {
    if (!isGlobalLoading && (!currenRole || !availableRoles.includes(currenRole))) return <UnauthorizedHeader />;
  }

  return (
    <>
      {isGlobalLoading
        ? <SuspenseLoading backdropColor="rgb(0, 0, 0, 0.5)" />
        : isExperienceSwitcher
      }
    </>
  );
};

export default InitAppChecker;
