import { styled } from '@mui/material';

import { p, rem } from '@helpers/remUtils';

export const BoxWithVerticalTabsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  height: '100%',

  '.MuiTabs-root': {
    padding: p(40, 0, 0),
    minWidth: rem(210),
    borderRight: `${rem(1)} solid ${theme.palette.additionalColors.divider.primary}`,
  },

  '.MuiTab-root': {
    alignItems: 'end',
    padding: `${p(0, 15, 0, 0)} !important`,
  },

  '.tab-content-wrapper': {
    padding: p(40),
    width: rem(500),
    maxWidth: rem(500),
  },
}));
