import { createAsyncThunk } from '@reduxjs/toolkit';
import { stringify } from 'qs';

import apiHHAdmin from '@configs/urls/apiHHAdmin';

import instance from '../instanceDefiner';
import { IUpdateMaterialsRequestProps } from './updateMaterialsRequestProps.interface';

export const classificationMaterialsUpdating = createAsyncThunk(
  'materialClassifications/classificationMaterialsUpdating',
  async (materialsList: IUpdateMaterialsRequestProps) => {
    const { materialList } = materialsList;
    try {
      const response = await instance.patch(
        apiHHAdmin.updateClassificationMaterials,
        { materials: materialList },
        {
          baseURL: process.env.REACT_APP_HH_ADMIN_API_URI,
          params: { keyFormat: 'camel' },
          paramsSerializer: {
            serialize: params => stringify(params, { encode: true }),
          },
        }
      );
      return response.data || null;
    } catch {
      return Promise.reject();
    }
  },
);
