import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ISharedWidgetsState } from './sharedWidgetsState.interface';
import { ISharedWidgetsValue } from './sharedWidgetsValue.interface';

const initialState: ISharedWidgetsState = {
  shareWidgetUsersPopupStatus: false,
  sharedWidgetValue: null,
  loading: false,
  error: false,
};

export const sharedWidgetsSlice = createSlice({
  name: 'sharedWidgets',
  initialState,
  reducers: {
    openShareWidgetUsersPopup(
      state: ISharedWidgetsState,
    ): void {
      state.shareWidgetUsersPopupStatus = true;
    },
    closeShareWidgetUsersPopup(
      state: ISharedWidgetsState,
    ): void {
      state.shareWidgetUsersPopupStatus = false;
    },
    setSharedWidgetValue(
      state: ISharedWidgetsState,
      action: PayloadAction<ISharedWidgetsValue>,
    ): void {
      const { payload } = action;
      state.sharedWidgetValue = payload;
    },
    clearSharedWidgetValue(
      state: ISharedWidgetsState,
    ): void {
      state.sharedWidgetValue = null;
    },
  },
});

export const {
  closeShareWidgetUsersPopup,
  openShareWidgetUsersPopup,
  clearSharedWidgetValue,
  setSharedWidgetValue,
} = sharedWidgetsSlice.actions;
export default sharedWidgetsSlice.reducer;
