import { rem, p, m } from '@helpers/remUtils';
import { makeStyles } from '@theme/appTheme';

export const useStylesExperienceSwitcher = makeStyles()((theme) => ({
  wrapper: {
    height: '100vh',
    width: '100%',
  },
  logo: {
    position: 'absolute',
    top: rem(30),
    left: rem(40),
  },
  title: {
    margin: m(0, 0, 10, 0),
    fontSize: rem(20),
  },
  experienceTitle: {
    marginBottom: rem(10),
    fontSize: rem(17),
    fontWeight: 400,
  },
  experienceContainer: {
    width: rem(380),
    padding: p(40, 34, 40, 34),
    backgroundColor: theme.palette.background.paper,
    borderRadius: rem(10),
  },
  button: {
    padding: p(12, 0, 12, 0),
    marginTop: rem(10),
    fontSize: rem(14),
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
}));
