import { FC } from 'react';
import { Button, CircularProgress, ListItem } from '@mui/material';

import { rem } from '@helpers/remUtils';

import { CustomSkeleton } from '../customSkeleton';
import { IChipsListProps } from './chipsList.interface';
import { ChipsListWrapper } from './chipsList.theme';
import { chipsListTexts } from './chipsList.const';

const ChipsList: FC<IChipsListProps> = (props) => {
  const {
    entityList,
    firstLoading,
    options,
    intervalRef,
    newPageLoading,
    manageSelectedEntity,
    onListScrollHandler,
  } = props;
  const { emptyListMessage } = options || {};

  const manageSelectedEntityHandler = (id: string | number): VoidFunction => (): void => {
    manageSelectedEntity(id);
  };

  return (
    <ChipsListWrapper
      ref={intervalRef}
      onScroll={onListScrollHandler}
    >
      {/* TODO: update  skeleton style after having design */}
      {firstLoading
        ? <CustomSkeleton
            lines={1}
            lineHeight={rem(30)}
          />
        : null
      }

      {!firstLoading
        ? entityList.map((row) => {
            const {
              id,
              label,
              isSelected,
            } = row;

            return (
              <ListItem
                key={id}
                className="chips-list-item"
              >
                <Button
                  variant="rounded"
                  color={isSelected ? 'primary' : 'secondary'}
                  onClick={manageSelectedEntityHandler(id)}
                >
                  {label}
                </Button>
              </ListItem>
            );
          })
        : null
      }

      {newPageLoading
        ? <ListItem className="loading-wrapper">
            <CircularProgress size={30} />
          </ListItem>
        : null
      }

      {!entityList.length && !newPageLoading && !firstLoading
        ? <ListItem className="empty-state">
            {emptyListMessage || chipsListTexts.NO_DATA_AVAILABLE}
          </ListItem>
        : null
      }
    </ChipsListWrapper>
  );
};

export { ChipsList };
