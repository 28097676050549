import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DateFilterEnum } from '@enums/dateFilter.enum';
import { getProjectPlusRanking } from '@store/thunk/ranking/getProjectPlusRanking';
import { ISortColumn } from '@enums/pagination/sortColumn.interface';
import { setSortStatus } from '@helpers/setSortStatus';
import { IDateInterval } from '@store/projectPlus/newRankingSlice/interfaces/dateInterval.interface';

import { IRankingState } from './interfaces/rankingState.interface';
import { IDateRange } from './interfaces/dateRange';
import { dateRanges } from './rankingSlice.const';

export const initialDateInterval = {
  ...dateRanges[DateFilterEnum.MONTH],
  interval: DateFilterEnum.MONTH,
};

export const initialState: IRankingState = {
  loading: false,
  error: false,
  dateInterval: initialDateInterval,
  showEntityQuantity: 100,
  rawData: [],
  tableSorting: [],
};

const rankingSlice = createSlice({
  name: 'rankingSlice',
  initialState,
  reducers: {
    resetAdditionalRankingFilters: (state: IRankingState): void => {
      const { from, to } = dateRanges[DateFilterEnum.MONTH];
      state.dateInterval.interval = DateFilterEnum.MONTH;
      state.dateInterval.from = from;
      state.dateInterval.to = to;
    },
    setNewDateRange: (state: IRankingState, action: PayloadAction<IDateRange>): void => {
      const { from, to } = action.payload;

      state.dateInterval.from = from;
      state.dateInterval.to = to;
    },
    setNewDateInterval: (state: IRankingState, action: PayloadAction<DateFilterEnum>): void => {
      const dateRange = dateRanges[action.payload];
      const { from, to } = dateRange;

      state.dateInterval.from = from;
      state.dateInterval.to = to;
      state.dateInterval.interval = action.payload;
    },
    setFullDateObject: (state: IRankingState, action: PayloadAction<IDateInterval>): void => {
      const { from, to, interval } = action.payload;
      state.dateInterval.from = from;
      state.dateInterval.to = to;
      state.dateInterval.interval = interval;
    },
    resetDateInterval: (state: IRankingState): void => {
      const { from, to } = dateRanges[DateFilterEnum.MONTH];

      state.dateInterval.interval = DateFilterEnum.MONTH;
      state.dateInterval.from = from;
      state.dateInterval.to = to;
    },
    tableSortingHandler: (state: IRankingState, action: PayloadAction<ISortColumn>): void => {
      state.tableSorting = [...setSortStatus(action.payload, state.tableSorting)];
    },
    resetTableSorting: (state: IRankingState): void => {
      state.tableSorting = [];
    },
  },
  extraReducers: builder => {
    builder
      .addCase(getProjectPlusRanking.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(getProjectPlusRanking.fulfilled, (state, action) => {
        const { data } = action.payload;
        state.loading = false;
        state.error = false;
        state.rawData = data;
      })
      .addCase(getProjectPlusRanking.rejected, (state) => {
        state.error = true;
        state.loading = false;
      });
  },
});
export const {
  resetAdditionalRankingFilters,
  resetDateInterval,
  setNewDateRange,
  setNewDateInterval,
  setFullDateObject,
  tableSortingHandler,
  resetTableSorting,
} = rankingSlice.actions;

export default rankingSlice.reducer;
