import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IDailyAdditionalInformation } from '@interfaces/newRanking/dailyAdditionalInformation.interface';
import { IRankingSelectedHeatmap } from '@interfaces/newRanking/rankingSelectedHeatmap.interface';
import { IRankingGroups } from '@interfaces/newRanking/rankingGroups.interface';
import { DateFilterEnum } from '@enums/dateFilter.enum';

import { INewRankingState } from './interfaces/newRankingState.interface';
import { IDateRange } from './interfaces/dateRange';
import { dateRanges } from './newRankingSlice.const';
import { IDateInterval } from './interfaces/dateInterval.interface';

export const initialDateInterval = {
  ...dateRanges[DateFilterEnum.MONTH],
  interval: DateFilterEnum.MONTH,
};

export const initialState: INewRankingState = {
  dateInterval: initialDateInterval,
  rankingGroupSkeletonLoading: true,
  detailedRankingSkeletonLoading: true,
  dailyPerformanceSkeletonLoading: true,
  dailyPerformanceStatistics: null,
  selectedRankingGroupItem: null,
  selectedRankingDetailsItem: null,
};

const newRankingSlice = createSlice({
  name: 'newRankingSlice',
  initialState,
  reducers: {
    setNewDateRange: (state: INewRankingState, action: PayloadAction<IDateRange>): void => {
      const { from, to } = action.payload;

      state.dateInterval.from = from;
      state.dateInterval.to = to;
    },
    setNewDateInterval: (state: INewRankingState, action: PayloadAction<DateFilterEnum>): void => {
      const dateRange = dateRanges[action.payload];
      const { from, to } = dateRange;

      state.dateInterval.from = from;
      state.dateInterval.to = to;
      state.dateInterval.interval = action.payload;
    },
    setFullDateObject: (state: INewRankingState, action: PayloadAction<IDateInterval>): void => {
      const { from, to, interval } = action.payload;
      state.dateInterval.from = from;
      state.dateInterval.to = to;
      state.dateInterval.interval = interval;
    },
    resetDateInterval: (state: INewRankingState): void => {
      const { from, to } = dateRanges[DateFilterEnum.MONTH];

      state.dateInterval.interval = DateFilterEnum.MONTH;
      state.dateInterval.from = from;
      state.dateInterval.to = to;
    },
    selectRankingGroupItem: (state: INewRankingState, action: PayloadAction<IRankingGroups>): void => {
      state.selectedRankingGroupItem = action.payload;
    },
    resetSelectedGroupItem: (state: INewRankingState): void => {
      state.selectedRankingGroupItem = null;
    },
    selectRankingDetailsItem: (state: INewRankingState, action: PayloadAction<IRankingSelectedHeatmap>): void => {
      state.selectedRankingDetailsItem = action.payload;
    },
    resetSelectedDetailsItem: (state: INewRankingState): void => {
      state.selectedRankingDetailsItem = null;
    },
    setRankingGroupSkeletonLoading: (state: INewRankingState, action: PayloadAction<boolean>): void => {
      state.rankingGroupSkeletonLoading = action.payload;
    },
    setDetailedRankingSkeletonLoading: (state: INewRankingState, action: PayloadAction<boolean>): void => {
      state.detailedRankingSkeletonLoading = action.payload;
    },
    setDailyPerformanceSkeletonLoading: (state: INewRankingState, action: PayloadAction<boolean>): void => {
      state.dailyPerformanceSkeletonLoading = action.payload;
    },
    setDailyPerformanceStatistics: (
      state: INewRankingState,
      action: PayloadAction<IDailyAdditionalInformation>,
    ): void => {
      state.dailyPerformanceStatistics = action.payload;
    },
  },
});
export const {
  resetDateInterval,
  setNewDateRange,
  setNewDateInterval,
  setFullDateObject,
  selectRankingGroupItem,
  resetSelectedGroupItem,
  setRankingGroupSkeletonLoading,
  setDetailedRankingSkeletonLoading,
  setDailyPerformanceSkeletonLoading,
  setDailyPerformanceStatistics,
  selectRankingDetailsItem,
  resetSelectedDetailsItem,
} = newRankingSlice.actions;

export default newRankingSlice.reducer;
