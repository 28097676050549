import { useContext } from 'react';

import { TenantContext } from './TenantProvider';
import { TSharedTenantData } from './interfaces/tenantProvider.interface';

const useTenantProvider = (): TSharedTenantData => {
  const context = useContext(TenantContext);

  if (context === undefined) {
    throw new Error('useTenantProvider must be used within a TenantProvider');
  }

  return context;
};

export default useTenantProvider;
