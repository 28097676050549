import React, { FC, useState } from 'react';
import { ButtonBase } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { useGlobalButtonStyles, useGlobalFlexStyles } from '@assets/globalStyles';
import { ExperienceType } from '@enums/experience.enum';
import { useEditCurrentUser } from '@features/editCurrentUserContainer/hooks/useEditCurrentUser/useEditCurrentUser';
import SuspenseLoading from '@components/suspenseLoading';
import { ReactComponent as Logo } from '@assets/images/icons/logo.svg';
import useTenantProvider from '@providers/tenantProvider/useTenantProvider';
import { ITenant } from '@interfaces/tenant.interface';

import ExperienceButtonContainer from './components/experienceButtonContainer';
import { ExperienceSwitcherText } from './experienceSwitcher.const';
import { useStylesExperienceSwitcher } from './experienceSwitcher.theme';

const ExperienceSwitcher: FC = () => {
  const { classes, cx } = useStylesExperienceSwitcher();
  const { classes: flexClasses } = useGlobalFlexStyles();
  const { classes: buttonClasses } = useGlobalButtonStyles();
  const { t } = useTranslation(['common', 'experienceSwitcher']);
  const [tenantExperience, setTenantExperience] = useState<ExperienceType | null>(null);
  const {
    loading,
    updatePersonalInfoHandler,
  } = useEditCurrentUser();
  const {
    setTenant,
  } = useTenantProvider();

  const updateInfoHandler = (value: ITenant): void => {
    setTenant(value);
  };

  const experienceSwitcherHandler = (): void => {
    const requestData = { experience: tenantExperience as ExperienceType };
    updatePersonalInfoHandler(requestData, updateInfoHandler);
  };

  return (
    <div
      className={cx(
        classes.wrapper,
        flexClasses.flexCenter
      )}
    >
      <Logo className={classes.logo} />

      <div
        className={cx(
          classes.experienceContainer,
          flexClasses.flexColumn,
        )}
      >
        <h2 className={classes.title}>
          {t(ExperienceSwitcherText.WELCOME_TO_SUPPLIER, { ns: 'experienceSwitcher' })}
        </h2>

        <h3 className={classes.experienceTitle}>
          {t(ExperienceSwitcherText.CHOOSE_YOUR_EXPERIENCE, { ns: 'experienceSwitcher' })}
        </h3>

        <ExperienceButtonContainer
          experienceHandler={setTenantExperience}
          experience={tenantExperience}
        />

        <ButtonBase
          className={cx(
            buttonClasses.buttonHoverEffect,
            buttonClasses.squareButton,
            classes.button
          )}
          onClick={experienceSwitcherHandler}
          disabled={!tenantExperience}
        >
          {t(ExperienceSwitcherText.CONTINUE)}
        </ButtonBase>
      </div>

      {loading && <SuspenseLoading backdropColor="rgb(0, 0, 0, 0.5)" />}
    </div>
  );
};

export default ExperienceSwitcher;
