import React, { FC, createContext, useMemo, useState, useCallback, PropsWithChildren } from 'react';

import { IActiveEntity } from '@interfaces/selectedEntity/activeEntity.interface';

import { IEntityManagementContext } from './entityManagementContext.interface';

const EntityManagementContext = createContext<IEntityManagementContext | undefined>(undefined);

const EntityManagementProvider: FC<PropsWithChildren> = ({ children }) => {
  const [selectedItem, setSelectedItem] = useState<IActiveEntity | null>(null);

  const setSelectedItemHandler = useCallback((newSelectedItem: IActiveEntity) => {
    setSelectedItem(newSelectedItem);
  }, []);

  const clearSelectedItemHandler = useCallback(() => {
    setSelectedItem(null);
  }, []);

  const value = useMemo(() => {
    return {
      selectedItem,
      setSelectedItemHandler,
      clearSelectedItemHandler,
    };
  }, [
    selectedItem,
  ]);

  return (
    <EntityManagementContext.Provider value={value}>
      {children}
    </EntityManagementContext.Provider>
  );
};

export { EntityManagementProvider, EntityManagementContext };
