import { ITimeParts } from '@components/nativeTimePiker/interfaces/ITimeParts.interface';
import { DayPeriodEnum } from '@enums/dayPeriod.enum';

interface ITimeRangeButton {
  label: string;
  value: string;
  timeStart: ITimeParts,
  timeEnd: ITimeParts,
}

export const defaultTimePeriodButtonsConfig: Record<string, ITimeRangeButton> = {
  DAY: {
    label: 'Day',
    value: 'DAY',
    timeStart: {
      hours: '05',
      minutes: '00',
      dayPeriod: DayPeriodEnum.AM,
    },
    timeEnd: {
      hours: '05',
      minutes: '00',
      dayPeriod: DayPeriodEnum.PM,
    },
  },
  NIGHT: {
    label: 'Night',
    value: 'NIGHT',
    timeStart: {
      hours: '05',
      minutes: '00',
      dayPeriod: DayPeriodEnum.PM,
    },
    timeEnd: {
      hours: '05',
      minutes: '00',
      dayPeriod: DayPeriodEnum.AM,
    },
  },
};
