import { ITimeParts } from '@components/nativeTimePiker/interfaces/ITimeParts.interface';
import { deepCompare } from '@helpers/deepCompare';

const isNumber = (string: string): boolean => {
  const num = parseFloat(string);
  return !isNaN(num) && isFinite(num);
};

export const isSaveButtonDisabled = (
  timeStart: ITimeParts,
  timeEnd: ITimeParts,
  localTimeStart: ITimeParts,
  localTimeEnd: ITimeParts,
): boolean => {
  const isTimeChange = !deepCompare(timeStart, localTimeStart) || !deepCompare(timeEnd, localTimeEnd);
  const isTimeStartHours = isNumber(localTimeStart.hours);
  const isTimeStartMinutes = isNumber(localTimeStart.minutes);
  const isTimeEndHours = isNumber(localTimeEnd.hours);
  const isTimeEndMinutes = isNumber(localTimeEnd.minutes);
  const timeValuesArray = [isTimeStartHours, isTimeStartMinutes, isTimeEndHours, isTimeEndMinutes];
  const timeValuesArrayHasTrue = timeValuesArray.includes(true);
  const timeValuesArrayHasFalse = timeValuesArray.includes(false);

  return !isTimeChange || timeValuesArrayHasTrue && timeValuesArrayHasFalse;
};
