// GENERAL_SETTINGS - project plus general settings changes
// MATERIAL_PRODUCER - material producer blocking
// TICKET_TYPES - turn on/off ticket type
// USER_INFO - user/material producer settings changing

export enum ENotificationsStatusType {
  SHARED_NEW_WIDGET = 'SHARED_NEW_WIDGET',
  FORCE_SHARED_NEW_WIDGET = 'FORCE_SHARED_NEW_WIDGET',
  SHARED_WIDGET_UNSHARED = 'SHARED_WIDGET_UNSHARED',
  SHARED_WIDGET_ACCEPT = 'SHARED_WIDGET_ACCEPT',
  SHARED_WIDGET_REJECT = 'SHARED_WIDGET_REJECT',
  SHARED_WIDGET_BLOCKED = 'SHARED_WIDGET_BLOCKED',
  CLONED_SHARED_WIDGET = 'CLONED_SHARED_WIDGET',
  DELETED_WIDGET = 'DELETED_WIDGET',
  INTERNAL_SHARING = 'INTERNAL_SHARING',
  GENERAL_SETTINGS = 'GENERAL_SETTINGS',
  MATERIAL_PRODUCER = 'MATERIAL_PRODUCER',
  TICKET_TYPES = 'TICKET_TYPES',
  USER_INFO = 'USER_INFO',
}

export enum EUserInfoActionsType {
  BLOCK = 'BLOCK',
  MODIFY = 'MODIFY',
  ARCHIVE = 'ARCHIVE',
  UNBLOCK = 'UNBLOCK',
}

export enum ESilentNotificationsStatusType {
  GENERAL_SETTINGS = 'GENERAL_SETTINGS',
  MATERIAL_PRODUCER = 'MATERIAL_PRODUCER',
  TICKET_TYPES = 'TICKET_TYPES',
  USER_INFO = 'USER_INFO',
}

export type TNoSilentPushNotifications = Exclude<ENotificationsStatusType, keyof typeof ESilentNotificationsStatusType>;
