import React, { FC, memo } from 'react';
import { Button } from '@mui/material';

import { IGroupDropdownItemProps } from './groupDropdownItem.interface';
import { GroupDropdownItemWrapper } from './groupDropdownItem.theme';

const GroupDropdownItem: FC<IGroupDropdownItemProps> = (props) => {
  const {
    group,
    addNewGroupClickEvent,
  } = props;

  const addNewGroupClickEventHandler = (): void => {
    addNewGroupClickEvent(group);
  };

  return (
    <GroupDropdownItemWrapper>
      <Button
        variant="text"
        color="secondary"
        className="dropdown-list-item-button"
        onClick={addNewGroupClickEventHandler}
      >
        {group.label}
      </Button>
    </GroupDropdownItemWrapper>
  );
};

export default memo(GroupDropdownItem);
