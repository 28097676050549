import { List, styled } from '@mui/material';

import { rem, p } from '@helpers/remUtils';

export const EntityListWrapper = styled(List)(() => ({
  height: `calc(100vh - ${rem(360)})`,
  overflowY: 'auto',

  '.loading-wrapper': {
    justifyContent: 'center',
    padding: p(10, 0),
  },

  '.empty-state': {
    justifyContent: 'center',
  },
}));
