import { createContext, FC, PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { IErrorHandlerContext } from '@providers/errorHandlerProvider/errorHandlerContext.types';

const ErrorHandlerContext = createContext<IErrorHandlerContext | undefined>(undefined);

const ErrorHandlerProvider: FC<PropsWithChildren> = ({ children }) => {
  const [errorText, setErrorText] = useState<string | null>(null);
  const [errorStatus, setErrorStatus] = useState<boolean>(false);
  const [errorStatusCode, setErrorStatusCode] = useState<number | null>(null);

  useEffect(() => {
    if (errorText || errorStatusCode) {
      setErrorStatus(true);
    } else {
      setErrorStatus(false);
    }
  }, [errorText, errorStatusCode]);

  const value = useMemo(() => {
    return {
      errorText,
      errorStatus,
      errorStatusCode,
      setErrorText,
      setErrorStatusCode,
    };
  }, [
    errorText,
    errorStatus,
    errorStatusCode,
  ]);

  return (
    <ErrorHandlerContext.Provider value={value}>
      {children}
    </ErrorHandlerContext.Provider>
  );
};

export { ErrorHandlerProvider, ErrorHandlerContext };
