import { AnimationDirection } from '@enums/animationDirection';
import { alpha } from '@mui/material/styles';
import { makeStyles } from '@theme/appTheme';

import { getTransformValue } from '../helpers/getTransformValue';

const useStylesFlyOutModal = makeStyles<AnimationDirection, 'modalContent'>()((
  _theme,
  animationDirection,
  classes,
  ) => ({
  modalBackdrop: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'flex-end',
    backgroundColor: alpha('#000000', 0.5),
    backdropFilter: 'blur(1px)',
    opacity: 0,
    zIndex: 999998,
    '&.active': {
      opacity: 1,
    },
    [`&.active > .${classes.modalContent}`]: {
      transform: 'translateY(0)',
      opacity: 1,
      transitionDelay: '150ms',
      transitionDuration: '350ms',
    },
  },
  modalContent: {
    height: '100vh',
    transform: getTransformValue(animationDirection, 250),
    transition: 'all 200ms cubic-bezier(0.4, 0, 0.2, 1)',
    opacity: 0,
    zIndex: 999999,
  },
}));

export default useStylesFlyOutModal;
