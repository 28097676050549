import { ElementType, FC } from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { IDndListItemWrapper } from './dndListItemWrapper.interface';

const DndListItemWrapper: FC<IDndListItemWrapper> = (props) => {
  const {
    renderContent,
    useSortableConfig,
    component,
    isItemFreezed,
  } = props;
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ ...useSortableConfig });
  const CustomElement = component || ('li' as ElementType);
  const style = {
    width: '100%',
    cursor: useSortableConfig.disabled && 'default',
    listStyle: 'none',
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <CustomElement
      ref={!isItemFreezed ? setNodeRef : undefined}
      style={style}
      {...attributes}
      {...listeners}
    >
      {renderContent({ isDragging, disabled: useSortableConfig.disabled })}
    </CustomElement>
  );
};

export default DndListItemWrapper;
