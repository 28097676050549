export const dropdownContentTestId = 'dropdownContentTestId';
export const dropdownContentResetButtonTestId = 'dropdownContentResetButtonTestId';
export const dropdownContentSaveButtonTestId = 'dropdownContentSaveButtonTestId';

export const dropdownTimePickerContentTexts = {
  SAVE_BUTTON: 'Save',
  RESET_BUTTON: 'Clear All',
  START_TIME_LABEL: 'Start Time',
  END_TIME_LABEL: 'End Time',
};
