import { alpha } from '@mui/material';
import { createTheme, useTheme, Theme } from '@mui/material/styles';
import { createMakeStyles } from 'tss-react';

const appTheme = createTheme({
  components: {
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          padding: '2px 13px',
          borderRadius: 13,
          boxShadow: 'none',
          fontSize: 14,
          fontWeight: 500,
          textTransform: 'none',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          color: '#9BA1AD',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          '&$shrink': {
            transform: 'translate(0, 1.5px) scale(0.90)',
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: 'standard',
      },
    },
  },
  palette: {
    primary: {
      main: '#4ADFE9',
      dark: '#447C9D',
      light: alpha('#CFCFCF', 0.18),
      contrastText: '#35C5AB',
    },
    secondary: {
      main: '#4ADFE9',
      dark: '#77A4B0',
      contrastText: '#FFFFFF',
    },
    text: {
      primary: '#FFFFFF',
      secondary: alpha('#FFFFFF', 0.70),
      disabled: alpha('#3A4359', 0.25),
      hint: '#9BA1AD',
      dark: '#2B2E4A',
      main: '#4ADFE9',
    },
    background: {
      default: '#2B2E4A',
      paper: '#3E4268',
    },
    action: {
      disabledBackground: alpha('#DBDBDB', 0.20),
      disabled: '#DBDBDB',
    },
    error: {
      main: '#FF808B',
      light: alpha('#FF808B', 0.20),
      contrastText: '#FF0000',
    },
    success: {
      main: '#77FFC9',
      light: alpha('#77FFC9', 0.20),
      contrastText: '#0AAE24',
    },
    warning: {
      main: '#ff9800',
      light: alpha('#ff9800', 0.20),
      contrastText: '#FFFFFF',
    },
    divider: '#C7C8C8',
    darkColors: {
      50: alpha('#DBDBDB', 0.20),
      100: '#DBDBDB',
      200: '#343757',
      300: '#3C466C',
      400: '#525C80',
      500: '#16435C',
      600: '#4E537E',
      700: '#323550',
      800: '#303453',
      900: '#212338',
    },
    additionalColors: {
      historicalCalendar: {
        background: '#181C34',
      },
      customDropdown: {
        onItemHover: '#77A1E9',
        activeItem: '#77A1E9',
        itemBorder: alpha('#C7C8C8', 0.4),
        selectInputBg: '#363854',
        selectInputLabel: '#FFFFFF',
      },
      inputBackgroundColor: {
        main: alpha('#CFCFCF', 0.2),
        dark: '#363854',
      },
      buttonIconBackgroundColor: {
        main: '#FFFFFF',
      },
      buttonLinkColor: {
        main: '#95BDFF',
      },
      background: {
        main: '#95BDFF',
        darkBlueBackground: '#16223a',
        card: '#29314C',
        block: '#3E4362',
      },
      dividers: {
        light: '#FFFFFF',
        dark: alpha('#FFFFFF', 0.3),
        main: '#242534',
      },
      squareButton: {
        main: '#4ADFE9',
        light: '#515577',
        dark: '#000000',
        contrastText: '#2A2D48',
      },
      navigation: {
        background: '#13223C',
      },
      ranking: {
        green: '#3DC553',
        yellow: '#FCC400',
        red: '#C53D3D',
      },
      notification: {
        background: '#2A3459',
        header: '#333C5E',
      },
      pulseType: {
        exceeds: '#EF8282',
        within: '#37A3C5',
      },
    },
    // For colors name I use this resource https://colors.artyclick.com/color-name-finder/
    colorsVars: {
      blueHosta: '#7BBFD7',
      comet: '#575D7C',
      mediumTurquoise: '#39CDD7',
      greyGoose: '#CECECE',
      stoplightGoGreen: '#64E844',
      lightSlateGrey: '#717892',
      blueDiamond: '#1B1C2C',
      davyGrey: '#4B5859',
      dawnPink: '#ECECEC',
      dusk: '#46547D',
      softPeach: '#ECECF2',
      burningOrange: '#FF7934',
      rhino: '#313663',
      morningGlory: '#7FE1EB',
      celeste: '#51F4D7',
      earlyDawn: '#FFF8E7',
      blueChill: '#208D94',
      osloGrey: '#8D8D93',
      greyCloud: '#B6B6B6',
      cornflowerBlue: '#6497EA',
      frenchGrey: '#BDBECA',
      santaGrey: '#9FA1B4',
      artyClickDeepSkyBlue: '#007AFF',
      duskyBlue: '#5B6095',
      deepAqua: '#206E87',
      ebonyClay: '#222242',
      hippieBlue: '#689AA8',
      pinkSwan: '#B8B8B8',
      tuna: '#333446',
      geraldine: '#FF8480',
      slate: '#575B7F',
      casper: '#B9BBCB',
      darkPastelBlue: '#7CA2E4',
      paleCerulean: '#8DC7DC',
      mulledWine: '#4B4F6D',
      dark: '#1D203B',
      cloudBurst: '#272B4E',
      periwinkleGrey: '#C8CADF',
      geyser: '#DCDEE8',
      marbleBlue: '#5A648F',
      watermelonPink: '#FF6F88',
      nileBlue: '#262E4F',
      gunPowder: '#31395E',
      bluewood: '#2F3359',
      amethystSmoke: '#989CB5',
      saffronMango: '#E8C05B',
      yellowishOrange: '#FFBE6B',
      deepMidnightBlue: '#2D304B',
      darkSlateBlue: '#383B55',
      dolphin: '#5D6080',
      darkComet: '#5A5F88',
      paleAqua: '#B9D9DB',
      darkRino: '#393D5F',
      darkDusk: '#535E83',
      parchment: '#FFF7AF',
      coralBlush: '#FF8F8F',
      aquaFrost: '#B2FFFA',
      midnightIndigo: '#4a4d71',
      twilightNavy: '#323555',
      silveryGrey: '#CDCDCD',
      chardonnay: '#FACE79',
    },
  },
  typography: {
    fontFamily: [
      'Rubik',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    htmlFontSize: 16,
    fontSize: 14,
    h1: {
      fontSize: 21,
      fontWeight: 700,
    },
    h2: {
      fontSize: 22,
      fontWeight: 500,
    },
    h3: {
      fontSize: 17,
      fontWeight: 600,
      letterSpacing: '-0.01em',
    },
    h4: {
      fontSize: 17,
      fontWeight: 600,
      lineHeight: '21px',
      letterSpacing: '-0.01em',
    },
    subtitle1: {
      fontSize: 15,
      fontWeight: 400,
    },
    body1: {
      fontSize: 14,
      fontWeight: 400,
    },
    body2: {
      fontSize: 13,
      fontWeight: 600,
    },
    button: {
      fontSize: 14,
      textTransform: 'none',
      fontWeight: 600,
    },
    caption: {
      fontSize: 12,
      textTransform: 'none',
      fontWeight: 400,
    },
    overline: {
      fontSize: 10,
      fontWeight: 500,
      textTransform: 'none',
    },
  },
});

export const { makeStyles } = createMakeStyles({ 'useTheme': useTheme as (() => Theme) });

export default appTheme;
