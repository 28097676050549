import { useNavigate } from 'react-router-dom';

import { IUseNavigationReturn } from './useNavigation.interface';

export const useNavigation = (): IUseNavigationReturn => {
  const navigate = useNavigate();

  const goBack = (): void => {
    navigate(-1);
  };

  const redirect = (url: string, options?: Record<string, unknown>): void => {
    navigate(url, { ...options });
  };
  const isCurrentLink = (currentLocation: string): boolean => {
    return location.pathname === currentLocation;
  };

  return ({
    goBack,
    redirect,
    isCurrentLink,
  });
};
