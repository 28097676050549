import { createAsyncThunk } from '@reduxjs/toolkit';
import { stringify } from 'qs';

import { IProjectsPerDay } from '@store/projectPlus/historicalProjectsCalenderSlice/projectsPerDay.interface';
import api from '@configs/urls/api';

import instance from '../instanceDefiner';
import { IHistoricalProjectsCalendarParamsParams } from './historicalProjectsCalendarParams.interface';

export const getProjectsPerDayStatistics = createAsyncThunk(
  'historicalProjectsCalender/getProjectsPerDayStatistics',
  async (inputParams: IHistoricalProjectsCalendarParamsParams, thunkAPI) => {
    try {
      const response = await instance.get<IProjectsPerDay[]>(api.getProjectsNumberPerDayStatistics, {
        params: { ...inputParams },
        paramsSerializer: {
          serialize: params => stringify(params, { arrayFormat: 'repeat', encode: true }),
        },
        signal: thunkAPI.signal,
      });
      return response.data;
    } catch {
      return Promise.reject();
    }
  },
);
