import { FC, createContext, useMemo, useState, useCallback, PropsWithChildren } from 'react';
import { httpClient } from '@okta/axiosInstance';

import { IFeatureFlag } from '@interfaces/featureFlag.interface';
import api from '@configs/urls/api';

import { IFeatureFlagsContext, IIFeatureFlagsActions } from './featureFlagsProvider.interface';

const FeatureFlagsContext = createContext<IFeatureFlagsContext | undefined>(undefined);

export let featureFlagsActions: IIFeatureFlagsActions;

const FeatureFlagsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [featuresList, setFeaturesList] = useState<IFeatureFlag[] | null>(null);
  const [isFeatureLoading, setIsFeatureLoading] = useState<boolean>(true);

  const getFeatureFlags = async (): Promise<void> => {
    setIsFeatureLoading(true);
    try {
      const response = await httpClient.get<IFeatureFlag[]>({ url: api.getFeaturesList });
      setFeaturesList(response.data);
    } finally {
      setIsFeatureLoading(false);
    }
  };

  const isUnderFeatureFlag = useCallback((uid: string | undefined): boolean => {
    if (process.env.REACT_APP_E2E_TESTS) {
      return true;
    }
    if (!uid) {
      return true;
    }
    const currentFeatureFlag = featuresList?.find((featureFlag: IFeatureFlag) => featureFlag.uid === uid);
    if (currentFeatureFlag) {
      return currentFeatureFlag.enabled;
    }
    return false;
  }, [featuresList]);

  featureFlagsActions = {
    getFeatureFlags,
  };

  const value = useMemo(() => {
    return {
      featuresList,
      isUnderFeatureFlag,
      isFeatureLoading,
    };
  }, [
    featuresList,
    isFeatureLoading,
  ]);

  return (
    <FeatureFlagsContext.Provider value={value}>
      {children}
    </FeatureFlagsContext.Provider>
  );
};

export { FeatureFlagsProvider, FeatureFlagsContext };
