import { styled } from '@mui/material';

import { p, rem } from '@helpers/remUtils';

interface IDndListItemContentWrapper {
  isDragging: boolean;
  useDragOverlay?: boolean;
}

export const DndListItemContentWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isDragging' && prop !== 'useDragOverlay',
})<IDndListItemContentWrapper>(({ theme, isDragging, useDragOverlay }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: p(5, 0),
  cursor: isDragging ? 'grabbing' : 'grab',
  opacity: isDragging && useDragOverlay ? 0.3 : undefined,
  '.default-icon': {
    marginRight: rem(15),
    fontSize: rem(16),
    color: theme.palette.common.white,
    opacity: 0.3,
    transition: 'opacity 0.1s',
  },
  '.disable-icon': {
    opacity: 1,
  },
  '.hidden-icon': {
    transform: 'rotate(180deg) scale(1, -1)',
  },
  '.dragging-icon': {
    opacity: isDragging && 1,
  },
  '.content-label': {
    fontSize: rem(17),
  },
}));
