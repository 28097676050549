import { FC, PropsWithChildren } from 'react';
import { Provider } from 'react-redux';

import { store } from '../index';

const ReduxWrapperProvider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Provider store={store}>
      {children}
    </Provider>
  );
};

export default ReduxWrapperProvider;
