import { createAsyncThunk } from '@reduxjs/toolkit';

// eslint-disable-next-line max-len
import { addStatusToTicketTypes } from '@pages/settings/pages/companySettings/components/materialClassification/components/materialsTableContainer/helpers/addStatusToTicketTypes.helper';
import { ITicketTypeItem } from '@store/materialClassifications/interfaces/ticketTypeItem.interface';
import { IMaterialTypes } from '@interfaces/materialTypes.interface';
import apiHHAdmin from '@configs/urls/apiHHAdmin';

import instance from '../instanceDefiner';
import { ITicketTypesMaterialsResponseProps } from './ticketTypesMaterialsResponseProps.interface';

export const getTicketTypesMaterials = createAsyncThunk<ITicketTypeItem[] | null, IMaterialTypes[]>(
  'materialClassifications/getTicketTypesMaterials',
  async (materials: IMaterialTypes[]) => {
    const response = await instance.get<ITicketTypesMaterialsResponseProps>(apiHHAdmin.getTicketTypesMaterials, {
      baseURL: process.env.REACT_APP_HH_ADMIN_API_URI,
    });
    return addStatusToTicketTypes(response.data.types, materials) || null;
  },
);
