import { FC, ReactElement } from 'react';
import { IconButton, Skeleton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import { IDefaultModalHeaderProps } from './defaultModalHeader.interface';
import { StyledDefaultModalHeader } from './defaultModalHeader.theme';

const DefaultModalHeader: FC<IDefaultModalHeaderProps> = (props): ReactElement => {
  const {
    title,
    isLoading,
    closeHandler,
  } = props;

  return (
    <StyledDefaultModalHeader>
      <h2 className="modal-header-title">
        {!isLoading ? title : <Skeleton variant="text" />}
      </h2>

      {closeHandler
        ? (
          <IconButton
            className="modal-header-close-button"
            size="small"
            onClick={closeHandler}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        )
        : null
      }
    </StyledDefaultModalHeader>
  );
};

export default DefaultModalHeader;
