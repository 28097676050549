import { useState } from 'react';

import { changeListItemHandler } from '@helpers/arrayUtils';
import { IColumPreference } from '@interfaces/calibration/storedTablePreference.interface';

import { IUseTableGroupsManagement } from './useTableGroupsManagement.interface';

export const useTableGroupsManagement = (): IUseTableGroupsManagement => {
  const [groupsList, setGroupsList] = useState<IColumPreference[] | null>(null);
  const [isDefaultGroupsList, setIsDefaultGroupsList] = useState(false);
  const isGrouped = Boolean(groupsList?.length);

  const toggleGroupIntoGroupsList = (group: IColumPreference): IColumPreference[] => {
    const updatedGroupsList = groupsList ? changeListItemHandler(groupsList, group, group.id) : [];
    setGroupsList(updatedGroupsList);
    return updatedGroupsList;
  };

  const updateGroupsList = (newGroupsList: IColumPreference[]): void => {
    setGroupsList(newGroupsList);
  };

  const updateIsDefaultGroupsList = (newIsDefaultGroupsList: boolean): void => {
    setIsDefaultGroupsList(newIsDefaultGroupsList);
  };

  return ({
    values: {
      groupsList,
      isDefaultGroupsList,
      isGrouped,
    },
    actions: {
      toggleGroupIntoGroupsList,
      updateGroupsList,
      updateIsDefaultGroupsList,
    },
  });
};
