import { FC } from 'react';
import { Button } from '@mui/material';

import { manageColumnsModalFooterTexts } from './manageColumnsModalFooter.const';
import { IManageColumnsModalFooter } from './manageColumnsModalFooter.interface';
import { ManageColumnsModalFooterWrapper } from './manageColumnsModalFooter.theme';

const ManageColumnsModalFooter: FC<IManageColumnsModalFooter> = (props) => {
  const {
    isDefaultColumnsList,
    resetButtonLabel,
    saveButtonLabel,
    saveButtonClickHandler,
    resetButtonClickHandler,
  } = props;

  return (
    <ManageColumnsModalFooterWrapper>
      <Button
        size="large"
        disabled={isDefaultColumnsList}
        onClick={resetButtonClickHandler}
      >
        {resetButtonLabel || manageColumnsModalFooterTexts.RESET_BUTTON}
      </Button>

      <Button
        variant="contained"
        size="large"
        onClick={saveButtonClickHandler}
      >
        {saveButtonLabel || manageColumnsModalFooterTexts.SAVE_BUTTON}
      </Button>
    </ManageColumnsModalFooterWrapper>
  );
};

export default ManageColumnsModalFooter;
