import { AnimationDirection } from '@enums/animationDirection';
import { rem } from '@helpers/remUtils';
import { makeStyles } from '@theme/appTheme';

import { getTransformValue } from '../helpers/getTransformValue';

const useStylesFullPageModal = makeStyles<AnimationDirection, 'modalContent'>()((
  theme,
  animationDirection,
  classes,
  ) => ({
  modalBackdrop: {
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 70,
    display: 'flex',
    justifyContent: 'flex-end',
    opacity: 0,
    zIndex: 999996,
    '&.active': {
      opacity: 1,
    },
    [`&.active > .${classes.modalContent}`]: {
      transform: 'translateY(0)',
      opacity: 1,
      transitionDelay: '150ms',
      transitionDuration: '350ms',
    },
  },
  modalContent: {
    width: `calc(100vw - ${rem(70)})`,
    height: '100vh',
    backgroundColor: theme.palette.background.default,
    overflowY: 'auto',
    transform: getTransformValue(animationDirection, 250),
    transition: 'all 200ms cubic-bezier(0.4, 0, 0.2, 1)',
    opacity: 0,
    zIndex: 999996,
  },
}));

export default useStylesFullPageModal;
