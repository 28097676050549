import { useState } from 'react';

import { ITenant } from '@interfaces/tenant.interface';
import api from '@configs/urls/api';
import { httpClient } from '@okta/axiosInstance';

import { IUseGetTenant } from './useGetTenant.interface';

export const useGetTenant = (): IUseGetTenant => {
  const [tenant, setTenant] = useState<ITenant | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);

  const getTenantHandler = async (): Promise<void> => {
    setError(false);
    setLoading(true);
    try {
      const { data } = await httpClient.get<ITenant>({
        url: api.currentUser,
      });
      setTenant(data);
      setLoading(false);
    } catch {
      setError(true);
      setLoading(false);
    }
  };

  const getTenantSilentHandler = async (): Promise<ITenant | null> => {
    try {
      const { data } = await httpClient.get<ITenant>({
        url: api.currentUser,
      });
      return data;
    } catch {
      return null;
    }
  };

  return {
    tenant,
    loading,
    error,
    setTenant,
    getTenantHandler,
    getTenantSilentHandler,
  };
};
