import { styled } from '@mui/material';
import { rem } from '@helpers/remUtils';

export const TimeEntityListWrapper = styled('div')(({ theme }) => ({
  marginTop: rem(2),
  width: rem(50),
  height: '100%',
  borderRadius: rem(6),
  border: `${rem(1)} solid ${theme.palette.additionalColors.divider.secondary}`,
  boxShadow: `0px 10px 32px 0px ${theme.palette.additionalColors.boxShadow.main}`,
  overflow: 'hidden',
  '& .time-entity-list': {
    width: rem(55),
    height: '100%',
    maxHeight: rem(200),
    backgroundColor: theme.palette.background.paper,
    overflowY: 'auto',
  },
}));
