import { Drawer, styled } from '@mui/material';
import { rem } from '@helpers/remUtils';
import { IAnimatedTreeViewContainer } from './animatedTreeViewDrawer.interface';

const AnimatedTreeViewDrawer = styled(Drawer)<IAnimatedTreeViewContainer>(({ open, width }) => ({
  width: width || rem(311),
  flexShrink: 0,
  position: 'relative',
  left: 0,
  right: 0,
  zIndex: 1000,
  ...(!open && { pointerEvents: 'none' }),
  '&& .MuiDrawer-paper': {
    width: width || rem(311),
    boxSizing: 'border-box',
    position: 'absolute',
    // TODO we need as about colors from Tyshchenko,
    backgroundColor: '#333552',
    borderRight: `${rem(1)} solid #565972`,
    boxShadow: '5px 0px 1px 0px rgba(35, 38, 62, 0.33)',
  },
}));

export { AnimatedTreeViewDrawer };
