import eventBus from '@eventBus/index';

import { EventBusTypes } from '../../enums/eventBusTypes.enum';
import { EventBusCallbackType } from '../../interfaces/callback.interface';
import { EmptyFunction } from '../../interfaces/emptyFunction.interface';
import { IShareChartValuesProps } from './shareChartValues.interface';

const eventName = EventBusTypes.SHARE_CHART_VALUES;

const subscribe = (
  callback: EventBusCallbackType<IShareChartValuesProps>
): EmptyFunction => {
  return eventBus.subscribe<IShareChartValuesProps>(eventName, callback);
};

const broadcast = (data: IShareChartValuesProps): void => {
  eventBus.broadcast<IShareChartValuesProps>(eventName, data);
};

export default {
  subscribe,
  broadcast,
};
