import { getStorageItem, setStorageItem } from '@helpers/localStorage';
import { LocalStorageKeysEnum } from '@enums/localStorageKeys.enum';

import { TProjectPlusOptions } from '../interfaces/optionsPopupState.interface';
import { IGeneralOptions } from '../interfaces/generalOptions.interface';
import { ILocalOptions } from '../interfaces/localOptions.interface';
import { initialLocalOptions } from '../optionsPopup.const';

const getOptionsFromLocalStorage = (): Partial<ILocalOptions> => {
  return getStorageItem<ILocalOptions>(LocalStorageKeysEnum.PROJECT_PLUS_OPTIONS) || {};
};

export const setOptionsAfterInitiations = (generalOptions: IGeneralOptions): TProjectPlusOptions => {
  const { tonnageRange, trucks, fleetIds } = getOptionsFromLocalStorage();
  return {
    ...generalOptions,
    tonnageRange: tonnageRange ?? initialLocalOptions.tonnageRange,
    trucks: trucks ?? initialLocalOptions.trucks,
    fleetIds: fleetIds ?? initialLocalOptions.fleetIds,
  };
};

export const saveOptionFieldsInLocalStorage = (field: Record<string, any>): void => {
  const localStorageOptions = getStorageItem<ILocalOptions>(LocalStorageKeysEnum.PROJECT_PLUS_OPTIONS) || {};
  setStorageItem(
    LocalStorageKeysEnum.PROJECT_PLUS_OPTIONS,
    {
      ...localStorageOptions,
      ...field,
    }
  );
};
