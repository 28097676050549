import DirectionEnum from '@enums/direction.enum';
import { ISortColumn } from '@enums/pagination/sortColumn.interface';

export const setSortStatus = (
  sortStatus: ISortColumn,
  sortStatusList: ISortColumn[],
  push = false,
): ISortColumn[] => {
  const currentList = sortStatusList;
  const index = currentList.findIndex(item => item.column === sortStatus.column);
  if (index === -1) {
    if (!push) {
      currentList.unshift(sortStatus);
    } else {
      currentList.push(sortStatus);
    }
  } else {
    if (sortStatus.direction === DirectionEnum.DEFAULT) {
      currentList.splice(index, 1);
    } else {
      currentList[index] = sortStatus;
    }
  }
  return currentList;
};

export const setSortStatusSingle = (
  sortStatus: ISortColumn,
): ISortColumn[] => {

  if (sortStatus.direction === DirectionEnum.DEFAULT) {
    return [];
  }
  return [sortStatus];
};
