import { rem, p, br } from '@helpers/remUtils';
import { alpha, createTheme } from '@mui/material/styles';

export const ticketsTheme = createTheme({
  components: {
    MuiTableRow: {
      styleOverrides: {
        head: {
          boxShadow: '0px 0px 3.856px 0px rgba(0, 0, 0, 0.20), 0px 1.542px 1.542px 0px rgba(0, 0, 0, 0.20)',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        body: {
          wordBreak: 'break-all',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          fontSize: rem(15),
          fontWeight: 400,
          background: '#2B2E4A',
          borderBottomColor: `${alpha('#FFFFFF', 0.12)}`,
          '&': {
            borderLeft: `${rem(1)} solid ${alpha('#000000', 0.12)}`,
          },
          '&:first-of-type': {
            borderLeft: 'none',
          },
        },
        head: {
          background: '#3F425F',
          fontSize: rem(12),
          lineHeight: rem(12),
          padding: p(15, 12),
          borderBottomColor: '#3F425F',
          borderLeft: `${rem(1)} solid ${alpha('#FFFFFF', 0.12)}`,
          '&:first-of-type': {
            borderRadius: br(6, 0, 0, 0),
            borderLeft: 'none',
          },
          '&:last-of-type': {
            borderRadius: br(0, 6, 0, 0),
          },
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: 'rounded', size: 'large' },
          style: {
            borderRadius: rem(30),
          },
        },
        {
          props: { variant: 'rounded', size: 'medium' },
          style: {
            borderRadius: rem(30),
          },
        },
        {
          props: { variant: 'rounded', size: 'small' },
          style: {
            maxHeight: rem(33),
            borderRadius: rem(62),
            fontSize: rem(14),
            fontWeight: 500,
          },
        },
        {
          props: { variant: 'rounded', color: 'primary' },
          style: {
            transition: 'opacity 0.2s ease-out',
            backgroundColor: '#4CDFE9',
            color: '#333552',
            '&:hover': {
              backgroundColor: '#4CDFE9',
              opacity: '.7',
            },
            '&.Mui-disabled': {
              color: '#333552',
              opacity: '.4',
            },
          },
        },
        {
          props: { variant: 'rounded', color: 'secondary' },
          style: {
            transition: 'opacity 0.2s ease-out',
            backgroundColor: '#515472',
            color: '#D5E0E0',
            '&:hover': {
              backgroundColor: '#515472',
              opacity: '.7',
            },
            '&.Mui-disabled': {
              color: '#D5E0E0',
              opacity: '.4',
            },
          },
        },
        {
          props: { variant: 'rounded', color: 'thirdly' },
          style: {
            transition: 'opacity 0.2s ease-out',
            backgroundColor: '#506986',
            color: '#FFFFFF',
            '&:hover': {
              backgroundColor: '#506986',
              opacity: '.7',
            },
            '&.Mui-disabled': {
              color: '#FFFFFF',
              opacity: '.4',
            },
          },
        },
        {
          props: { variant: 'contained', color: 'secondary' },
          style: {
            transition: 'opacity 0.2s ease-out',
            backgroundColor: '#515472',
            color: '#D5E0E0',
            boxShadow: 'none',
            '&:hover': {
              backgroundColor: '#515472',
              opacity: '.7',
              boxShadow: 'none',
            },
            '&.Mui-disabled': {
              backgroundColor: '#515472',
              opacity: '.4',
              boxShadow: 'none',
            },
          },
        },
        {
          props: { size: 'large' },
          style: {
            padding: p(12, 22),
            fontSize: rem(14),
            lineHeight: 1,
          },
        },
        {
          props: { variant: 'contained', color: 'primary' },
          style: {
            borderRadius: rem(3),
            color: '#2B2E4A',
            '&.Mui-disabled': {
              backgroundColor: '#4CDFE9',
              color: '#2B2E4A',
              opacity: '.4',
            },
          },
        },
      ],
      styleOverrides: {
        root: ({ ownerState }) => ({
          minWidth: 'unset',
          textTransform: 'none',
          whiteSpace: 'nowrap',
          ...(ownerState.variant === 'text' &&
            ownerState.color === 'primary' && {
              transition: 'opacity 0.2s ease-out',
              color: '#4CDFE9',
              '&:hover': {
                opacity: '.7',
                backgroundColor: 'transparent',
              },
              '&.Mui-disabled': {
                color: '#4CDFE9',
                opacity: '.4',
              },
            }),
          ...(ownerState.variant === 'text' &&
            ownerState.color === 'secondary' && {
              transition: 'opacity 0.2s ease-out',
              color: '#FFFFFF',
              '&:hover': {
                opacity: '.7',
                backgroundColor: 'transparent',
              },
              '&.Mui-disabled': {
                color: '#FFFFFF',
                opacity: '.4',
              },
            }),
          ...(ownerState.size === 'medium' && {
            padding: p(8, 10),
            fontSize: rem(14),
            fontWeight: 500,
            lineHeight: 1,
          }),
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          transition: 'opacity 0.2s ease-out',
          ...(ownerState.variant === 'filled' &&
            ownerState.color === 'default' && {
              color: '#C1CDF5',
              border: '1px solid #585C80',
              backgroundColor: '#414460',
              '&:hover': {
                backgroundColor: '#414460',
                opacity: '.7',
              },
            }),
          ...(ownerState.variant === 'filled' &&
            ownerState.color === 'primary' && {
              color: '#2B2E4A',
              backgroundColor: '#4CDFE9',
              '&:hover': {
                backgroundColor: '#4CDFE9',
                opacity: '.7',
              },
            }),
          ...(ownerState.variant === 'filled' &&
            ownerState.color === 'secondary' && {
              color: '#FFFFFF',
              border: '1px solid #7B7E9D',
              backgroundColor: '#565B7D',
              '&:hover': {
                backgroundColor: '#565B7D',
                opacity: '.7',
              },
            }),
          ...(ownerState.size === 'small' && {
            minHeight: rem(33),
            borderRadius: rem(62),
            fontSize: rem(14),
            fontWeight: 500,
          }),
          ...(ownerState.size === 'medium' && {
            minHeight: rem(40),
            fontSize: rem(13),
            fontWeight: 600,
            borderRadius: rem(40),
          }),
        }),
        icon: ({ ownerState }) => ({
          flexShrink: 0,
          marginLeft: rem(12),
          ...(ownerState.variant === 'filled' &&
            ownerState.color === 'default' && {
              color: '#C1CDF5',
            }),
          ...(ownerState.variant === 'filled' &&
            ownerState.color === 'secondary' && {
              color: '#FFFFFF',
            }),
        }),
        deleteIcon: ({ ownerState }) => ({
          marginRight: rem(12),
          ...(ownerState.variant === 'filled' &&
            ownerState.color === 'default' && {
              color: '#C1CDF5',
              '&:hover': {
                color: '#C1CDF5',
                opacity: '.7',
              },
            }),
          ...(ownerState.variant === 'filled' &&
            ownerState.color === 'secondary' && {
              color: '#FFFFFF',
              '&:hover': {
                color: '#FFFFFF',
                opacity: '.7',
              },
            }),
        }),
      },
    },
    MuiPopper: {
      defaultProps: {
        modifiers: [
          {
            name: 'flip',
            enabled: false,
            options: {
              altBoundary: true,
              rootBoundary: 'document',
              padding: 8,
            },
          },
          {
            name: 'preventOverflow',
            enabled: false,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: true,
              rootBoundary: 'document',
              padding: 8,
            },
          },
        ],
      },
    },
    MuiDrawer: {
      styleOverrides: {
        root: {
          zIndex: 999999,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          transition: 'opacity 0.2s ease-out',
          ...( ownerState.color === 'secondary' && {
            color: '#3E4268',
            backgroundColor: '#FFFFFF',
            '&:hover': {
              backgroundColor: '#FFFFFF',
              opacity: '.7',
            },
            '&.Mui-disabled': {
              backgroundColor: '#FFFFFF',
              opacity: '.4',
            },
          }),
          ...(ownerState.color === 'primary' && {
            color: '#3E4268',
            backgroundColor: '#4ADFE9',
            '&:hover': {
              backgroundColor: '#4ADFE9',
              opacity: '.7',
            },
            '&.Mui-disabled': {
              backgroundColor: '#4ADFE9',
              opacity: '.4',
            },
          }),
          ...(ownerState.color === 'default' && {
            color: '#C1CDF5',
            border: '1px solid #585C80',
            backgroundColor: '#414460',
            '&:hover': {
              backgroundColor: '#414460',
              opacity: '.7',
            },
            '&.Mui-disabled': {
              backgroundColor: '#414460',
              opacity: '.4',
            },
          }),
        }),
      },
    },
    MuiBadge: {
      styleOverrides: {
        badge: ({ ownerState }) => ({
          ...( ownerState.color === 'default' && {
            color: '#2B2E4A',
            background: '#37A3C5',
          }),
        }),
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          margin: 0,
          padding: 0,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiSvgIcon: {
      variants: [
        {
          props: { fontSize: 'small' },
          style: {
            fontSize: rem(16),
          },
        },
      ],
    },
  },
  palette: {
    primary: {
      main: '#4ADFE9',
    },
    secondary: {
      main: '#4ADFE9',
    },
    thirdly: {
      main: '#506986',
      contrastText: '#FFFFFF',
    },
    error: {
      main: '#FF808B',
    },
    warning: {
      main: '#F90',
    },
    text: {
      primary: '#FFFFFF',
      secondary: alpha('#FFFFFF', 0.70),
      dark: '#333552',
      main: alpha('#FFFFFF', 0.90),
      hint: '#2B2E4A',
    },
    background: {
      default: '#2B2E4A',
      paper: '#3E4268',
    },
    darkColors: {},
    additionalColors: {
      searchFieldBackground: {
        main: alpha('#CFCFCF', 0.2),
      },
      divider: {
        main: '#3F5275',
        primary: alpha('#FFFFFF', 0.3),
        secondary: '#606381',
        thirdly: '#FFFFFF',
        fourthly: alpha('#000000', 0.12),
        fifthly: '#424564',
        sixthly: '#585C80',
        seventh: '#5D6080',
      },
      boxShadow: {
        main: '#13223C',
      },
      background: {
        main: alpha('#1F2242', 0.5),
        primary: '#333552',
        secondary: '#393D5C',
        thirdly: '#4CDFE9',
        fourthly: '#3d4e6b',
        fifthly: '#414460',
        sixthly: '#689AA8',
        seventh: '#AFD3DB',
        eighth: '#506986',
        ninth: '#5A5F88',
        tenth: '#4E537E',
      },
      inspectorStatusIcon: {
        pending: alpha('#ffffff', 0.3),
        delivered: '#58A878',
        rejected: '#D56F6F',
      },
      plantHealthStatus: {
        delivered: '#64E844',
        rejected: '#717892',
      },
      text: {
        main: '#C1CDF5',
      },
      map: {
        controlButton: '#838796',
        controlButtonBorder: '#A0A0A0',
      },
    },
  },
  typography: {
    fontFamily: [
      'Rubik',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    htmlFontSize: 16,
    fontSize: 14,
    h1: {
      fontSize: 21,
      fontWeight: 700,
    },
    h2: {
      fontSize: rem(20),
      fontWeight: 500,
    },
    h3: {
      fontSize: 17,
      fontWeight: 600,
      letterSpacing: '-0.01em',
    },
    h4: {
      fontSize: 17,
      fontWeight: 600,
      lineHeight: '21px',
      letterSpacing: '-0.01em',
    },
    subtitle1: {
      fontSize: 15,
      fontWeight: 400,
    },
    subtitle2: {
      fontSize: 17,
      fontWeight: 700,
    },
    body1: {
      fontSize: 14,
      fontWeight: 400,
    },
    body2: {
      fontSize: 13,
      fontWeight: 600,
    },
    button: {
      fontSize: 14,
      textTransform: 'none',
      fontWeight: 600,
    },
    caption: {
      fontSize: 12,
      textTransform: 'none',
      fontWeight: 600,
    },
    overline: {
      fontSize: 10,
      fontWeight: 500,
      textTransform: 'none',
    },
  },
});
