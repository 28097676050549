import api from '@configs/urls/api';
import { appClientPlatform, appClientVersion } from '@configs/version';

import { httpClient } from '../../../../okta/axiosInstance';
import { ISetFireBaseTokenParams } from '../interfaces/setFireBaseTokenParams.interface';

export const postFireBaseToken = (notificationToken: string): void => {
  httpClient.post<void, ISetFireBaseTokenParams>({
    url: api.setFireBaseToken,
    postData: {
      applicationType: appClientPlatform,
      firebaseDeviceToken: notificationToken,
      version: appClientVersion,
    },
  });
};
