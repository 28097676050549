import { IRequestReducerState } from './requestReducer.interface';

export const useRequestReducerInit: IRequestReducerState = {
  isLoading: false,
  isPending: false,
  isError: false,
  isSuccess: false,
  status: 'pending',
  error: null,
};

export const useRequestReducerLoadingInit: IRequestReducerState = {
  ...useRequestReducerInit,
  isLoading: true,
  isPending: true,
  isError: false,
  isSuccess: false,
  status: 'pending',
};
