import { styled } from '@mui/system';

interface IDraggableWrapper {
  disabled?: boolean;
}

const DraggableWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'disabled',
})<IDraggableWrapper>(({ disabled }) => ({
  cursor: disabled ? 'default' : 'grab',
  opacity: disabled ? '0.3' : undefined,
}));

export { DraggableWrapper };
