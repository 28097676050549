import { useCallback, useMemo, useState } from 'react';
import useAxios from 'axios-hooks';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import api from '@configs/urls/api';
import { notifications } from '@helpers/notificationsMessages';
import TenantDto from '@classes/tenant/tenant.dto';

import NotificationTypeEnum from '../../../../helpers/notificationsMessages/notificationsTypes.enum';
import { IRequestEditCurrentUser, IUseEditCurrentUserReturn } from './useEditCurrentUser.interface';

export const useEditCurrentUser = (): IUseEditCurrentUserReturn => {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation('informationMessages');

  const [{}, updateCurrentUser] = useAxios<TenantDto>({
    url: api.currentUser,
    method: 'put',
  }, { manual: true });

  const showToaster = (textMessage: string): void => {
    enqueueSnackbar(
      textMessage,
      { variant: NotificationTypeEnum.success },
    );
  };

  const updatePersonalInfoHandler = useCallback(async (
    requestData: IRequestEditCurrentUser,
    callback?: (value: TenantDto) => void,
  ): Promise<void> => {
    try {
      setLoading(true);
      const { data } = await updateCurrentUser({ data: requestData });
      if (callback) {
        callback(data);
      }
      showToaster(t(notifications.editUserSuccess.body));
    } finally {
      setLoading(false);
    }
  }, []);

  return useMemo(() => ({
    loading,
    updatePersonalInfoHandler,
  }), [
    loading,
  ]);
};
