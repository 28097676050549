import { styled } from '@mui/material';

import { p, rem } from '@helpers/remUtils';

export const PhoneInputWrapper = styled('input')(({ theme }) => ({
  padding: p(10, 14),
  height: rem(40),
  width: '100%',
  borderRadius: rem(5),
  border: 'none',
  background: theme.palette.additionalColors.background.tenth,
  fontSize: rem(14),
  fontWeight: 400,
  color: theme.palette.text.secondary,
  outline: 'none',
  ':focus': {
    color: theme.palette.text.primary,
  },
}));
