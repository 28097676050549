import { ENotificationsStatusType, EUserInfoActionsType } from '@features/navigationContainer/components/pushNotificationMenu/enums/notificationStatusType.enum';
import { MessagePayload } from '@firebase/messaging';

export const getFilteredNotifications = (notifications: MessagePayload[]): { [key: string]: MessagePayload } => {
  return [...notifications].reverse().reduce((filteredNotifications: { [key: string]: MessagePayload }, notification: MessagePayload) => {
    const { notificationType, action } = notification.data || {};
    const isUserInfoType = notificationType === ENotificationsStatusType.USER_INFO;
    const isBlockUserAction = action === EUserInfoActionsType.BLOCK || action === EUserInfoActionsType.ARCHIVE;
    const isAddBlockUserAction = !(isBlockUserAction && !!filteredNotifications[EUserInfoActionsType.UNBLOCK]);

    if (!isUserInfoType && !filteredNotifications[notificationType]) {
      filteredNotifications[notificationType] = notification;
    }

    if (isUserInfoType && !filteredNotifications[action] && isAddBlockUserAction) {
      filteredNotifications[action] = notification;
    }

    return filteredNotifications;
  }, {});
};
